import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface User {
  _id: string;
  name?: string;
  email?: string;
  token?: string;
  sessionStatus: boolean;
  authStatus: boolean;
  profile_photo?: string;
}

interface UserState {
  c_user: User;
}

const initialState: UserState = {
  c_user: {
    _id: "",
    name: "",
    email: "",
    token: "",
    sessionStatus: false,
    authStatus: false,
    profile_photo: "",
  },
};

export const customerSlice = createSlice({
  name: "c_user",
  initialState,
  reducers: {
    c_updateUserState: (state, action: PayloadAction<User>) => {
      state.c_user = action.payload;
    },
    c_updateAuthStatus: (state, action: PayloadAction<boolean>) => {
      state.c_user.authStatus = action.payload;
    },
    c_updateSessionStatus: (state, action: PayloadAction<boolean>) => {
      state.c_user.sessionStatus = action.payload;
    },
    c_updateAuthToken: (state, action: PayloadAction<string>) => {
      state.c_user.token = action.payload;
    },
    c_resetUser: (state) => {
      state.c_user = initialState.c_user;
    },
  },
});

export const {
  c_updateUserState,
  c_updateAuthStatus,
  c_updateSessionStatus,
  c_updateAuthToken,
  c_resetUser,
} = customerSlice.actions;

export default customerSlice.reducer;
