/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, FC } from "react";
import WebLayout from "../../layout/WebLayout";
import { useParams, useNavigate } from "react-router-dom";

import { getProductData } from "api/product";
import SliderComponent from "components/Slider";

import CustomerReviews from "components/CustomerReviews";

import { getProductListByCategories, getProductList } from "api/product";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  addItem,
  removeItem,
  updateQuantity,
} from "lib/redux/reducers/customer/cart";

import { ReadyOnlyPlateEditor } from "components/Plate/ReadyOnlyPlateEditor";
import { Description } from "@radix-ui/react-dialog";
import { getProductReviewList } from "api/product/review";
import Rating from "components/CustomerReviews/Rating";

import Snackbar from "components/Snackbar";

type Props = {};

const ProductInfo = (props: Props) => {
  //   const { id } = useParams();

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const id = params.get("id");

  const customer = useAppSelector((state) => state?.customerReducer);
  console.log("productInfo: customer: ", customer);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cartState = useAppSelector((state) => state?.cartReducer);
  const [categoryList, setCategoryList] = useState<any>();

  const [product, setProduct] = useState<any>();
  const [categoryId, setCategoryId] = useState<any>();
  const [productList, setProductList] = useState<any>();
  const [reviewList, setReviewList] = useState<any>([]);

  const isMobile = window.innerWidth < 768;

  const handleCartClick = () => {
    dispatch(addItem(product));
    handleSnackbar(
      true,
      "success",
      "Success !",
      "The item has been successfully added to your cart."
    );
    navigate(`/cart`);
  };

  const handleCartButtonClick = (item) => {
    // dispatch(addItem(item));
    handleSnackbar(
      true,
      "success",
      "Success !",
      "The item has been successfully added to your cart."
    );
    navigate(`/cart`);
  };

  const isItemExist = (itemId: string) => {
    if (cartState?.items && cartState?.items?.length) {
      const existingItem = cartState.items.find(
        (item) => item?.product?._id === itemId
      );
      if (existingItem) return existingItem;

      return false;
    }
    return false;
  };

  const handleAddItem = (item: any) => {
    dispatch(addItem(item));
    handleSnackbar(
      true,
      "success",
      "Success !",
      "The item has been successfully added to your cart."
    );
  };

  const handleRemoveItem = (item: any) => {
    const isItem = isItemExist(item?._id);

    if (isItem && isItem?.quantity > 0) {
      dispatch(
        updateQuantity({
          productId: item?._id,
          quantity: isItem?.quantity - 1,
        })
      );
    }

    if (isItem && isItem?.quantity === 1) {
      dispatch(removeItem(item?._id));
    }
  };

  useEffect(() => {
    if (categoryId) {
      getProductListByCategories([categoryId])
        .then((res: any) => {
          setProductList(res?.result);
        })
        .catch((error: any) => {
          console.log("failed to get product data", error);
        });
    }
  }, [categoryId]);

  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    if (cartState?.items && cartState?.items?.length && id) {
      const isItem = isItemExist(id);
      if (isItem) {
        setQuantity(isItem?.quantity);
      }
    }

    return () => {};
  }, [cartState?.items, id]);

  useEffect(() => {
    if (id) {
      getProductData(id)
        .then((res: any) => {
          setCategoryId(res?.result?.category?.id);
          setProduct(res?.result);
        })
        .catch((error: any) => {
          console.log("Failed to get category data : ", error);
        });
    }

    return () => {};
  }, [id]);

  const fetchReviewList = async () => {
    try {
      const result: any = await getProductReviewList(id);
      console.log("reviewData : ", result);
      setReviewList(result);
    } catch (error) {
      console.log("Failed to get review list, Error: ", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchReviewList();
    }
    return () => {};
  }, [id]);

  //snackbar
  const [openSnackbar, setOpenSnackbar] = useState<any>();
  const [type, setType] = useState<any>();
  const [heading, setHeading] = useState<any>();
  const [message, setMessage] = useState<any>();

  const hideSnackbar = () => {
    setTimeout(() => {
      setOpenSnackbar(false);
      setType("");
      setHeading("");
      setMessage("");
    }, 3000);
  };

  const handleSnackbar = (openSnackbar, type, heading, message) => {
    setOpenSnackbar(openSnackbar);
    setType(type);
    setMessage(message);
    setHeading(heading);
    hideSnackbar();
  };

  return (
    <WebLayout>
      {openSnackbar && (
        <Snackbar type={type} heading={heading} message={message} />
      )}
      <>
        {/* <!-- Product Left Sidebar Start --> */}
        <section className="product-section pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col wow fadeInUp">
                <div className="row g-4">
                  <div className="col-xl-7 wow fadeInUp">
                    <div className="product-left-box">
                      <div className="row g-2">
                        <div className="col-xxl-10 col-lg-12 col-md-10 order-xxl-2 order-lg-1 order-md-2">
                          <div className="product-main-2 no-arrow">
                            <div>
                              <div className="slider-image !aspect-square">
                                <img
                                  // src="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  src={product?.images[0]?.location}
                                  id="img-1"
                                  data-zoom-image="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  className="img-fluid image_zoom_cls-0 blur-up lazyload !aspect-square object-cover rounded-[1vw]"
                                  alt=""
                                />
                              </div>
                            </div>

                            {/* <div>
                              <div className="slider-image !aspect-square">
                                <img
                                  src="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  data-zoom-image="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  className="img-fluid image_zoom_cls-1 blur-up lazyload !aspect-square object-cover rounded-[1vw]"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div>
                              <div className="slider-image !aspect-square">
                                <img
                                  src="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  data-zoom-image="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  className="img-fluid image_zoom_cls-2 blur-up lazyload !aspect-square object-cover rounded-[1vw]"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div>
                              <div className="slider-image !aspect-square">
                                <img
                                  src="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  data-zoom-image="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  className="img-fluid image_zoom_cls-3 blur-up lazyload !aspect-square object-cover rounded-[1vw]"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div>
                              <div className="slider-image !aspect-square">
                                <img
                                  src="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  data-zoom-image="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  className="img-fluid image_zoom_cls-4 blur-up lazyload !aspect-square object-cover rounded-[1vw]"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div>
                              <div className="slider-image !aspect-square">
                                <img
                                  src="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  data-zoom-image="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  className="img-fluid image_zoom_cls-5 blur-up lazyload !aspect-square object-cover rounded-[1vw]"
                                  alt=""
                                />
                              </div>
                            </div> */}
                          </div>
                        </div>

                        {/* <div className="col-xxl-2 col-lg-12 col-md-2 order-xxl-1 order-lg-2 order-md-1">
                          <div className="left-slider-image-2 left-slider no-arrow slick-top">
                            <div>
                              <div className="sidebar-image">
                                <img
                                  src="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  className="img-fluid blur-up lazyload !aspect-square rounded-[1vw] object-cover"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div>
                              <div className="sidebar-image">
                                <img
                                  src="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  className="img-fluid blur-up lazyload !aspect-square rounded-[1vw] object-cover"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div>
                              <div className="sidebar-image">
                                <img
                                  src="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  className="img-fluid blur-up lazyload !aspect-square rounded-[1vw] object-cover"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div>
                              <div className="sidebar-image">
                                <img
                                  src="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  className="img-fluid blur-up lazyload !aspect-square rounded-[1vw] object-cover"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div>
                              <div className="sidebar-image">
                                <img
                                  src="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  className="img-fluid blur-up lazyload !aspect-square rounded-[1vw] object-cover"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div>
                              <div className="sidebar-image">
                                <img
                                  src="https://images.unsplash.com/photo-1524638067-feba7e8ed70f?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                  className="img-fluid blur-up lazyload !aspect-square rounded-[1vw] object-cover"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="right-box-contain">
                      <h2 className="name poppins-regular font-medium text-[6vw] md:!text-[3vw] !mb-0 !leading-[1.1] text-left">
                        {product?.name}
                      </h2>
                      <div className="price-rating flex-col-reverse !items-start gap-[1vw]">
                        <h3 className="theme-color price poppins-regular text-left mt-[1.5vw] md:mt-0">
                          AED {product?.price}
                          {/* <del className="text-content">$58.46</del>{" "} */}
                          {/* <span className="offer theme-color">(8% off)</span> */}
                        </h3>
                        <div className="product-rating custom-rate !justify-start mt-4">
                          <Rating
                            level={reviewList[0]?.overallAverageRating}
                            size={"12"}
                          />
                          <span className="review poppins-regular">
                            {reviewList[0]?.overallAverageRating} (
                            {reviewList[0]?.overallTotalReviews} reviews)
                          </span>
                        </div>
                      </div>

                      <div className="product-contain poppins-regular">
                        <p className="poppins-regular text-left">
                          {product?.shortDescription}
                        </p>
                      </div>

                      <div className="product-package">
                        <div className="product-title !mb-[1vw]">
                          <h4 className="poppins-regular text-left">Weight</h4>
                        </div>
                        <ul className="select-package !justify-start">
                          <li>
                            <a href="#" className="active poppins-regular">
                              {product?.weight} {product?.units}
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="note-box product-package !mt-[5vw] md:!mt-[3vw]">
                        <div className="cart_qty qty-box product-qty">
                          <div className="input-group">
                            <button
                              type="button"
                              className="qty-right-plus"
                              data-type="plus"
                              data-field=""
                              onClick={() => handleAddItem(product)}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                            <input
                              className="form-control input-number qty-input poppins-regular"
                              type="text"
                              name="quantity"
                              value={quantity}
                            />
                            <button
                              type="button"
                              className="qty-left-minus"
                              data-type="minus"
                              data-field=""
                              onClick={() => handleRemoveItem(product)}
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                          </div>
                        </div>

                        <button
                          onClick={handleCartClick}
                          className="btn poppins-regular btn-md bg-[#0FB16E] cart-button text-white w-100"
                        >
                          Add To Cart
                        </button>
                      </div>

                      {product?.ingredients.length ? (
                        <div className="payment-option product-package !mt-[5vw] md:!mt-[2vw]">
                          <div className="product-title">
                            <h4 className="poppins-regular text-left">
                              Ingredients
                            </h4>
                            <div className="!mt-[1vw]">
                              <ul className="select-package !justify-start">
                                {product?.ingredients.map(
                                  (_ingredients: any, index: number) => (
                                    <li key={_ingredients + index}>
                                      <a
                                        href="#"
                                        className="!cursor-auto !px-[3vw] md:!px-[1vw] !py-[1vw] md:!py-[.5vw] poppins-regular"
                                      >
                                        {_ingredients}
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {product?.allergicIngredients.length ? (
                        <div className="payment-option product-package !mt-[5vw] md:!mt-[2vw]">
                          <div className="product-title">
                            <h4 className="poppins-regular text-red-500 text-left whitespace-nowrap">
                              Some people may have allergies:
                            </h4>
                            <div className="!mt-[1vw]">
                              <ul className="select-package !justify-start">
                                {product?.ingredients.map(
                                  (_ingredients: any, index: number) => (
                                    <li key={_ingredients + index}>
                                      <a
                                        href="#"
                                        className="!cursor-auto !px-[3vw] md:!px-[1vw] !py-[1vw] md:!py-[.5vw] poppins-regular"
                                      >
                                        {_ingredients}
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Product Left Sidebar End --> */}
        <section className="pb-[14vw] md:pb-[7vw] !pt-0">
          <div className="container-fluid-lg">
            <div className="faq-box-contain">
              <div className="">
                <div className="faq-accordion">
                  <div className="accordion !gap-0" id="accordionExample">
                    <div className="accordion-item !bg-transparent">
                      <h2
                        className="accordion-header border-b border-black"
                        id="headingOne"
                      >
                        <button
                          className="accordion-button !bg-transparent collapsed flex poppins-regular justify-between !py-[2vw] md:!py-[1.15vw] !text-[4.25vw] md:!text-[1.95vw] !px-[2vw]"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                        >
                          Description
                          <span className="aspect-square w-[8.5vw] md:w-[2.5vw] shrink-0 rounded-full flex items-center justify-center">
                            <i className="fa-solid fa-angle-down !m-0"></i>
                          </span>
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body !py-[3vw] md:!py-[1.25vw] !text-[1.25vw] !px-[2vw]">
                          {product?.description && (
                            <ReadyOnlyPlateEditor
                              value={product?.description}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item !bg-transparent">
                      <h2
                        className="accordion-header border-b border-black"
                        id="headingTwo"
                      >
                        <button
                          className="accordion-button !bg-transparent collapsed flex poppins-regular justify-between !py-[2vw] md:!py-[1.15vw] !text-[4.25vw] md:!text-[1.95vw] !px-[2vw]"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                        >
                          Nutritional Profile and Benefits
                          <span className="aspect-square w-[8.5vw] md:w-[2.5vw] shrink-0 rounded-full flex items-center justify-center">
                            <i className="fa-solid fa-angle-down !m-0"></i>
                          </span>
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body !py-[3vw] md:!py-[1.25vw] !text-[1.25vw] !px-[2vw]">
                          <div className="table-responsive">
                            <table className="table info-table">
                              <tbody>
                                <tr>
                                  <td>Specialty</td>
                                  <td>{product?.additionalInfo?.speciality}</td>
                                </tr>
                                <tr>
                                  <td>Ingredient Type</td>
                                  <td>
                                    {product?.additionalInfo?.ingredientType}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Brand</td>
                                  <td>{product?.additionalInfo?.brand}</td>
                                </tr>
                                <tr>
                                  <td>Form</td>
                                  <td>{product?.additionalInfo?.form}</td>
                                </tr>
                                <tr>
                                  <td>Package Information</td>
                                  <td>
                                    {
                                      product?.additionalInfo
                                        ?.packageInformation
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Manufacturer</td>
                                  <td>
                                    {product?.additionalInfo?.manufacture}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Item part number</td>
                                  <td>
                                    {product?.additionalInfo?.itemPartNumber}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Net Quantity</td>
                                  <td>
                                    {product?.additionalInfo?.netQuantity}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item !bg-transparent">
                      <h2
                        className="accordion-header border-b border-black"
                        id="headingThree"
                      >
                        <button
                          className="accordion-button !bg-transparent collapsed flex poppins-regular justify-between !py-[2vw] md:!py-[1.15vw] !text-[4.25vw] md:!text-[1.95vw] !px-[2vw]"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                        >
                          Instructions
                          <span className="aspect-square w-[8.5vw] md:w-[2.5vw] shrink-0 rounded-full flex items-center justify-center">
                            <i className="fa-solid fa-angle-down !m-0"></i>
                          </span>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body !py-[3vw] md:!py-[1.25vw] !text-[1.25vw] !px-[2vw]">
                          {product?.careInstruction && (
                            <ReadyOnlyPlateEditor
                              value={product?.careInstruction}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-[14vw] md:pb-[7vw] !pt-0">
          <div className="container-fluid-lg">
            <h2 className="text-[4.5vw] md:text-[1.95vw] !text-[#0FB16E]">
              Related Products
            </h2>

            <div className=" mt-[5vw] md:mt-[2vw]">
              <div className="row g-8">
                {productList &&
                  productList.slice(0, 4) &&
                  productList.slice(0, 4).map((item: any) => {
                    const isItem = isItemExist(item._id);
                    const itemCount = (isItem && isItem?.quantity) || 0;
                    return (
                      <div
                        className="col-lg-3 col-md-4 col wow fadeInUp !bg-white"
                        key={item?._id}
                      >
                        <div className="rounded-[2vw] md:rounded-[.5vw] border border-[#ececec]">
                          <div className="product-image">
                            <a
                              href={`/product?id=${item?._id}`}
                              className="w-full h-full flex rounded-t-[2vw] md:rounded-t-[.5vw] overflow-hidden"
                            >
                              <img
                                src={item?.images[0]?.location}
                                className="!w-full !h-full !object-cover m-0"
                                alt=""
                              />
                            </a>
                          </div>

                          <div className="product-detail product-box-4 !border-0">
                            <div className={!isMobile ? "product-detail-name" : undefined}>
                              <a href={`/product?id=${item?._id}`}>
                                <h5 className="name">{item?.name}</h5>
                              </a>
                            </div>
                            <h5 className={!isMobile ? "price !text-[#0FB16E] product-detail-price" : "price !text-[#0FB16E]"}>
                              {" "}
                              AED {item?.price}
                            </h5>
                            <div className="price-qty flex items-center justify-between mt-[3vw] md:mt-[1vw]">
                              <div className="counter-number">
                                <div className="counter">
                                  <div
                                    className="qty-left-minus"
                                    data-type="minus"
                                    data-field=""
                                    onClick={() => handleRemoveItem(item)}
                                  >
                                    <i className="fa-solid fa-minus"></i>
                                  </div>
                                  <input
                                    className="form-control input-number qty-input"
                                    type="text"
                                    name="quantity"
                                    value={itemCount}
                                  />
                                  <div
                                    className="qty-right-plus"
                                    data-type="plus"
                                    data-field=""
                                    onClick={() => handleAddItem(item)}
                                  >
                                    <i className="fa-solid fa-plus"></i>
                                  </div>
                                </div>
                              </div>

                              <button
                                className="buy-button buy-button-2 btn btn-cart !bg-[#0FB16E] !w-[14vw] !h-[9vw] md:!w-[2vw] md:!h-[2.35vw] text-[5vw] md:text-[1.75vw]"
                                onClick={() => handleCartButtonClick(item)}
                              >
                                <i className="iconly-Buy icli text-white m-0"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>

        <CustomerReviews
          product={id}
          customer={customer}
          reviewList={reviewList}
        />
      </>
    </WebLayout>
  );
};

export default ProductInfo;
