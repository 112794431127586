import React, { useState } from "react";
import Modal from "components/Modal";
import { productReviewCreate } from "api/product/review";
import { Spinner } from "@material-tailwind/react";

type Props = {
  open: boolean;
  handler: (value: boolean) => void;
  close: () => void;
  product: any;
  customer: any;
};

const ratingLevel = [1, 2, 3, 4, 5];

const ReviewModal = (props: Props) => {
  const { product, customer } = props;

  const [reviewText, setReviewText] = useState<string>("");
  const [rating, setRating] = useState<number>(0);

  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleReviewText = (e: any) => {
    setReviewText(e.target.value);
  };

  const handleSaveReview = async () => {
    setIsSaving(true);
    setSaveStatus("");
    setErrorMsg("");

    const data = {
      review: reviewText,
      productId: product,
      reviewedBy: {
        id: customer?.c_user?._id,
        name: customer?.c_user?.name,
      },
      rating,
      comment: reviewText,
      attachments: [],
    };

    try {
      await productReviewCreate(data);
      setSaveStatus("completed");
      setIsSaving(false);
      setErrorMsg("");
      props.close();
    } catch (error) {
      setIsSaving(false);
      setSaveStatus("failed");
      setErrorMsg(error?.response?.data?.message);
    }
  };

  return (
    <>
      {/* write review modal */}
      <Modal
        open={props?.open}
        handler={props.handler}
        header={
          <div className="flex flex-row flex-1 justify-between">
            <h5 className="modal-title" id="exampleModalLabel">
              Write a review
            </h5>
            <button
              type="button"
              className="w-8 h-8 rounded-full text-red-500 hover:bg-red-500 hover:shadow-red-500 hover:shadow-md hover:text-white"
              onClick={() => props.close()}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        }
        footer={
          <div className="flex flex-1 flex-row justify-content-end align-items-center">
            <button
              className="btn text-white mx-1"
              onClick={() => props.close()}
              style={{
                backgroundColor: "rgb(255, 99, 71)",
                padding: "10px 20px",
                borderRadius: "5px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 5px",
              }}
            >
              Cancel
            </button>
            <button
              className="btn text-white mx-1"
              style={{
                backgroundColor: "rgb(50, 205, 50)",
                padding: "10px 20px",
                borderRadius: "5px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 5px",
              }}
              onClick={() => handleSaveReview()}
            >
              {isSaving && (
                <Spinner
                  className="h-4 w-4"
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
              )}
              Save review
            </button>
          </div>
        }
      >
        <div className="flex flex-1 flex-col">
          <span className="font-monospace text-black">
            Please provide your feedback here:
          </span>
          <textarea
            className="rounded-1 p-2"
            placeholder="Write your review..."
            onBlur={handleReviewText}
          />
          <div className="mt-4">
            <span className="font-monospace text-black py-1">Your Rating:</span>
            <div className="product-main-rating">
              <ul className="rating gap-[.5vw]">
                {ratingLevel.map((level: number) => (
                  <li onClick={() => setRating(level)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className={`feather feather-star !w-[5vw] !h-[5vw] md:!h-[1.75vw] md:!w-[1.75vw] ${
                        rating >= level ? " fill " : ""
                      }`}
                    >
                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                    </svg>
                  </li>
                ))}
              </ul>
              <h2 className="!font-normal font-monospace lowercase pl-[1vw]">
                {rating} out of 5
              </h2>
            </div>
          </div>
          {errorMsg && (
            <div className="flex flex-row flex-1 align-items-center justify-content-center font-monospace text-red-500 p-1 m-1">
              Error: {errorMsg}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ReviewModal;
