import React, { useState, useEffect } from "react";
import WebLayout from "../../../layout/WebLayout";
import HowItWorks from "./HowItWorks";
import BrowseByCategories from "./BrowseByCategories";
import ValueSection from "./ValueSection";
import ReviewSection from "./ReviewSection";
import BlogList from "./BlogList";
import { useNavigate } from "react-router-dom";

import { getProductList, productDelete } from "../../../api/product";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";

import {
  addItem,
  removeItem,
  updateQuantity,
} from "lib/redux/reducers/customer/cart";
import HomeSection from "./HomeSection";
import Faq from "./Faq";
import TrendingThisWeek from "./TrendingThisWeek";
import Banner from "./Banner";

type Props = {};

const Index = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cartState = useAppSelector((state) => state.cartReducer);

  const [productList, setProductList] = useState<any>();

  const fetchProductList = () => {
    getProductList()
      .then((res: any) => {
        setProductList(res?.result);
      })
      .catch((error: any) => {
        console.log("Failed to get product list : , error", error);
      });
  };
  useEffect(() => {
    if (!productList) fetchProductList();
    return () => {};
  }, []);

  const handleProductClick = (item: any) => {
    if (item?._id) {
      navigate(`/product?id=${item?._id}`);
    }
  };

  const handleCartClick = () => {
    navigate(`/cart`);
  };
  const handleShopNowClick = () => {
    navigate(`/shop`);
  };

  const isItemExist = (itemId: string) => {
    if (cartState?.items && cartState.items.length) {
      const existingItem = cartState.items.find(
        (item) => item.product._id === itemId
      );
      if (existingItem) return existingItem;

      return false;
    }
    return false;
  };

  const handleAddItem = (item: any) => {
    dispatch(addItem(item));
  };

  const handleRemoveItem = (item: any) => {
    const isItem = isItemExist(item._id);

    if (isItem && isItem.quantity > 0) {
      dispatch(
        updateQuantity({
          productId: item._id,
          quantity: isItem.quantity - 1,
        })
      );
    }

    if (isItem && isItem.quantity === 1) {
      dispatch(removeItem(item._id));
    }
  };

  return (
    <WebLayout>
      <HomeSection />
      <TrendingThisWeek />
      <Banner />
      {/* <!-- Fresh Vegetable Section Start --> */}
      <Faq />

      <BlogList />
      {/* <!-- Blog Section End --> */}
    </WebLayout>
  );
};

export default Index;
