import React, { useEffect } from "react";
// import Login from "pages/auth/Login";
import { RouterProvider } from "react-router-dom";
import router from "./routes";

function App() {
  useEffect(() => {
    if (typeof window !== "undefined" && "feather" in window) {
      window.feather.replace();
    }
  }, []);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
