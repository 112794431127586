import axios from "axios";
import { SERVER_URL } from "../../constants";

type AuthUserType = {
  name: string | undefined;
  email: string | undefined;
  password: string | undefined;
};

type UserDataType = {
  email: string;
  password: string;
};

/**
 * create a new user
 * @param data : user data
 * @returns Promise resolved with user account details
 */
export const createAuthUser = (data: AuthUserType) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/auth/signup`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const userLogin = (data: UserDataType) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/auth/login`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });