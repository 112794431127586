import React from "react";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";

type Props = {
  arrows?: boolean;
  dots?: boolean;
  slides?: number;
  scroll?: number;
  children: any;
  slidesToShow?: number;
  slidesToScroll?: number;
  speed?: number;
  infinite?: boolean;
  autoplay?: boolean;
  autoplaySpeed?: number;
  adaptiveHeight?: boolean;
  className?: string;
  dotsClass?: string;
};

const SliderComponent = ({
  dots,
  slides,
  scroll,
  children,
  speed = 500,
  infinite = true,
  slidesToShow = 3, // default number of slides to show
  slidesToScroll = 1,
  ...otherProps
}: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 640 }); // Tailwind's md breakpoint

  const settings = {
    dots: !!dots,
    infinite,
    speed,
    slidesToShow: isMobile ? 1 : slidesToShow,
    slidesToScroll: isMobile ? 1 : slidesToScroll,
    ...otherProps,
  };

  return <Slider {...settings}>{children}</Slider>;
};

export default SliderComponent;
