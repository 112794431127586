import React, { useState, useEffect } from "react";
import { useAppSelector } from "lib/redux/hooks";

import { getAddressByCustomer, getAddressData } from "api/address";
import {
  getCustomerData,
  customerUpdate,
  changeCustomerPassword,
} from "api/customer";

import { Button, Chip } from "@material-tailwind/react";
import Dialog from "components/Dialog";
import DatePickerComponent from "components/DatePicker";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Snackbar from "components/Snackbar";

type Props = {};

const Profile = (props: Props) => {
  const customer = useAppSelector((state) => state.customerReducer.c_user);

  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const [name, setName] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [birthday, setBirthday] = useState<Date | null>();
  const [gender, setGender] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [phone, setPhone] = useState<any>();
  const [pincode, setPincode] = useState<any>();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const fetchCustomerData = async () => {
    getCustomerData(customer?._id)
      .then((res: any) => {
        setName(res?.result?.name);
        setEmail(res?.result?.email);
        setGender(res?.result?.gender);
        console.log("birthday : ", res?.result?.birthday);
        setBirthday(res?.result?.birthday);
        setPhone(res?.result?.phone);
        setAddress(res?.result?.address);
        setPincode(res?.result?.pincode);
      })
      .catch((error) => {
        console.log("failed to fetch customer datails : ", error);
      });
    return () => {};
  };

  useEffect(() => {
    if (customer?._id) {
      fetchCustomerData();
    }
    return () => {};
  }, [customer?._id]);

  // edit profile functions
  const handleEditProfileModalOpen = () => {
    setOpenEditProfileModal(!openEditProfileModal);
  };

  const handleGenderClick = (e: any) => {
    setGender(e?.target?.value);
  };

  const handleEditProfileSave = () => {
    // TODO: handle profile Edit
    // to close modal after Edit completion
    const data = {
      name,
      address,
      phone,
      pincode,
      gender,
      birthday,
    };
    customerUpdate(customer?._id, data)
      .then((res: any) => {
        fetchCustomerData();
        // props.profileUpdated(true)
      })
      .catch((error) => {
        console.log("failed to update customer datails : ", error);
      });
    handleEditProfileModalOpen();
  };

  // delete profile functions
  const handleEditProfileClose = () => {
    handleEditProfileModalOpen();
  };

  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);

  const [currentPassword, setCurrentPassword] = useState<any>();
  const [newPassword, setNewPassword] = useState<any>();
  const [confirmPassword, setConfirmPassword] = useState<any>();

  const handleChangePassword = () => {
    setOpenChangePassword(!openChangePassword);
  };

  const cancelPassword = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setOpenChangePassword(!openChangePassword);
  };

  const changePassword = async () => {
    const data = {
      email,
      existingPassword: currentPassword,
      newPassword,
    };
    // const result = await changeCustomerPassword(data);
    // console.log("change password result : ", result);
    changeCustomerPassword(data)
      .then((res) => {
        console.log("order track result : ", res);
        handleSnackbar(
          true,
          "success",
          "Success !",
          "The password has been successfully updated."
        );
      })
      .catch((error) => {
        handleSnackbar(true, "error", "Error !", "Failed to change password");
      });
  };

  // Function to split address into <td> after every 2 words
  const formatAddressInTable = (address) => {
    return address
      .split(" ")
      .map((word, index) =>
        (index + 1) % 2 === 0 ? <td key={index}>{word} </td> : <>{word} </>
      );
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    // Format the date to "dd-mm-yyyy"
    const formattedDate =
      date.getUTCDate().toString().padStart(2, "0") +
      "-" +
      (date.getUTCMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getUTCFullYear();

    return formattedDate;
  };

  //snackbar
  const [openSnackbar, setOpenSnackbar] = useState<any>();
  const [type, setType] = useState<any>();
  const [heading, setHeading] = useState<any>();
  const [message, setMessage] = useState<any>();

  const hideSnackbar = () => {
    setTimeout(() => {
      setOpenSnackbar(false);
      setType("");
      setHeading("");
      setMessage("");
    }, 3000);
  };

  const handleSnackbar = (openSnackbar, type, heading, message) => {
    setOpenSnackbar(openSnackbar);
    setType(type);
    setMessage(message);
    setHeading(heading);
    hideSnackbar();
  };

  return (
    <>
      {openSnackbar && (
        <Snackbar type={type} heading={heading} message={message} />
      )}

      <div className="dashboard-profile">
        <div className="title">
          <h2>My Profile</h2>
          <span className="title-leaf">
            <svg className="icon-width bg-gray">
              <use xlinkHref="../../assets/svg/leaf.svg#leaf" />
            </svg>
          </span>
        </div>
        <div className="profile-detail dashboard-bg-box">
          <div className="dashboard-title">
            <h3>Profile Name</h3>
          </div>

          <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-center mb-2">
              <i className="fa-solid fa-user mr-2"></i> {/* Person icon */}
              <p style={{ fontSize: 16, color: "#4a5568" }}>{name}</p>
            </div>
            <div className="d-flex align-items-center">
              <i className="fa-solid fa-envelope mr-2"></i> {/* Email icon */}
              <p style={{ fontSize: 16, color: "#4a5568" }}>{email}</p>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ fontSize: 15, color: "#4a5568" }}
            >
              Do you wish to update profile?.
              <button
                onClick={handleEditProfileModalOpen}
                style={{
                  color: "#008000",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 16px",
                  borderRadius: "5px",
                }}
              >
                <i className="fa-solid fa-pen mr-2"></i> Edit
              </button>
            </div>
          </div>
          <div className="location-profile" style={{ marginTop: -20 }}></div>
          <div className="profile-description">
            <p>
              Residences can be classified by and how they are connected to
              neighbouring residences and land. Different types of housing
              tenure can be used for the same physical type.
            </p>
          </div>
        </div>
        <div className="profile-about dashboard-bg-box">
          <div className="row">
            <div className="col-xxl-7">
              <div className="dashboard-title mb-3">
                <h3>Profile About</h3>
              </div>
              <div className="table-responsive">
                <table className="table" style={{ border: "none" }}>
                  <tbody>
                    {/* <tr>
                    <td>Gender :</td>
                    <td>{gender}</td>
                  </tr> */}
                    <tr>
                      <td>Birthday :</td>
                      <td>{formatDate(birthday)}</td>
                    </tr>
                    <tr>
                      <td>Phone Number :</td>
                      <td>
                        <a href="javascript:void(0)"> {phone}</a>
                      </td>
                    </tr>
                    <tr>
                      <td>Address :</td>
                      <td>
                        {address && address.length > 25
                          ? `${address.slice(0, 60)}...`
                          : address}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Login Edit  */}

              <div className="dashboard-title mb-3">
                <h3>Login Details</h3>
              </div>
              <div className="table-responsive">
                <table className="table" style={{ border: "none" }}>
                  <tbody>
                    <tr>
                      <td>Email :</td>
                      <td>{email}</td>
                    </tr>
                    <tr>
                      <td>Password :</td>
                      <td>
                        <div
                          className="text-content"
                          onClick={handleChangePassword}
                          style={{ cursor: "pointer", color: "#008000" }}
                        >
                          Do you wish to Change Password?.
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {openChangePassword && (
                  <>
                    <div className="form-floating mb-4 theme-form-floating">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter current password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                      <label htmlFor="fname">Current Password</label>
                      <div
                        className="form-floating mb-4 theme-form-floating"
                        style={{ marginTop: "25px" }}
                      >
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter new password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <label htmlFor="fname">New Password</label>
                      </div>
                      <div className="form-floating mb-4 theme-form-floating m">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter confirm password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <label htmlFor="fname">Confirm Password</label>
                      </div>
                      <div className="d-flex justify-content-start mt-4">
                        <button
                          className="btn theme-bg-color btn-md  text-white mr-2" // Added margin-right for spacing
                          onClick={changePassword}
                        >
                          Change Password
                        </button>
                        <button
                          className="btn theme-bg-color btn-md  text-white"
                          onClick={cancelPassword}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-xxl-5">
              <div className="profile-image">
                <img
                  src="/assets/images/inner-page/dashboard-profile.png"
                  className="img-fluid blur-up lazyload"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal start */}
        <Dialog
          open={openEditProfileModal}
          handler={handleEditProfileModalOpen}
          header={
            <div className="flex flex-row flex-1 justify-between">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Contact Details
              </h5>
              <button
                type="button"
                className="w-8 h-8 rounded-full text-red-500 hover:bg-red-500 hover:shadow-red-500 hover:shadow-md hover:text-white"
                onClick={handleEditProfileClose}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
          }
          footer={
            <div className="flex flex-row">
              <button
                type="button"
                className="btn theme-bg-color btn-md text-white mx-1 bg-custom-secondary-btn"
                onClick={handleEditProfileClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn theme-bg-color btn-md text-white mx-1 bg-custom-primary-btn"
                onClick={handleEditProfileSave}
              >
                Save changes
              </button>
            </div>
          }
        >
          <div className="modal-body max-h-[450px] overflow-y-auto">
            <form>
              <div className="form-floating mb-4 theme-form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="fname"
                  placeholder="Enter First Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label htmlFor="fname">Name</label>
              </div>
            </form>

            <form>
              <div className="form-floating mb-4 theme-form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="lname"
                  placeholder="Enter Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <label htmlFor="lname">Phone Number</label>
              </div>
            </form>

            {/* <form>
            <div className="form-floating mb-4 theme-form-floating">
              <DatePicker
                selected={birthday}
                onChange={(date: null) => setBirthday(date)}
                dateFormat="MM/dd/yyyy"
                isClearable
                placeholderText="Select your birthday"
              />
              <label htmlFor="lname">Choose Birthday</label>
            </div>
          </form> */}

            <div className="mb-4 row align-items-center">
              <label className="col-sm-3 col-form-label form-label-title">
                Birthday
              </label>
              <div className="col-sm-9">
                <div
                  className="bs-example"
                  style={{
                    border: "1px solid #cccccc", // Adds a green border
                    padding: "10px", // Adds padding for better appearance
                    borderRadius: "5px", // Rounds the corners
                    width: 200,
                  }}
                >
                  <DatePicker
                    selected={birthday}
                    onChange={(date: null) => setBirthday(date)}
                    dateFormat="MM/dd/yyyy"
                    isClearable
                    placeholderText="Select your birthday"
                  />
                </div>
              </div>
            </div>
            {/* <form>
            <div className="form-floating mb-4 theme-form-floating">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id={"male"}
                  defaultChecked={false}
                  value="male"
                  onClick={(e) => handleGenderClick(e)}
                />
                <div className="label">
                  <label>Male</label>
                </div>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id={"male"}
                  defaultChecked={false}
                  value="male"
                  onClick={(e) => handleGenderClick(e)}
                />
                <div className="label">
                  <label>Female</label>
                </div>
              </div>

              <label htmlFor="pin">Gender</label>
            </div>
          </form> */}

            <form>
              <div className="form-floating mb-4 theme-form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="address"
                  style={{ height: 100 }}
                  defaultValue={""}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <label htmlFor="address">Enter Address</label>
              </div>
            </form>
            <form>
              <div className="form-floating mb-4 theme-form-floating">
                <input
                  type="test"
                  className="form-control"
                  id="pin"
                  placeholder="Enter address type"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
                <label htmlFor="pin">Pin Code</label>
              </div>
            </form>
          </div>
        </Dialog>
        {/* modal end */}
      </div>
    </>
  );
};

export default Profile;
