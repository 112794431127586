import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../lib/redux/hooks";

const CustomerProtectedRoute = ({ children }) => {
  const userAuthStatus = useAppSelector(
    (state) => state.customerReducer.c_user.authStatus
  );

  if (!userAuthStatus) {
    // return <Navigate to="/customer/login" />;
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};

export default CustomerProtectedRoute;
