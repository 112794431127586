import axios from "axios";
import { SERVER_URL } from "../../constants";

export const orderCreate = (data: Object) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/order/create`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const orderUpdate = (orderId: String, data: any) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${SERVER_URL}/order/update/${orderId}`, { data })
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const orderDelete = (orderId: String) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${SERVER_URL}/order/delete/${orderId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getOrderDataById = (orderId: String) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/order/get/${orderId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getOrderList = (customerId: string) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/order/list/by/customer/${customerId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

// TODO: Aggregated order data
export const getAllOrderList = () =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/order/list`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
  
  export const getOrderCount = (customerId: string) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${SERVER_URL}/order/list/by/count/customer/${customerId}`)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error));
    });
