import React, { useState } from "react";

import { PlateEditor } from "../components/Plate";
import { ReadyOnlyPlateEditor } from "components/Plate/ReadyOnlyPlateEditor";

type Props = {};

const EditorTest = (props: Props) => {
  const [editorState, setEditorState] = useState<any>("");

  const handleEditorExport = (data: string) => {
    console.log("editor state: ", data);
    setEditorState(data);
  };

  return (
    <div className="flex flex-1 flex-col w-screen h-screen p-2 max-h-screen overflow-auto">
      <h1 className="text-black m-2 text-md"> Editor Test</h1>
      <PlateEditor onExport={(value) => handleEditorExport(value)} />

      <div className="m-2">
        {editorState && <ReadyOnlyPlateEditor value={editorState} />}
      </div>
    </div>
  );
};

export default EditorTest;
