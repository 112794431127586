import React, { useEffect, useState } from "react";
import WebLayout from "../../layout/WebLayout";
import { useParams, useNavigate } from "react-router-dom";
import { PlateEditor } from "components/Plate";
import { ReadyOnlyPlateEditor } from "components/Plate/ReadyOnlyPlateEditor";

import { blogById, createBlog, getBlogList, blogByStatus } from "api/blog";

type Props = {};

const BlogDetails = (props: Props) => {
  // const { blogId } = useParams();

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const blogId = params.get('blogId');

  const [editorState, setEditorState] = useState<any>("");
  const [blogTitle, setBlogTitle] = useState<any>("");
  const [createdDate, setCreatedDate] = useState<any>("");
  const [blogAuthor, setBlogAuthor] = useState<any>("");
  const [blogImage, setBlogImage] = useState<any>("");

  const [blogList, setBlogList] = useState<any>();
  const [originalList, setOriginalList] = useState<any>(blogList);

  useEffect(() => {
    blogByStatus({
      status: "approved",
    })
      .then((res: any) => {
        setBlogList(res?.result);
        setOriginalList(res?.result);
      })
      .catch((error) => {
        console.log("Failed to get blog list : ", error);
      });
    return () => {};
  }, []);

  const handleEditorExport = (data: string) => {
    setEditorState(data);
  };

  const formatDate = (timestamp: string): string => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options).toUpperCase();
  };

  useEffect(() => {
    if (blogId) {
      blogById(blogId)
        .then((res: any) => {
          setEditorState(res?.result?.description);
          setBlogTitle(res?.result?.title);
          setCreatedDate(formatDate(res?.result?.createdAt));
          setBlogAuthor(res?.result?.auther);
          setBlogImage(res?.result?.images?.location);
        })
        .catch((error) => {
          console.log("failed to get blog data : ", error);
        });
    }

    return () => {};
  }, [blogId]);

    // Function to handle the search
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchTerm(value);
  
      if (value === "") {
        setBlogList(originalList);
      } else {
        const filteredData = blogList.filter(
          (item) =>
            item.title && item.title.toLowerCase().includes(value.toLowerCase())
        );
        setBlogList(filteredData);
      }
    };

  return (
    <WebLayout>
      {/* Blog Details Section Start */}
      <section className="blog-section section-b-space pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
        <div className="container-fluid-lg">
          <div className="row g-sm-4 g-3">
            <div className="col-xxl-3 col-xl-4 col-lg-5 d-lg-block d-none">
              <div className="left-sidebar-box">
                <div className="left-search-box">
                  <div className="search-box">
                    <input
                      type="search"
                      className="form-control"
                      id="exampleFormControlInput4"
                      placeholder="Search...."
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                </div>

                <div
                  className="accordion left-accordion-box"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-headingOne"
                    >
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                      >
                        Recent Post
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body pt-0">
                        <div className="recent-post-box">
                          {blogList &&
                            blogList.slice(0, 4) &&
                            blogList.slice(0, 4).map((item: any) => (
                              <div className="recent-box" key={item?._id}>
                                <a
                                  href={`/blog-details?blogId=${item?._id}`}
                                  className="recent-image"
                                >
                                  <img
                                    src={item?.images?.location}
                                    className="img-fluid blur-up lazyload"
                                    alt=""
                                  />
                                </a>

                                <div className="recent-detail">
                                  <a href={`/blog-details?blogId=${item?._id}`}>
                                    <h5 className="recent-name">
                                      {item?.title}
                                    </h5>
                                  </a>
                                  <h6>
                                    {formatDate(item?.createdAt)}{" "}
                                    <i data-feather="thumbs-up"></i>
                                  </h6>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-9 col-xl-8 col-lg-7 ratio_50">
              <div className="blog-detail-image rounded-3 mb-4">
                <img
                  src={blogImage}
                  className="bg-img blur-up lazyload"
                  alt=""
                />
                <div className="blog-image-contain">
                  {/* <ul className="contain-list">
                    <li>backpack</li>
                    <li>life style</li>
                    <li>organic</li>
                  </ul> */}
                  <h2>{blogTitle}</h2>
                  <ul className="contain-comment-list">
                    {blogAuthor && (
                      <li>
                        <div className="user-list">
                          <i data-feather="user" />
                          <span>{blogAuthor}</span>
                        </div>
                      </li>
                    )}

                    <li>
                      <div className="user-list">
                        <i data-feather="calendar" />
                        <span>{createdDate}</span>
                      </div>
                    </li>

                    {/* <li>
                      <div className="user-list">
                        <i data-feather="message-square"></i>
                        <span>82 Comment</span>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div className="blog-detail-contain">
                {editorState && <ReadyOnlyPlateEditor value={editorState} />}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Blog Details Section End */}
    </WebLayout>
  );
};

export default BlogDetails;
