import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  getProductList,
  productDelete,
  getProductListByFilter,
} from "api/product";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";

import Dialog from "components/Dialog";

import {
  addItem,
  removeItem,
  updateQuantity,
} from "lib/redux/reducers/customer/cart";

import { c_updateFilterState } from "lib/redux/reducers/customer/filterSlice";
import Snackbar from "components/Snackbar";

type Props = {};

const TrendingThisWeek = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cartState = useAppSelector((state) => state.cartReducer);
  const filterProduct = useAppSelector(
    (state) => state.filterReducer?.c_filter
  );
  
  const [productList, setProductList] = useState<any>();

  const fetchProductList = () => {
    getProductList()
      .then((res: any) => {
        setProductList(res?.result);
      })
      .catch((error: any) => {
        console.log("Failed to get product list : , error", error);
      });
  };
  useEffect(() => {
    if (!productList) fetchProductList();
    return () => {};
  }, []);

  const handleProductClick = (item: any) => {
    if (item?._id) {
      navigate(`/product?id=${item?._id}`);
    }
  };

  const handleCartClick = () => {
    navigate(`/cart`);
  };
  const handleShopNowClick = () => {
    navigate(`/shop`);
  };

  const isItemExist = (itemId: string) => {
    if (cartState?.items && cartState.items.length) {
      const existingItem = cartState.items.find(
        (item) => item.product._id === itemId
      );
      if (existingItem) return existingItem;

      return false;
    }
    return false;
  };

  const handleAddItem = (item: any) => {
    dispatch(addItem(item));
    handleSnackbar(
      true,
      "success",
      "Success !",
      "The item has been successfully added to your cart."
    );
  };

  const handleRemoveItem = (item: any) => {
    const isItem = isItemExist(item._id);

    if (isItem && isItem.quantity > 0) {
      dispatch(
        updateQuantity({
          productId: item._id,
          quantity: isItem.quantity - 1,
        })
      );
    }

    if (isItem && isItem.quantity === 1) {
      dispatch(removeItem(item._id));
    }
  };

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (filterProduct) {
      setOpenModal(filterProduct?.openFilterModal);
    }

    return () => {};
  }, [filterProduct]);

  // edit profile functions
  const handleModalOpen = () => {
    setOpenModal(!openModal);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(
      c_updateFilterState({
        allergens: selected,
        productsList: [],
        openFilterModal: false,
        applyFilter: true,
      })
    );
  };

  const [selected, setSelected] = useState<any>([]);
  const items = [
    "Milk",
    "Eggs",
    "Peanuts",
    "Tree nuts",
    "Soy",
    "Wheat",
    "Fish",
    "Shellfish",
    "Sesame",
  ];

  const handleChipClick = (item) => {
    if (selected.includes(item)) {
      setSelected(selected.filter((selectedItem) => selectedItem !== item));
    } else {
      setSelected([...selected, item]);
    }
  };

  const [limit, setLimit] = useState<any>(8);

  const handleApplyFilter = () => {
    const filterProducts = selected;

    getProductListByFilter(filterProducts)
      .then((res: any) => {
        dispatch(
          c_updateFilterState({
            allergens: selected,
            productsList: res?.result,
            openFilterModal: false,
            applyFilter: true,
          })
        );
        setOpenModal(false);
        navigate(`/shop`);
      })
      .catch((error: any) => {
        console.log("Failed to get product list : , error", error);
      });
  };

  //snackbar
  const [openSnackbar, setOpenSnackbar] = useState<any>();
  const [type, setType] = useState<any>();
  const [heading, setHeading] = useState<any>();
  const [message, setMessage] = useState<any>();

  const hideSnackbar = () => {
    setTimeout(() => {
      setOpenSnackbar(false);
      setType("");
      setHeading("");
      setMessage("");
    }, 3000);
  };

  const handleSnackbar = (openSnackbar, type, heading, message) => {
    setOpenSnackbar(openSnackbar);
    setType(type);
    setMessage(message);
    setHeading(heading);
    hideSnackbar();
  };

  return (
    <>
      {openSnackbar && (
        <Snackbar type={type} heading={heading} message={message} />
      )}
      <section className="product-section py-[15vw] pb-[4vw] md:pt-[9vw] md:pb-[2vw] relative">
        <img
          src="/assets/images/home-new/pattern.png"
          className="absolute inset-0 opacity-[.019] h-[90%] md:h-auto object-cover top-[0%] md:top-[20%]"
          alt=""
        />
        <div className="container-fluid-lg">
          <div className="title w-100 d-flex flex-column justify-content-center align-items-center">
            <img
              src="/assets/images/home-new/second-logo.png"
              className="w-[15vw] md:w-[8vw]"
              alt=""
            />
            <h2 className="text-[8vw] md:text-[3vw] !font-normal poppins-regular !text-[#0FB16E]  mt-[3vw] md:mt-[1vw] !normal-case">
              Most loved this week
            </h2>
            {/* <div class="w-[60%] mx-auto h-[1px] bg-[#F15624]/70 mt-[3vw] md:mt-[1.75vw]"></div> */}
          </div>
          <div className="tab-content mt-[2vw] relative" id="myTabContent">
            <div className="tab-pane fade show active" id="all" role="tabpanel">
              <div className="row g-8">
                {productList &&
                  productList.slice(0, limit) &&
                  productList
                    .slice(0, limit)
                    .map((item: any, index: number) => {
                      const isItem = isItemExist(item._id);
                      const itemCount = (isItem && isItem?.quantity) || 0;
                      const isMobile = window.innerWidth < 768;

                      return (
                        <div className="col-lg-3 col-md-4 col wow fadeInUp !bg-white">
                          <div className="rounded-[2vw] md:rounded-[.5vw] border border-[#ececec]">
                            <div
                              className="product-image"
                              onClick={() => handleProductClick(item)}
                              style={{ cursor: "pointer" }}
                            >
                              <a
                                href={`/product?id=${item?._id}`}
                                className="w-full h-full flex rounded-t-[2vw] md:rounded-t-[.5vw] overflow-hidden"
                              >
                                <img
                                  src={item?.images[0]?.location}
                                  className="!w-full !h-full !object-cover m-0"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="product-detail product-box-4 !border-0">
                              <div className={!isMobile ? "product-detail-name" : undefined}>
                                <a href={`/product?id=${item?._id}`}>
                                  <h5 className="name">{item?.name}</h5>
                                </a>
                              </div>
                              <h5 className={!isMobile ? "price !text-[#0FB16E] product-detail-price" : "price !text-[#0FB16E]"}>
                                {" "}
                                AED {item?.price}
                              </h5>
                              <div className="price-qty flex items-center justify-between mt-[3vw] md:mt-[1vw]">
                                <div className="counter-number">
                                  <div className="counter">
                                    <div
                                      className="qty-left-minus"
                                      data-type="minus"
                                      data-field=""
                                      onClick={() => handleRemoveItem(item)}
                                    >
                                      <i className="fa-solid fa-minus" />
                                    </div>
                                    <input
                                      className="form-control input-number qty-input"
                                      type="text"
                                      name="quantity"
                                      value={itemCount}
                                    />
                                    <div
                                      className="qty-right-plus"
                                      data-type="plus"
                                      data-field=""
                                      onClick={() => handleAddItem(item)}
                                    >
                                      <i className="fa-solid fa-plus" />
                                    </div>
                                  </div>
                                </div>
                                <button
                                  className="buy-button buy-button-2 btn btn-cart !bg-[#0FB16E] !w-[14vw] !h-[9vw] md:!w-[2vw] md:!h-[2.35vw] text-[5vw] md:text-[1.75vw]"
                                  onClick={() => {
                                    // handleAddItem(item);
                                    handleCartClick();
                                  }}
                                >
                                  <i className="iconly-Buy icli text-white m-0" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
              <div className="flex justify-center md:mt-0 mt-[8vw]">
                <button
                  onClick={() => {
                    handleShopNowClick();
                  }}
                  className="btn mt-sm-5   btn-2  text-white !bg-[#F15624] !px-[5vw] !py-[2.5vw] md:!py-[.85vw] md:!px-[2.15vw] !rounded-[2vw] !rounded-[2vw] md:!rounded-[.65vw]"
                >
                  <strong>Go to Shop</strong>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal --> */}

        <Dialog
          open={openModal}
          handler={handleModalOpen}
          header={
            <div className="flex flex-row flex-1 justify-between">
              <h5 className="modal-title" id="exampleModalLabel">
                Filter Products
              </h5>
              <button
                type="button"
                className="w-8 h-8 rounded-full text-red-500 hover:bg-red-500 hover:shadow-red-500 hover:shadow-md hover:text-white"
                onClick={handleCloseModal}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
          }
        >
          <div className="modal-body">
            <h4
              className="poppins-regular text-left"
              style={{ fontSize: "20px", marginBottom: "20px" }}
            >
              Would you like to filter products based ingredients?
            </h4>
            <h4
              className="poppins-regular text-left"
              style={{ fontSize: "15px", marginBottom: "20px" }}
            >
              Choose allergens you want to filter.
            </h4>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              {items.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleChipClick(item)}
                  style={{
                    display: "inline-block",
                    margin: "5px",
                    padding: "10px 20px",
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    borderRadius: "5px",
                    cursor: "pointer",
                    backgroundColor: selected.includes(item)
                      ? "#cbf5cd"
                      : "#ffcccb",
                    // color: selected.includes(item) ? "#ffffff" : "#000000",
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div className="flex items-center justify-end shrink-0 flex-wrap p-4 text-blue-gray-500">
            <div className="flex flex-row">
              <button
                type="button"
                className="btn text-white mx-1"
                style={{
                  backgroundColor: "#ff6347",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                }}
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn text-white mx-1"
                style={{
                  backgroundColor: "#32cd32",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                }}
                onClick={handleApplyFilter}
              >
                Apply Filter
              </button>
            </div>
          </div>
        </Dialog>
      </section>
    </>
  );
};

export default TrendingThisWeek;
