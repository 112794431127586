import React from "react";
import WebLayout from "../../layout/WebLayout";
import SliderComponent from "../../components/Slider";

type Props = {};

const About = (props: Props) => {
  return (
    <WebLayout>
      <>
        {/* Fresh Vegetable Section Start */}
        <section className="fresh-vegetable-section section-lg-space pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
          <div className="container-fluid-lg">
            <div className="row gx-xl-5 gy-xl-0 g-3 ratio_148_1">
              <div className="col-xl-6 col-12">
                <div className="fresh-image">
                  <div>
                    <img
                      src="/assets/images/about-us-hidden-health.jpeg"
                      className="blur-up lazyload"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-12">
                <div className="fresh-contain p-center-left">
                  <div>
                    <div className="review-title">
                      <h2 className="text-[8vw] md:text-[3vw] !font-normal !text-[#0FB16E]">
                        Welcome to Hidden Health
                      </h2>
                    </div>

                    <div className="delivery-list">
                      <p className="text-content">
                        Where we’re revolutionizing the way busy individuals and
                        families approach mealtime. We know that juggling a
                        hectic schedule can make it challenging to maintain a
                        healthy lifestyle. Our mission is to support parents and
                        professionals in their quest for a balanced diet,
                        ensuring that lack of time never makes them compromise
                        on quality or nutrition, even on their busiest days. We
                        aim to make nutritious meals accessible and effortless.
                        <br />
                        <br /> We imagine quick and convenient foods that
                        secretly give you an extra bite of health. We might give
                        you a sandwich filling or pasta sauce – But with
                        goodness hidden in it. The Hidden Health products are
                        crafted using traditional recipes with an added variety
                        of hidden vegetables which will increase the nutritional
                        value of the meals without losing the taste or visual
                        appeal, especially for the kids.
                        <br />
                        <br />
                        Choose Hidden Health and make nutritious food a seamless
                        part of your everyday routine, ensuring the well-being
                        of those you care about most.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row gx-xl-5 gy-xl-0 g-3 ratio_148_1">
              <div className="col-xl-6 col-12">
                <div className="fresh-contain p-center-left">
                  <div>
                    <div className="review-title">
                      <h2 className="text-[8vw] md:text-[3vw] !font-normal !text-[#0FB16E]">
                        A Mother & A Master Chef
                      </h2>
                    </div>

                    <div className="delivery-list">
                      <p className="text-content">
                        The journey of Hidden Health began when Manmeet Kaur, a
                        successful entrepreneur and mother of two found out
                        that, in her busy schedule, the options to prepare quick
                        and nutritious meals were very limited. Preparing
                        healthy meals would take a lot of time. She wanted a
                        solution that would not compromise her profession or her
                        love for her children. That is when she came up with the
                        idea of hidden health. Manmeet partnered up with Avinash
                        Mohan, a renowned and award-winning Chef in UAE, who is
                        also a successful entrepreneur. It was a perfect team
                        that came together to set up Hidden Health. Avinash with
                        his mastery skills was able to culminate different
                        recipes keeping the vision of Hidden Health in mind.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="fresh-image">
                  <div>
                    <img
                      src="/assets/images/about-us-2.jpg"
                      className="blur-up lazyload"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Fresh Vegetable Section End */}
        {/* Client Section Start */}
        <section className="client-section section-lg-space">
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col-12">
                <div className="slider-3_1 product-wrapper">
                  <SliderComponent dots={true}>
                    <div>
                      <div className="clint-contain">
                        <div className="client-icon">
                          <img
                            src="../assets/svg/mission.png"
                            className="blur-up lazyload"
                            alt=""
                          />
                        </div>
                        <h4>Our Mission</h4>
                        <p>
                          We aim to support parents and professionals in their
                          quest for a balanced diet, ensuring that lack of time
                          never makes them compromise on quality or nutrition!{" "}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="clint-contain">
                        <div className="client-icon">
                          <img
                            src="../assets/svg/vision.png"
                            className="blur-up lazyload"
                            alt=""
                          />
                        </div>
                        <h4>Our Vision</h4>
                        <p>
                          We aspire to be a part of every family’s journey towards
                          a healthier lifestyle, making nutritious meals
                          accessible to everyone, anywhere.
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="clint-contain">
                        <div className="client-icon">
                          <img
                            src="../assets/svg/values.png"
                            className="blur-up lazyload"
                            alt=""
                          />
                        </div>
                        <h4>Our Values</h4>
                        <p>
                          At Hidden Health, we prioritize quality and
                          authenticity, using only the best ingredients to
                          ensure our products are both wholesome and nutritious.
                        </p>
                      </div>
                    </div>
                  </SliderComponent>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Client Section End */}
        <section className="fresh-vegetable-section section-lg-space">
          <div className="container-fluid-lg">
            <div className="row gx-xl-5 gy-xl-0 g-3 ratio_148_1">
              <div className="about-us-title text-center">
                <h2 className="center text-[8vw] md:text-[3vw] !font-normal !text-[#0FB16E]">
                  Meet Our Founders
                </h2>
              </div>
              <div className="col-xl-6 col-12">
                <div className="fresh-contain p-center-left">
                  <div>
                    <div className="review-title">
                      <h4>Co-Founder</h4>
                      <h2 className="text-[8vw] md:text-[3vw] !font-normal !text-[#0FB16E]">
                        Manmeet Kaur
                      </h2>
                    </div>

                    <div className="delivery-list">
                      <p className="text-content">
                        Manmeet Kaur is a successful entrepreneur and business
                        strategist with over 15 years of experience working in
                        various verticals. Her major regime is IT and
                        Management. She is an enterprising, extroverted, and
                        customer-focused sales leader. She holds a bachelor's
                        degree in Software Engineering from Pune University,
                        India. She is the founder and Managing Director of
                        Praxis Solutions, Dubai – UAE.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="fresh-image">
                  <div>
                    <img
                      src="/assets/images/maneeth.jpg"
                      className="blur-up lazyload fresh-image-founder"
                      alt=""
                      style={{ borderRadius: "10%" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row gx-xl-5 gy-xl-0 g-3 ratio_148_1">
              <div className="col-xl-6 col-12">
                <div className="fresh-image">
                  <div>
                    <img
                      src="/assets/images/avinash.jpg"
                      className="blur-up lazyload fresh-image-founder"
                      alt=""
                      style={{ borderRadius: "10%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="fresh-contain p-center-left">
                  <div>
                    <div className="review-title">
                      <h4>Co-Founder</h4>
                      <h2 className="text-[8vw] md:text-[3vw] !font-normal !text-[#0FB16E]">
                        Chef Avinash Mohan
                      </h2>
                    </div>

                    <div className="delivery-list">
                      <p className="text-content">
                        Avinash Mohan is a multiple award-winning Chef based in
                        Dubai. Having started his career with the Oberoi Mumbai,
                        Avinash has worked with many world-class chefs and led
                        5-star hotel kitchens across India, Europe, Africa, and
                        the Middle East. His last achievements were as the
                        Executive chef of Address Marina, Director of Culinary
                        at Palace Downtown, and then Head of F&B Operations for
                        EMAAR Hospitality at EXPO2020. He recently got
                        shortlisted as Top 10 Chef-Patron of the Year by Caterer
                        Middle East. He owns and runs a fine-dine restaurant
                        called ‘Rasam by Avinash Mohan’ in Discovery Gardens
                        Dubai.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </WebLayout>
  );
};

export default About;
