import React from "react";

type Props = {};

const Footer = (props: Props) => {
  return (
    <footer className="section-t-space">
      <div className="container-fluid-lg">
        <div className="sub-footer section-small-space">
          <div className="reserve">
            <h6 className="text-content">
              ©2024 Hidden Health. All rights reserved
            </h6>
          </div>

          <div className="payment">
            <img
              src="/assets/images/payment/1.png"
              className="blur-up lazyload"
              alt=""
            />
          </div>

          <div className="social-link">
            <h6 className="text-content">Stay connected :</h6>
            <ul>
              <li>
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/" target="_blank">
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/" target="_blank">
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="https://in.pinterest.com/" target="_blank">
                  <i className="fa-brands fa-pinterest-p"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
