import axios from "axios";

import { SERVER_URL } from "../../../constants";

type CreateReviewData = {
  productId: string;
  reviewedBy: {
    id?: string;
    name?: string;
  };
  rating?: number;
  review?: string;
  attachments?: string[];
};

export interface ReviewData {
  productId: string;
  reviewedBy: {
    id: string;
    name: string;
  };
  rating: number;
  comment: string;
  attachments?: string[];
}

export const productReviewCreate = (data: CreateReviewData) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/product/review/create`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const productReviewUpdate = (reviewId: string, data: any) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${SERVER_URL}/product/review/update/${reviewId}`, { data })
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const productReviewDelete = (reviewId: string) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${SERVER_URL}/product/review/delete/${reviewId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getProductReviewList = (productId: string) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/product/review/get/by/product/${productId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getProductReviewById = (reviewId: string) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/product/review/get/by/id/${reviewId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getProductReviewByCustomer = (
  productId: string,
  customerId: string
) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `${SERVER_URL}/product/review/get/by/customer/${customerId}/product/${productId}`
      )
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
