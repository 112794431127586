import axios from "axios";
import { SERVER_URL } from "../../constants";

export const createMessage = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/complaints/create`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getMessageList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/complaints/list`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
