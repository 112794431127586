import React, { useState, useEffect } from "react";

type Props = {};

const CookiesBar = (props: Props) => {
  const cookieState = localStorage.getItem("allowCookies");
  const [visible, setVisible] = useState<boolean>(false);

  const handleOkClick = () => {
    setVisible(false);
    localStorage.setItem("allowCookies", JSON.stringify({ status: true }));
  };

  useEffect(() => {
    if (cookieState) {
      setVisible(!JSON.parse(cookieState).status);
    } else {
      setVisible(true);
    }

    return () => {};
  }, [cookieState]);

  return (
    <div className={visible ? "cookie-bar-box" : "cookie-bar-box hidden"}>
      <div className="cookie-box">
        <div className="cookie-image">
          <img
            src="/assets/images/cookie-bar.png"
            className="blur-up lazyload"
            alt=""
          />
          <h2>Cookies!</h2>
        </div>

        <div className="cookie-contain">
          <h5 className="text-content">
            We use cookies to make your experience better
          </h5>
        </div>
      </div>

      <div className="button-group">
        <button className="btn privacy-button" onClick={handleOkClick}>
          Privacy Policy
        </button>
        <button className="btn ok-button" onClick={handleOkClick}>
          OK
        </button>
      </div>
    </div>
  );
};

export default CookiesBar;
