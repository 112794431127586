import axios from "axios";
import { SERVER_URL } from "../../constants";

// type blogType = {
//   title: string | undefined;
//   description: object | undefined;
//   images: string | undefined;
//   status: string| undefined;
//   shortDescription: string| undefined;
//   createdBy: string |undefined;
//   blogByType : string | undefined;
//   blogData : string | undefined;

// };

export const createBlog = (data: Object) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/blog/create`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const blogUpdate = (blogId: String, data: any) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${SERVER_URL}/blog/update/${blogId}`, { data })
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const blogDelete = (blogId: String) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${SERVER_URL}/blog/delete/${blogId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const blogById = (blogId: String) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/blog/get/${blogId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const blogByCustomerId = (customerId: String) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/blog/get/customer/${customerId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getBlogList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/blog/list`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const blogByUserType = (data: any) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/blog/get/createdBy`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const blogByStatus = (data: any) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/blog/get/by/status`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
