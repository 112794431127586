import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

type Props = {
  open: boolean;
  handler: (value: boolean) => void;
  header?: string | React.ReactNode;
  footer?: string | React.ReactNode;
  children: React.ReactNode;
};

const Modal = (props: Props) => {
  return (
    <>
      <Dialog
        open={props?.open}
        handler={props?.handler}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        {props?.header && (
          <DialogHeader
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            {props?.header}
          </DialogHeader>
        )}
        <DialogBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {props?.children}
        </DialogBody>
        {props?.footer && (
          <DialogFooter
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            {props?.footer}
          </DialogFooter>
        )}
      </Dialog>
    </>
  );
};

export default Modal;
