import SliderComponent from "components/Slider";
import React from "react";

import { useNavigate } from "react-router-dom";

import "./HomeSectionStyle.css";

type Props = {};

const HomeSection = (props: Props) => {
  const navigate = useNavigate();

  const handleShopNowClick = () => {
    navigate(`/shop`);
  };
  const handleAboutClick = () => {
    navigate(`/about-us`);
  };

  return (
    <>
      {/* Home Section Start */}
      <section
        id="home-section"
        className="pt-0 h-[100vh] md:h-screen relative z-[1] w-full !overflow-hidden"
      >
        <div className="h-screen w-full !overflow-hidden">
          <div className="slider-1 h-screen">
            <SliderComponent
              dots={true}
              slidesToShow={1}
              slidesToScroll={1}
              autoplay={true}
              autoplaySpeed={2000}
              dotsClass="slick-dots custom-dot"
            >
              <div className="h-screen relative !mx-0">
                <div
                  className="h-100 bg-contain blur-up lazyloaded"
                  style={{
                    backgroundImage:
                      'url("/assets/images/home-new/landing.jpg")',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    display: "block",
                  }}
                >
                  <img
                    src="/assets/images/home-new/landing.jpg"
                    className="bg-img blur-up lazyloaded h-100"
                    alt=""
                    style={{ display: "none" }}
                  />
                </div>
                <div className="absolute inset-0 h-100">
                  <div className="container-fluid-lg h-100 flex flex-col justify-center md:justify-end py-[10vw] md:py-[3vw] gap-[35vw] md:gap-[5vw] mt-[15vw] md:mt-0">
                    <h2 className="title text-white font-semibold text-[12vw] md:text-[7vw] leading-[.9]">
                      A secret to
                      <br />
                      Wellness
                      <br />
                      In every bite!
                    </h2>
                    <div className="flex items-center gap-[2vw] justify-center">
                      <button
                        onClick={() => {
                          handleShopNowClick();
                        }}
                        className="btn mt-sm-4 btn-2 !bg-[#F15624] text-white !px-[5vw] !py-[2.5vw] md:!py-[.85vw] md:!px-[2.15vw] !rounded-[2vw] md:!rounded-[.65vw]"
                      >
                        <strong>Shop Now</strong>
                      </button>
                      <button
                        onClick={() => {
                          handleAboutClick();
                        }}
                        className="btn mt-sm-4 btn-2 !bg-[#F15624] text-white !px-[5vw] !py-[2.5vw] md:!py-[.85vw] md:!px-[2.15vw] !rounded-[2vw] md:!rounded-[.65vw]"
                      >
                        Discover &nbsp;<strong>Hidden Health</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-100 relative !mx-0">
                <div
                  className="h-screen bg-cover blur-up lazyloaded"
                  style={{
                    backgroundImage:
                      'url("https://plus.unsplash.com/premium_photo-1661346030734-acdfd5fe235f?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    display: "block",
                  }}
                >
                  <img
                    src="https://plus.unsplash.com/premium_photo-1661346030734-acdfd5fe235f?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="bg-img blur-up lazyloaded h-100"
                    alt=""
                    style={{ display: "none" }}
                  />
                </div>
                <div className="absolute inset-0 h-100">
                  <div className="container-fluid-lg h-100 flex flex-col justify-center md:justify-end py-[10vw] md:py-[3vw] gap-[35vw] md:gap-[5vw] mt-[15vw] md:mt-0">
                    <h2 className="title text-white font-semibold text-[12vw] md:text-[7vw] leading-[.9]">
                      A secret to
                      <br />
                      Wellness
                      <br />
                      In every bite!
                    </h2>
                    <div className="flex items-center gap-[2vw] justify-center">
                      <button
                        onClick={() => {
                          handleShopNowClick();
                        }}
                        className="btn mt-sm-4 btn-2 !bg-[#F15624] text-white !px-[5vw] !py-[2.5vw] md:!py-[.85vw] md:!px-[2.15vw] !rounded-[2vw] md:!rounded-[.65vw]"
                      >
                        <strong>Shop Now</strong>
                      </button>
                      <button
                        onClick={() => {
                          handleAboutClick();
                        }}
                        className="btn mt-sm-4 btn-2 !bg-[#F15624] text-white !px-[5vw] !py-[2.5vw] md:!py-[.85vw] md:!px-[2.15vw] !rounded-[2vw] md:!rounded-[.65vw]"
                      >
                        Discover &nbsp;<strong>Hidden Health</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-100 relative !mx-0">
                <div
                  className="h-screen bg-cover blur-up lazyloaded"
                  style={{
                    backgroundImage:
                      'url("https://images.unsplash.com/photo-1484256017452-47f3e80eae7c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    display: "block",
                  }}
                >
                  <img
                    src="https://images.unsplash.com/photo-1484256017452-47f3e80eae7c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="bg-img blur-up lazyloaded h-100"
                    alt=""
                    style={{ display: "none" }}
                  />
                </div>
                <div className="absolute inset-0 h-100">
                  <div className="container-fluid-lg h-100 flex flex-col justify-center md:justify-end py-[10vw] md:py-[3vw] gap-[35vw] md:gap-[5vw] mt-[15vw] md:mt-0">
                    <h2 className="title text-white font-semibold text-[12vw] md:text-[7vw] leading-[.9]">
                      A secret to
                      <br />
                      Wellness
                      <br />
                      In every bite!
                    </h2>
                    <div className="flex items-center gap-[2vw] justify-center">
                      <button
                        onClick={() => {
                          handleShopNowClick();
                        }}
                        className="btn mt-sm-4 btn-2 !bg-[#F15624] text-white !px-[5vw] !py-[2.5vw] md:!py-[.85vw] md:!px-[2.15vw] !rounded-[2vw] md:!rounded-[.65vw]"
                      >
                        <strong>Shop Now</strong>
                      </button>
                      <button
                        onClick={() => {
                          handleAboutClick();
                        }}
                        className="btn mt-sm-4 btn-2 !bg-[#F15624] text-white !px-[5vw] !py-[2.5vw] md:!py-[.85vw] md:!px-[2.15vw] !rounded-[2vw] md:!rounded-[.65vw]"
                      >
                        Discover &nbsp;<strong>Hidden Health</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </SliderComponent>
          </div>
        </div>
      </section>
      {/* Home Section End */}
    </>
  );
};

export default HomeSection;
