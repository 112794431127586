import React, { useState } from "react";
import AuthLayout from "../../../layout/AuthLayout";
import WebLayout from "../../../layout/WebLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { createAuthCustomer } from "../../../api/customer";

import { Spinner } from "@material-tailwind/react";
import { useAppDispatch } from "../../../lib/redux/hooks";

type Props = {};

const Signup = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      setErrorMsg("");
      createAuthCustomer(values)
        .then((res: any) => {
          setIsSubmitting(false);
          // navigate("/customer/login");
          navigate("/login");
        })
        .catch((error: any) => {
          setIsSubmitting(false);
          setErrorMsg("Failed to create account, please try again.");
          console.log("user creation failed : ", error);
        });
    },
  });

  return (
    <WebLayout>
   
      {/* log in section start */}
      <section className="log-in-section section-b-space pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
        <div className="container-fluid-lg w-100">
          <div className="row">
            <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
              <div className="image-contain">
                <img
                  src="/assets/images/logo/login.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
              <div className="log-in-box">
                <div className="log-in-title">
                  <h3>Welcome To Hidden Health</h3>
                  <h4>Create New Account</h4>
                </div>

                <div className="input-box">
                  <form className="row g-4" onSubmit={formik.handleSubmit}>
                    <div className="col-12">
                      <div className="form-floating theme-form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Full Name"
                          onChange={(e) => {
                            setName(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={name}
                        />
                        <label htmlFor="fullname ">Full Name</label>
                      </div>
                      {formik.touched.name && formik.errors.name ? (
                        <div className="error-msg text-red-500 text-sm">{formik.errors.name}</div>
                      ) : null}
                    </div>
                    <div className="col-12">
                      <div className="form-floating theme-form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Email Address"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={email}
                        />
                        <label htmlFor="email">Email Address</label>
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error-msg text-red-500 text-sm">{formik.errors.email}</div>
                      ) : null}
                    </div>

                    <div className="col-12">
                      <div className="form-floating theme-form-floating">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={password}
                        />
                        <label htmlFor="password">Password</label>
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="error-msg text-red-500 text-sm">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-12">
                      <div className="forgot-box">
                        <div className="form-check ps-0 m-0 remember-box">
                          <input
                            className="checkbox_animated check-box"
                            type="checkbox"
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            I agree with
                            <span>Terms</span> and <span>Privacy</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      {errorMsg && (
                        <div className="m-1 text-red-500 text-sm">
                          {errorMsg}
                        </div>
                      )}
                      <button className="btn btn-animation w-100" type="submit">
                        {isSubmitting ? (
                          <>
                            {/* @ts-ignore */}
                            <Spinner
                              className="mx-1 text-white"
                              color="green"
                            />
                          </>
                        ) : (
                          "Sign Up"
                        )}
                      </button>
                    </div>
                  </form>
                </div>

                {/* <div className="other-log-in">
                            <h6>or</h6>
                        </div> */}

                {/*  <div className="log-in-button">
                            <ul>
                                <li>
                                    <a href="https://accounts.google.com/signin/v2/identifier?flowName=GlifWebSignIn&flowEntry=ServiceLogin"
                                        className="btn google-button w-100">
                                        <img src="/assets/images/inner-page/google.png" className="blur-up lazyload"
                                            alt="" />
                                        Sign up with Google
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/" className="btn google-button w-100">
                                        <img src="/assets/images/inner-page/facebook.png" className="blur-up lazyload"
                                            alt="" /> Sign up with Facebook
                                    </a>
                                </li>
                            </ul>
                        </div> */}

                <div className="other-log-in">
                  <h6></h6>
                </div>

                <div className="sign-up-box">
                  <h4>Already have an account?</h4>
                  {/* <a href="/customer/login">Log In</a> */}
                  <a href="/login">Log In</a>
                </div>
              </div>
            </div>

            <div className="col-xxl-7 col-xl-6 col-lg-6"></div>
          </div>
        </div>
      </section>

    </WebLayout>
  );
};

export default Signup;
