import axios from "axios";

import { SERVER_URL } from "../../constants";

export const productCreate = (data: Object) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/product/create`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const productUpdate = (productId: String, data: any) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${SERVER_URL}/product/update/${productId}`, { data })
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const productDelete = (productId: String) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${SERVER_URL}/product/delete/${productId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getProductData = (productId: String) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/product/get/${productId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const productListByCategoryId = (categoryId: String) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/product/get/category/${categoryId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getProductList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/product/list`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getProductListByCategories = (categories: Array<string>) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/product/list/by/categories`, { categories })
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

  export const getProductListByFilter = (filterProducts: Array<string>) =>
    new Promise((resolve, reject) => {
      console.log('filter Products: ', filterProducts);
      axios
        .post(`${SERVER_URL}/product/list/filter`, { filterProducts })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error));
    });
