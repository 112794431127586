import { getOrderList } from "api/order";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getOrderDataById, orderUpdate } from "api/order";

import { orderTrackCreate } from "api/orderTracking";

import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import Dialog from "components/Dialog";
import { useNavigate } from "react-router-dom";

type Props = {};

const orderStatusList = [
  { value: "order_placed", label: "Order Placed", color: "#fd49a0" },
  { value: "order_confirmed", label: "Order Confirmed", color: "#8F00FF" },
  { value: "food_assembly", label: "Food Assembly", color: "#ff9800" },
  { value: "out_for_delivery", label: "Out For Delivery", color: "#3f51b5" },
  { value: "delivered", label: "Delivered", color: "#4caf50" },
  { value: "cancelled", label: "Cancelled", color: "#FF0000" },
];

const Orders = (props: Props) => {
  // const { customerId } = useParams();
  const customerId = useAppSelector(
    (state) => state.customerReducer?.c_user?._id
  );

  const customerEmail = useAppSelector(
    (state) => state.customerReducer?.c_user?.email
  );

  console.log("customer email : ", customerEmail);

  const [orderList, setOrderList] = useState<any>([]);
  const [orderId, setOrderId] = useState<any>();
  const [orderStatus, setOrderStatus] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (customerId) {
      orderDataFetch();
    }

    return () => {};
  }, [customerId]);

  const orderDataFetch = () => {
    getOrderList(customerId)
      .then((response: any) => {
        console.log("order list by customer id : ", response);
        setOrderList(response);
      })
      .catch((error) => {
        console.log("Failed to get order data : ", error);
      });
  };

  const getOrderStatus = (orderStatus) => {
    const _filterStatus = orderStatusList.filter(
      (_status) => _status?.value === orderStatus
    );

    if (_filterStatus.length > 0) {
      return _filterStatus[0]?.label;
    } else {
      return "Pending";
    }
  };

  // address delete
  const [openCancelOrderModal, setOpenCancelOrderModal] = useState(false);
  const [cancelOrder, setCancelOrder] = useState<any>();

  const handleCancelOrderModalOpen = () => {
    setOpenCancelOrderModal(!openCancelOrderModal);
  };

  const handleCancelOrderClose = () => {
    handleCancelOrderModalOpen();
  };

  const handleCancelClick = (item: any) => {
    setOrderId(item?._id);
    handleCancelOrderModalOpen();
  };

  const handleCancelOrder = async () => {
    const statusData = {
      orderStatus: {
        status: "cancelled",
        description: "Order Cancelled",
      },
      mail: customerEmail,
    };

    await orderUpdate(orderId, statusData);

    const data = {
      orderId,
      customerId: customerId,
      status: "cancelled",
    };

    const result = await orderTrackCreate(data);
    if (result) {
      orderDataFetch();
    }
    handleCancelOrderClose();
  };

  const formatDate = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    const day = dateObject.getDate(); // Get the day
    const month = dateObject.getMonth() + 1; // Get the month (0-indexed, so add 1)
    const year = dateObject.getFullYear(); // Get the full year

    // Format the date as '13-9-2024'
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const formatTime = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);

    let hours = dateObject.getHours();
    const minutes = String(dateObject.getMinutes()).padStart(2, "0"); // Get minutes and ensure 2 digits
    const ampm = hours >= 12 ? "PM" : "AM"; // Determine AM or PM
    hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format (handle 0 as 12)

    // Format the time as '8:22 PM'
    const formattedTime = `${hours}:${minutes} ${ampm}`;
    return formattedTime;
  };
  const continueShopping = () => {
    navigate(`/shop`);
  };

  return (
    <div className="dashboard-order">
      <div className="title title-flex">
        <div>
          <h2>My Orders</h2>
          <span className="title-leaf title-leaf-gray">
            <svg className="icon-width bg-gray">
              <use xlinkHref="../../assets/svg/leaf.svg#leaf" />
            </svg>
          </span>
        </div>
        <button
          onClick={continueShopping}
          className="btn theme-bg-color text-white btn-sm fw-bold mt-lg-0 mt-3"
        >
          <i className="fa-solid fa-arrow-left-long me-2"></i>
          Continue Shopping
        </button>
      </div>
      <div className="order-contain flex flex-1 flex-col">
        {orderList && orderList.length ? (
          orderList
            .sort((a, b) => {
              const dateA = new Date(a.createdAt).getTime();
              const dateB = new Date(b.createdAt).getTime();
              return dateB - dateA;
            })
            .map((item) => (
              <div
                className="order-box dashboard-bg-box flex flex-1 flex-col"
                key={item?._id}
              >
                <div
                  className="order-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative", // Set the container to relative positioning
                  }}
                >
                  <div className="order-detail">
                    <h4>
                      Deliver Status:{" "}
                      <span>{getOrderStatus(item?.orderStatus?.status)}</span>
                    </h4>
                    <div className="flex flex-col my-1">
                      <span style={{ paddingTop: "5px" }}>
                        Order ID : {item._id}
                      </span>

                      <span style={{ paddingTop: "5px" }}>
                        <i className="fas fa-calendar-alt"></i> :{" "}
                        {formatDate(item?.createdAt)}{" "}
                        {formatTime(item?.createdAt)}
                      </span>
                      <span
                        className="text-md font-bold"
                        style={{ paddingTop: "5px" }}
                      >
                        Total : {item.currency.toUpperCase()}{" "}
                        {item.orderTotal.roundedTotal}
                      </span>
                      <div>
                        <a
                          href={`/order-tracking-details?orderId=${item?._id}`}
                          className="btn btn-md fw- mt-sm-3 mt-1 mend-auto"
                          style={{
                            maxWidth: "150px",
                            textAlign: "center",
                            backgroundColor: "#0FB16E",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          Track Your Order
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute", // Set the button's container to absolute positioning
                      right: "0", // Align to the right edge of the container
                      top: "0",
                      marginTop: -20,
                    }}
                  >
                    {(item?.orderStatus?.status == "pending" ||
                      item?.orderStatus?.status == "order_placed" ||
                      item?.orderStatus?.status == "order_confirmed" ||
                      item?.orderStatus?.status == "food_assembly" ||
                      item?.orderStatus?.status == "out_for_delivery") && (
                      <button
                        className="btn btn-md fw- mt-sm-3 mt-1 mend-auto"
                        style={{
                          maxWidth: "200px",
                          textAlign: "center",
                          backgroundColor: "#ff4f4f",
                          color: "white",
                          fontSize: "14px",
                        }}
                        onClick={() => handleCancelClick(item)}
                      >
                        Cancel Your Order
                      </button>
                    )}
                  </div>
                </div>

                <div
                  className="product-order-detail flex flex-col flex-wrap ml-4 items-start"
                  style={{ alignItems: "start !important" }}
                >
                  {item.products.map((product: any, index: number) => (
                    <div
                      className="col-md-12 d-flex"
                      key={product?._id || index}
                    >
                      <a href="/shop" className="">
                        <img
                          src={product?.thumbImage}
                          className="lazyload w-[144px] h-[144px] mr-4"
                          alt=""
                          style={{ borderRadius: "10px" }}
                        />
                      </a>

                      <div className="order-wrap">
                        <a href={`/product?id=${product?._id}`}>
                          <h3 style={{ fontSize: "18px" }}>{product?.name}</h3>
                        </a>
                        {/* <p className="text-content">
                    Cheddar dolcelatte gouda. Macaroni cheese cheese strings
                    feta halloumi cottage cheese jarlsberg cheese triangles say
                    cheese.
                  </p> */}
                        <ul className="product-size">
                          <li>
                            <div className="size-box">
                              <h6 className="text-content">Price : </h6>
                              <h5>{product?.price}</h5>
                            </div>
                          </li>
                          {/* <li>
                          <div className="size-box">
                            <h6 className="text-content">Rate : </h6>
                            <div className="product-rating ms-2">
                              <ul className="rating">
                                <li>
                                  <i data-feather="star" className="fill" />
                                </li>
                                <li>
                                  <i data-feather="star" className="fill" />
                                </li>
                                <li>
                                  <i data-feather="star" className="fill" />
                                </li>
                                <li>
                                  <i data-feather="star" className="fill" />
                                </li>
                                <li>
                                  <i data-feather="star" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li> */}
                          {/* <li>
                          <div className="size-box">
                            <h6 className="text-content">Sold By : </h6>
                            <h5>Fresho</h5>
                          </div>
                        </li> */}
                          <li>
                            <div className="size-box">
                              <h6 className="text-content">Quantity : </h6>
                              <h5>{product?.quantity}</h5>
                            </div>
                          </li>
                          {/* <li>
                          <a
                            href={`/orderTracking?orderId=${item?._id}&productId=${product?._id}`}
                            className="btn theme-bg-color text-white btn-md fw-bold mt-sm-3 mt-1 mend-auto"
                            style={{ maxWidth: "50%", textAlign: "center" }}
                          >
                            View Order
                          </a>
                        </li> */}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
        ) : (
          <div className="flex flex-1 flex-col text-black text-md items-center justify-center">
            No orders{" "}
          </div>
        )}
      </div>

      {/* delete order start */}
      <Dialog
        open={openCancelOrderModal}
        handler={handleCancelOrderModalOpen}
        header={
          <div className="modal-header flex flex-1 justify-between">
            <h5 className="modal-title" id="exampleModalLabel">
              Cancel order
            </h5>
            <button type="button" onClick={handleCancelOrderClose}>
              <i className="fa-solid fa-xmark" />
            </button>
          </div>
        }
        footer={
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-md"
              onClick={handleCancelOrderClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn theme-bg-color btn-md text-white"
              onClick={handleCancelOrder}
            >
              Yes, Cancel this order
            </button>
          </div>
        }
      >
        <p>
          <h3>Do you want to cancel this order? </h3>
        </p>
      </Dialog>
      {/* delete order end */}
    </div>
  );
};

export default Orders;
