import axios from "axios";
import { SERVER_URL } from "../../constants";

type categoryType = {
  name: string | undefined;
  description: string | undefined;
  image: string | undefined;
};

export const createCategory = (data: categoryType) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/category/create`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const categoryUpdate = (categoryId: String, data: any) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${SERVER_URL}/category/update/${categoryId}`, { data })
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const categoryDelete = (categoryId: String) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${SERVER_URL}/category/delete/${categoryId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const categoryById = (categoryId: String) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/category/get/${categoryId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getCategoryList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/category/list`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
