import React, {useEffect} from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Footer from "./Footer";

type Props = {
  children: React.ReactNode;
};

const PaymentLayout = ({ children }: Props) => {
  useEffect(() => {
    const openBtn = document.getElementById("navbar-toggler");
    const closeBtn = document.getElementById("nav-close-btn");
    const nav = document.getElementById("mobile-nav-modal");

    const openModal = () => {
      if (nav) {
        nav.classList.remove("-translate-x-full");
      }
    };

    const closeModal = () => {
      if (nav) {
        nav.classList.add("-translate-x-full");
      }
    };

    if (openBtn) {
      openBtn.addEventListener("click", openModal);
    }

    if (closeBtn) {
      closeBtn.addEventListener("click", closeModal);
    }

    // Cleanup event listeners on unmount
    return () => {
      if (openBtn) {
        openBtn.removeEventListener("click", openModal);
      }
      if (closeBtn) {
        closeBtn.removeEventListener("click", closeModal);
      }
    };
  }, []);
  
  return (
    <>
      {/* <!-- Header Start --> */}
      <header className="pb-md-4 pb-0">
        <div className="top-nav top-header sticky-header">
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col-12">
                <div className="navbar-top">
                  <button
                    className="navbar-toggler d-xl-none d-inline navbar-menu-button"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#primaryMenu"
                  >
                    <span className="navbar-toggler-icon">
                      <i className="fa-solid fa-bars"></i>
                    </span>
                  </button>
                  <a href="/" className="web-logo nav-logo">
                    <img
                      src="/assets/images/logo/logo.png"
                      className="img-fluid blur-up lazyload"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid-lg d-lg-none">
          <div className="row">
            <div className="col-12">
              <div className="header-nav">
                <div className="header-nav-middle">
                  <div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
                    <div
                      className="offcanvas offcanvas-collapse order-xl-2"
                      id="primaryMenu"
                    >
                      <div className="offcanvas-header navbar-shadow">
                        <h5>Menu</h5>
                        <button
                          className="btn-close lead"
                          type="button"
                          data-bs-dismiss="offcanvas"
                        ></button>
                      </div>
                      <div className="offcanvas-body">
                        <ul className="navbar-nav">
                          <li className="nav-item">
                            <a className="nav-link" href="/">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/about-us">
                              About Us
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/shop">
                              Shop
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/subscription">
                              Subscription
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/blog">
                              Blogs
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/contact-us">
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- Header End --> */}

      {children}

      {/* <!-- Footer Section Start --> */}
      <Footer />
      {/* <!-- Footer Section End --> */}

      {/* <!-- Tap to top and theme setting button start --> */}
      <div className="theme-option">
        <div className="back-to-top">
          <a id="back-to-top" href="#">
            <i className="fas fa-chevron-up"></i>
          </a>
        </div>
      </div>
      {/* <!-- Tap to top and theme setting button end --> */}

      {/* <!-- Bg overlay Start --> */}
      <div className="bg-overlay"></div>
      {/* <!-- Bg overlay End --> */}
    </>
  );
};

export default PaymentLayout;
