import React from "react";

type Props = {
  level: number;
  size?: string;
};

const ratingLevel = [1, 2, 3, 4, 5];

const Rating = (props: Props) => {
  return (
    <div className="product-rating mt-[1vw] md:mt-0">
      <ul className="rating gap-[.5vw]">
        {ratingLevel.map((level: number) => (
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={props?.size || "24"}
              height={props?.size || "24"}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className={`feather feather-star !w-[5vw] !h-[5vw] md:!h-[1.75vw] md:!w-[1.75vw] ${
                props?.level >= level ? " fill " : ""
              }`}
            >
              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
            </svg>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Rating;
