import axios from "axios";
import { SERVER_URL } from "../../constants";

type AuthCustomerType = {
  name: string | undefined;
  email: string | undefined;
  password: string | undefined;
};

type CustomerDataType = {
  email: string;
  password: string;
};

/**
 * create a new customer
 * @param data : customer data
 * @returns Promise resolved with customer account details
 */
export const createAuthCustomer = (data: AuthCustomerType) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/customer/create`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const customerLogin = (data: CustomerDataType) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/customer/login`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const findCustomerByEmail = (data) =>
  new Promise((resolve, reject) => {
    console.log("forgot password data : email", data);
    axios
      .post(`${SERVER_URL}/customer/find/email`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const changeCustomerPassword = (data: any) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/customer/change/password`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const resetCustomerPassword = (data: any) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/customer/reset/password`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getCustomerData = (customerId: String) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/customer/get/${customerId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const customerUpdate = (customerId: String, data: any) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${SERVER_URL}/customer/update/${customerId}`, { data })
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
