import React from "react";
import WebLayout from "../../layout/WebLayout";

type Props = {};

const Subscription = (props: Props) => {
  return (
    <WebLayout>
      <div>subscription</div>
    </WebLayout>
  );
};

export default Subscription;
