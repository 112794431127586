import { createBrowserRouter } from "react-router-dom";
import StartPage from "../pages/web/MainPage";

import Login from "../pages/authentication/Login";
import Signup from "../pages/authentication/Signup";
// import ForgotPassword from "../pages/authentication/ForgotPassword";

import Shop from "../pages/web/Shop";
import About from "../pages/web/About";
import Subscription from "../pages/web/Subscription";
import Blog from "../pages/web/Blog";
import BlogDetails from "../pages/web/BlogDetails";
import Contact from "../pages/web/Contact";
import FAQ from "../pages/web/FAQ";
import Cart from "../pages/web/Cart";
import Checkout from "../pages/web/Checkout";
import PaymentPage from "../pages/web/Payment/PaymentPage";
import OrderTracking from "../pages/web/OrderTracking";
import OrderTrackingDetails from "../pages/web/OrderTrackingDetails";
import Search from "../pages/web/Search";

import ProductInfo from "pages/web/ProductInfo";

// user dashboard
import CustomerDashboard from "../pages/web/customer/Dashboard";
import CustomerRegistration from "../pages/web/customer/Signup";
import CustomerLogin from "../pages/web/customer/Login";
import ForgotPassword from "../pages/web/customer/ForgotPassword";
import ResetPassword from "pages/web/customer/ResetPassword";

import EditorTest from "../pages/EditorTest";

// dashboard
import CustomerProtectedRoute from "./CustomerProtectedRoute";

import PaymentSuccess from "pages/web/Payment/PaymentSuccess";
import PaymentFailed from "pages/web/Payment/PaymentFailed";

const router = createBrowserRouter([
  {
    path: "/",
    element: <StartPage />,
  },
  {
    path: "/editor",
    element: <EditorTest />,
  },
  {
    path: "/shop?category",
    element: <Shop />,
  },
  {
    path: "/product?",
    element: <ProductInfo />,
  },
  {
    path: "/about-us",
    element: <About />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/blog-details?",
    element: <BlogDetails />,
  },
  {
    path: "/cart",
    element: <Cart />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/contact-us",
    element: <Contact />,
  },
  {
    path: "/faq",
    element: <FAQ />,
  },
  {
    path: "/order-tracking",
    element: <OrderTracking />,
  },
  {
    path: "/order-tracking-details?",
    element: <OrderTrackingDetails />,
  },
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/shop",
    element: <Shop />,
  },
  {
    path: "/subscription",
    element: <Subscription />,
  },
  {
    path: "/payment",
    element: (
      <CustomerProtectedRoute>
        <PaymentPage />
      </CustomerProtectedRoute>
    ),
  },

  {
    path: "/payment-status",
    element: <PaymentSuccess />,
  },
  {
    path: "/payment-status-cancel",
    element: <PaymentFailed />,
  },
  {
    // path: "/customer/account/:customerId",
    path: "/customer-account?",
    element: (
      <CustomerProtectedRoute>
        <CustomerDashboard />
      </CustomerProtectedRoute>
    ),
  },
  {
    // path: "/customer/login",
    path: "/login",
    element: <CustomerLogin />,
  },
  {
    // path: "/customer/register",
    path: "/register",
    element: <CustomerRegistration />,
  },

  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },

  {
    path: "/reset-password",
    element: <ResetPassword />,
  },

  // admin authentication
  {
    // path: "/login",
    path: "/admin/login",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <Signup />,
  },
]);

export default router;
