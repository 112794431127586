import React, { useEffect, useState } from "react";

import Payment from "components/Payment";
import PaymentLayout from "layout/PaymentLayout";

import { createCheckoutToken } from "api/payment";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { Spinner } from "@material-tailwind/react";

type Props = {};

const PaymentPage = (props: Props) => {
  const dispatch = useAppDispatch();
  const customer = useAppSelector((state) => state?.customerReducer?.c_user);
  const checkout = useAppSelector((state) => state?.checkoutReducer?.checkout);

  const {
    items = [],
    discount_coupon = "",
    isShippingCost = false,
    delivery_address = {
      addressName: "",
      userAddress: "",
      pincode: "",
      phone: "",
    },
    total = 0,
    grandTotal = 0,
    discount = 0,
    shippingCost = 0,
    tax = 0,
    paymentMode,
  } = checkout || {};

  const clientSecret = checkout?.clientSecret;

  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  return (
    <PaymentLayout>
      <div className="flex flex-1 flex-col lg:flex-row m-4">
        <div className="flex flex-col">
          {delivery_address && delivery_address?.addressName && (
            <div className="flex flex-col m-2">
              <h3 className="text-black text-2xl">Delivery Address</h3>
              <div className="p-2 w-auto rounded-md shadow-md bg-white">
                <div className="deliver-address-section py-3 px-2 flex flex-1 flex-col flex-wrap">
                  <div className="flex-row flex">
                    <p className="text-md text-black">
                      {delivery_address?.addressName},
                    </p>
                    <p className="text-md text-black">
                      {delivery_address?.userAddress},
                    </p>
                    <p className="text-md text-black">
                      {delivery_address?.pincode}
                    </p>
                  </div>
                  <p className="text-md text-black">
                    Phone: {delivery_address?.phone}
                  </p>
                </div>
              </div>
            </div>
          )}

          {items && items.length ? (
            <div className="flex flex-1 flex-col m-2">
              <div className="flex flex-col">
                <div className="flex flex-col">
                  <div className="my-2">
                    <h3 className="text-black text-2xl">Products</h3>
                  </div>
                  <ul className="p-2 flex flex-col rounded-md shadow-md bg-white">
                    {items &&
                      items.map((item: any) => (
                        <li
                          className="flex flex-row flex-1 justify-between my-1"
                          key={item?.product?._id}
                        >
                          <span className="flex flex-row">
                            <img
                              src={item?.product?.thumbImages[0]?.location}
                              className="w-16 h-16 blur-up lazyloaded checkout-image rounded-sm"
                              alt=""
                            />
                            <h4 className="text-md font-bold px-2">
                              {item?.product?.name}{" "}
                              <span>X {item?.quantity}</span>
                            </h4>
                          </span>
                          <h4 className="price text-md text-black px-2 items-center my-auto">
                            AED{" "}
                            {parseFloat(item?.product?.price) * item?.quantity}
                          </h4>
                        </li>
                      ))}
                  </ul>

                  <ul className="mt-4 flex flex-col py-4 px-2 rounded-md shadow-md bg-white">
                    <li className="flex flex-1 justify-between py-1">
                      <h4 className="text-md text-bold">Subtotal</h4>
                      <h4 className="text-md text-black font-medium">
                        AED {total.toFixed(2)}
                      </h4>
                    </li>
                    <li className="flex flex-1 justify-between py-1">
                      <h4 className="text-md">Shipping</h4>
                      <h4 className="text-md text-black font-medium">
                        AED {shippingCost}
                      </h4>
                    </li>
                    <li className="flex flex-1 justify-between py-1">
                      <h4 className="text-md">Tax</h4>
                      <h4 className="text-md text-black font-medium">
                        AED {tax}
                      </h4>
                    </li>
                    <li className="flex flex-1 justify-between py-1">
                      <h4 className="text-md">Coupon/Code</h4>
                      <h4 className="text-md text-black font-medium">
                        AED -{discount}
                      </h4>
                    </li>

                    <li className="flex flex-1 justify-between py-1 mt-2">
                      <h4 className="text-md font-semibold">Total (AED)</h4>
                      <h4 className="text-md text-black font-semibold">
                        AED {grandTotal.toFixed(2)}
                      </h4>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="flex flex-1 flex-col">
          <h4 className="text-2xl my-4 lg:mx-4">Payment</h4>

          {/* <Payment className="p-8" clientSecret={clientSecret} /> */}
        </div>
      </div>
    </PaymentLayout>
  );
};

export default PaymentPage;
