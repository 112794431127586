import React, { useState, useEffect, useRef } from "react";
import Dialog from "components/Dialog";
import { PlateEditor } from "components/Plate";
import { ReadyOnlyPlateEditor } from "components/Plate/ReadyOnlyPlateEditor";

import { useAppSelector } from "../../../../lib/redux/hooks";
import { blogByCustomerId, blogDelete, blogUpdate, createBlog } from "api/blog";
import { uploadFile } from "api/upload";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

type Props = {};

const Blogs = (props: Props) => {
  const customer = useAppSelector((state) => state.customerReducer?.c_user);

  const imagesInputRef = useRef<any>();
  const [title, setTitle] = useState<any>();
  const [auther, setAuther] = useState<any>();
  const [description, setDescription] = useState<any>();

  const [shortDescription, setShortDescription] = useState<any>();
  const [images, setImages] = useState<any>();
  const [newImages, setNewImages] = useState<any>();

  const [blogList, setBlogList] = useState<any>();

  const resetForm = () => {
    setTitle("");
    setAuther("");
    setDescription("");
    setShortDescription("");
    setImages("");
    setNewImages("");
  };

  const fetchBlogList = async () => {
    blogByCustomerId(customer?._id)
      .then((res: any) => {
        setBlogList(res?.result);
      })
      .catch((error) => {
        console.log("Failed to get blog Result : ", error);
      });
  };

  useEffect(() => {
    if (customer?._id) {
      fetchBlogList();
    }
    return () => {};
  }, [customer?._id]);

  const handleImagesChange = (e: any) => {
    setNewImages(e.target.files[0]);
    setImages(e.target.files[0]);
  };

  const handleImagesClose = () => {
    if (imagesInputRef.current) {
      imagesInputRef.current.value = "";
    }
    setImages(null);
    setNewImages(null);
  };

  // create blog functions
  const [openCreateBlogModal, setOpenCreateBlogModal] = useState(false);

  const handleCreateBlogModalOpen = () => {
    setEditItem("");
    setOpenCreateBlogModal(!openCreateBlogModal);
  };

  const handleDescription = (value: string) => {
    setDescription(value);
  };

  const handleCreateBlogSave = async () => {
    try {
      if (!customer?._id || !title || !description || !shortDescription) {
        return false;
      }

      const imagesFormData = new FormData();
      imagesFormData.append("file", images);

      const imagesUploadResult: any = await uploadFile(imagesFormData);

      const data = {
        createdBy: customer?._id,
        title,
        auther,
        description,
        shortDescription,
        images: imagesUploadResult.files[0],
        status: "pending",
        blogByType: "customer",
        blogData: "Blog Data",
      };
      const result = await createBlog(data);
      if (result) {
        await fetchBlogList();
        handleCreateBlogModalOpen();
        resetForm();
      }
    } catch (error) {
      handleCreateBlogModalOpen();
      resetForm();
      console.log("failed to create blog", error);
    }
  };

  const handleCreateBlogClose = () => {
    handleCreateBlogModalOpen();
  };

  // edit blog functions
  const [openEditBlogModal, setOpenEditBlogModal] = useState(false);
  const [editItem, setEditItem] = useState<any>();

  const handleEditClick = (item: any) => {
    setEditItem(item);
    setTitle(item?.title);
    setAuther(item?.auther);
    setDescription(item?.description);
    setShortDescription(item?.shortDescription);
    setImages(item?.images);
    handleEditBlogModalOpen();
  };

  const handleEditBlogModalOpen = () => {
    setOpenEditBlogModal(!openEditBlogModal);
  };

  const handleEditBlogSave = async () => {
    // TODO: handle blog Edit
    try {
      if (!customer?._id) {
        return false;
      }

      const imagesFormData = new FormData();
      imagesFormData.append("file", images);

      const uploadImagesResult: any = await uploadFile(imagesFormData);

      const data = {
        createdBy: customer?._id,
        title,
        description,
        auther,
        shortDescription,
        images: uploadImagesResult?.files[0] || images,
        status: "pending",
        blogByType: "customer",
        blogData: "Blog data",
      };
      if (editItem) {
        const result = await blogUpdate(editItem?._id, data);
        await fetchBlogList();
        handleEditBlogModalOpen();
        resetForm();
      }
    } catch (error) {
      console.log("failed to create blog", error);
    }
  };

  const handleEditBlogClose = () => {
    resetForm();
    handleEditBlogModalOpen();
  };

  // delete blog functions
  const [openDeleteBlogModal, setOpenDeleteBlogModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState<any>();

  const handleRemoveClick = (item: any) => {
    setDeleteItem(item);
    handleDeleteBlogModalOpen();
  };

  const handleDeleteBlogModalOpen = () => {
    setOpenDeleteBlogModal(!openDeleteBlogModal);
  };

  const handleDeleteBlogConfirm = async () => {
    if (deleteItem) {
      // blog delete code here
      const result = await blogDelete(deleteItem?._id);

      await fetchBlogList();
      handleDeleteBlogModalOpen();
    }
  };

  const handleDeleteBlogClose = () => {
    handleDeleteBlogModalOpen();
    setDeleteItem("");
  };

  const formatDate = (timestamp: string): string => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options).toUpperCase();
  };

  // view blog functions
  const [openViewBlogModal, setOpenViewBlogModal] = useState(false);
  const [selectedViewBlog, setSelectedViewBlog] = useState<any>();

  const handleViewBlogModalOpen = () => {
    setOpenViewBlogModal(!openViewBlogModal);
  };

  const handleViewBlogClose = () => {
    setSelectedViewBlog("");
    handleViewBlogModalOpen();
  };

  const readBlog = (blogData: any) => {
    setSelectedViewBlog(blogData);
    handleViewBlogModalOpen();
  };

  return (
    <>
      <div className="dashboard-address">
        <div className="title title-flex">
          <div>
            <h2>My Blogs</h2>
            <span className="title-leaf">
              <svg className="icon-width bg-gray">
                <use xlinkHref="../../assets/svg/leaf.svg#leaf" />
              </svg>
            </span>
          </div>
          <button
            className="btn theme-bg-color text-white btn-sm fw-bold mt-lg-0 mt-3"
            onClick={handleCreateBlogModalOpen}
          >
            <i data-feather="plus" className="me-2" /> Add New Blog
          </button>
        </div>
        <div className="row g-sm-4 g-3">
          {blogList && blogList.length ? (
            blogList.map((_blog: any, index: number) => (
              <div
                className="col-xxl-4 col-xl-6 col-lg-12 col-md-6"
                key={_blog?._id || index}
              >
                <div className="address-box">
                  <div>
                    <div className="label">
                      <button onClick={() => readBlog(_blog?.description)}>
                        Read Blog
                      </button>
                    </div>
                    <div className="table-responsive address-table">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td colSpan={2}>{_blog.title.substring(0, 25)}</td>
                          </tr>
                          <tr>
                            <td>Date : </td>
                            <td>
                              <p>{formatDate(_blog?.createdAt)}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>File :</td>
                            <td>{_blog.shortDescription}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="button-group">
                    <button
                      className="btn btn-sm add-button w-100"
                      onClick={() => handleEditClick(_blog)}
                    >
                      <i data-feather="edit" />
                      Edit
                    </button>
                    <button
                      className="btn btn-sm add-button w-100"
                      onClick={() => handleRemoveClick(_blog)}
                    >
                      <i data-feather="trash-2" />
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-1 flex-col text-black text-md items-center justify-center">
              No Blogs{" "}
            </div>
          )}
        </div>
      </div>

      <>
        {/* Add Blogs start */}
        <Dialog
          open={openCreateBlogModal}
          handler={handleCreateBlogModalOpen}
          header={
            <div className="flex flex-row flex-1 justify-between">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Blog
              </h5>
              <button
                type="button"
                className="w-8 h-8 rounded-full text-gray-500 hover:bg-gray-200 hover:text-gray-800 flex items-center justify-center"
                onClick={handleCreateBlogClose}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
          }
          footer={
            <div className="flex flex-row">
              <button
                type="button"
                className="btn theme-bg-color btn-md text-white mx-1 bg-custom-secondary-btn"
                onClick={handleCreateBlogClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn theme-bg-color btn-md text-white mx-1 bg-custom-primary-btn"
                onClick={handleCreateBlogSave}
              >
                Save changes
              </button>
            </div>
          }
        >
          <div className="modal-body max-h-[550px] overflow-y-auto">
            <div className="modal-body">
              <div className="form-floating mb-4 theme-form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="blogTitle"
                  placeholder="Enter First Name"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <label htmlFor="blogTitle">Title</label>
              </div>

              <div className="form-floating mb-4 theme-form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="blogTitle"
                  placeholder="Enter First Name"
                  value={auther}
                  onChange={(e) => setAuther(e.target.value)}
                />
                <label htmlFor="blogTitle">Auther Name</label>
              </div>

              <div className="form-floating mb-4 theme-form-floating">
                <textarea
                  className="form-control"
                  placeholder="Short description about your blog"
                  id="short-description"
                  style={{ height: 100 }}
                  value={shortDescription}
                  onChange={(e) => setShortDescription(e.target.value)}
                />
                <label htmlFor="address">Short Description</label>
              </div>

              <div className="form-control">
                <label className="col-sm-3 col-form-label form-label-title">
                  Description
                </label>
                <PlateEditor onExport={handleDescription} />
              </div>

              <div className="form-control" style={{ marginTop: 20 }}>
                <label className="col-sm-3 col-form-label form-label-title">
                  Thumb Images
                </label>
                <input
                  className="form-control form-choose text-black"
                  type="file"
                  accept="images/*"
                  onChange={handleImagesChange}
                  ref={imagesInputRef}
                />
                <div className="form-group col-sm-9">
                  {images && openCreateBlogModal && (
                    <div>
                      <button
                        className="relative top-1 w-6 h-6 align-middle shadow-md justify-center text-center rounded-full bg-white text-red-500"
                        onClick={() => handleImagesClose()}
                      >
                        x
                      </button>
                      <img
                        src={URL.createObjectURL(images)}
                        alt={"category-img"}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          margin: "5px",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        {/* Add Blog end */}

        {/* edit Blog start */}
        <Dialog
          open={openEditBlogModal}
          handler={handleEditBlogModalOpen}
          header={
            <div className="flex flex-row flex-1 justify-between">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Your Blog
              </h5>
              <button
                type="button"
                className="w-8 h-8 rounded-full text-gray-500 hover:bg-gray-200 hover:text-gray-800 flex items-center justify-center"
                onClick={handleEditBlogClose}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
          }
          footer={
            <div className="flex flex-row">
              <button
                type="button"
                className="btn theme-bg-color btn-md text-white mx-1 bg-custom-secondary-btn"
                onClick={handleEditBlogClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn theme-bg-color btn-md text-white mx-1 bg-custom-primary-btn"
                onClick={handleEditBlogSave}
              >
                Save changes
              </button>
            </div>
          }
        >
          <div className="modal-body max-h-[550px] overflow-y-auto">
            <div className="modal-body">
              <div className="form-floating mb-4 theme-form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="blogTitle"
                  placeholder="Enter First Name"
                  value={title}
                  onChange={(e) => setTitle(e.target?.value)}
                />
                <label htmlFor="blogTitle">Title</label>
              </div>

              <div className="form-floating mb-4 theme-form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="blogTitle"
                  placeholder="Enter First Name"
                  value={auther}
                  onChange={(e) => setAuther(e.target.value)}
                />
                <label htmlFor="blogTitle">Auther Name</label>
              </div>

              <div className="form-floating mb-4 theme-form-floating">
                <textarea
                  className="form-control"
                  placeholder="Short description about your blog"
                  id="shortDescription"
                  style={{ height: 100 }}
                  value={shortDescription}
                  onChange={(e) => setShortDescription(e.target?.value)}
                />
                <label htmlFor="address">Short Description</label>
              </div>

              <div className="form-control">
                {description && (
                  <PlateEditor
                    onExport={handleDescription}
                    initialValue={description}
                  />
                )}
                <label htmlFor="address">Description</label>
              </div>

              <div className="form-control" style={{ marginTop: 20 }}>
                <label className="col-sm-3 col-form-label form-label-title">
                  ThumbImages
                </label>
                <input
                  className="form-control form-choose text-black"
                  type="file"
                  accept="images/*"
                  onChange={handleImagesChange}
                  ref={imagesInputRef}
                />
                <div className="form-group col-sm-9">
                  {newImages ? (
                    <div>
                      <button
                        className="relative top-1 w-6 h-6 align-middle shadow-md justify-center text-center rounded-full bg-white text-red-500"
                        onClick={() => handleImagesClose()}
                      >
                        x
                      </button>
                      <img
                        src={URL.createObjectURL(newImages)}
                        alt={"blog-img"}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          margin: "5px",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                  ) : images ? (
                    <div>
                      <button
                        className="relative top-1 w-6 h-6 align-middle shadow-md justify-center text-center rounded-full bg-white text-red-500"
                        onClick={() => handleImagesClose()}
                      >
                        x
                      </button>
                      <img
                        src={images?.location}
                        alt={"blog-img"}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          margin: "5px",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              {/* TODO: add editor here  */}
            </div>
          </div>
        </Dialog>
        {/* edit Blog end */}

        {/* delete Blog start */}
        <Dialog
          open={openDeleteBlogModal}
          handler={handleDeleteBlogModalOpen}
          header={
            <div className="modal-header flex flex-1 justify-between">
              <h5 className="" id="exampleModalLabel">
                Delete Your Blog
              </h5>
              <button type="button" onClick={handleDeleteBlogClose}>
                <i className="fa-solid fa-xmark" />
              </button>
            </div>
          }
          footer={
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-md"
                onClick={handleDeleteBlogClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn theme-bg-color btn-md text-white"
                onClick={handleDeleteBlogConfirm}
              >
                Yes, delete this blog
              </button>
            </div>
          }
        >
          <p style={{ fontSize: 16, fontWeight: 600, marginLeft: 10 }}>
            Do you want to delete {deleteItem && deleteItem?.title.substring(0, 25)}?{" "}
          </p>
        </Dialog>
        {/* delete Blog end */}

        {/* Read Blogs start */}
        <Dialog
          open={openViewBlogModal}
          handler={handleViewBlogModalOpen}
          header={
            <div className="modal-header flex flex-1 justify-between">
              <h5 style={{ color: "#000" }} id="exampleModalLabel">
                Blog Details
              </h5>
              <button
                type="button"
                onClick={handleViewBlogClose}
                style={{ color: "#000" }}
              >
                <i className="fa-solid fa-xmark" />
              </button>
            </div>
          }
        >
          <div className="modal-body">
            {selectedViewBlog && (
              <ReadyOnlyPlateEditor value={selectedViewBlog} />
            )}
          </div>
        </Dialog>
      </>
    </>
  );
};

export default Blogs;
