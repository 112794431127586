import React, { useState, useEffect } from "react";
import WebLayout from "layout/WebLayout";

import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  addItem,
  removeItem,
  updateQuantity,
} from "lib/redux/reducers/customer/cart";

import {
  createAddress,
  getAddressByCustomer,
  addressDelete,
  addressUpdate,
} from "api/address";

import { Accordion } from "components/Accordion";
import Payment from "components/Payment";
import { useNavigate } from "react-router-dom";

import {
  c_updateCheckout,
  c_updateCheckoutClientSecret,
  c_updateCheckoutPaymentIntent,
} from "lib/redux/reducers/customer/checkoutSlice";

import { createCheckoutOrder, createCheckoutToken } from "api/payment";
import { Spinner } from "@material-tailwind/react";

import { getCustomerData, customerUpdate } from "api/customer";

import Snackbar from "components/Snackbar";
import { getCouponDataByCouponCode } from "api/coupon";

type Props = {};

const Checkout = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cartState = useAppSelector((state) => state.cartReducer);
  const customer = useAppSelector((state) => state.customerReducer?.c_user);

  const [couponCode, setCouponCode] = useState<any>();
  const [isValidCoupon, setIsValidCoupon] = useState<boolean>(false);
  const [couponData, setCouponData] = useState<any>();

  const handleApplyCoupon = (couponCodeText: string) => {
    getCouponDataByCouponCode(couponCodeText)
      .then((res) => {
        console.log("coupon data:", res);
        const couponResponse = res as { result: any[] }; // Type assertion
        console.log("coupon data...:", couponResponse.result);
        if (
          couponResponse &&
          couponResponse.result &&
          couponResponse.result[0] &&
          couponResponse.result[0].status
        ) {
          setIsValidCoupon(true);
          setCouponData(couponResponse.result[0]);
        }
      })
      .catch((error) => {
        console.log("failed to get coupon data : ", error);
      });
  };

  const [errorMessage, setErrorMessage] = useState<any>();

  useEffect(() => {
    if (couponData) {
      const today = new Date().toISOString();
      const expireDate = new Date(couponData?.validityEndDate);
      const currentDate = new Date(today);
      let comparisonResult = "";
      if (expireDate > currentDate) {
        console.log("Coupon Expired : ");
        setErrorMessage("Coupon Validity Expired");
      }
    }

    return () => {};
  }, [couponData]);

  const [addressList, setAddressList] = useState<any>();
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [discountCoupon, setDiscountCoupon] = useState("test 1");
  const [isShippingCost, setIsShoppingCost] = useState(true);

  const [paymentMode, setPaymentMode] = useState<string>("card");
  const [enablePlaceOrder, setEnablePlaceOrder] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [clientSecret, setClientSecret] = useState("");

  const [name, setName] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [phone, setPhone] = useState<any>();
  const [pincode, setPincode] = useState<any>();
  const [addressType, setAddressType] = useState<any>();

  //snackbar
  const [openSnackbar, setOpenSnackbar] = useState<any>();
  const [type, setType] = useState<any>();
  const [heading, setHeading] = useState<any>();
  const [message, setMessage] = useState<any>();

  const hideSnackbar = () => {
    setTimeout(() => {
      setOpenSnackbar(false);
      setType("");
      setHeading("");
      setMessage("");
    }, 3000);
  };

  const handleSnackbar = (openSnackbar, type, heading, message) => {
    setOpenSnackbar(openSnackbar);
    setType(type);
    setMessage(message);
    setHeading(heading);
    hideSnackbar();
  };

  const fetchCustomerAddress = () => {
    getAddressByCustomer(customer?._id)
      .then((res) => {
        setAddressList(res?.result);
        const customerAddress = res?.result;
        console.log("customer address : ", res?.result);
        if (customerAddress) {
          getCustomerData(customer?._id)
            .then((res: any) => {
              const _defaultAddress = customerAddress.filter(
                (item: any) => item?._id === res?.result?.defaultDeliveryAddress
              );
              if (_defaultAddress && _defaultAddress.length > 0)
                console.log("default addtress : ", _defaultAddress[0]);
              setSelectedAddress(_defaultAddress[0]);
            })
            .catch((error) => {
              console.log("failed to fetch customer datails : ", error);
            });
        }
      })
      .catch((error) => {
        console.log("Failed to get address Result : ", error);
      });
  };

  useEffect(() => {
    if (customer && customer?._id) {
      fetchCustomerAddress();
    }

    return () => {};
  }, [customer]);

  const handleAddressChange = (item) => {
    setSelectedAddress(item);
  };

  useEffect(() => {
    if (
      paymentMode &&
      (selectedAddress ||
        (name && email && addressType && address && phone && pincode))
    ) {
      setEnablePlaceOrder(true);
    } else {
      setEnablePlaceOrder(false);
    }

    return () => {};
  }, [
    paymentMode,
    selectedAddress,
    name,
    email,
    addressType,
    address,
    phone,
    pincode,
  ]);

  const [total, setTotal] = useState<number>(0);
  const [grandTotal, setGrandTotal] = useState<number>(0);

  const discount = 20.0;
  const shippingCost = 6.9;
  const tax = 29.498;

  useEffect(() => {
    if (cartState.items && cartState.items.length) {
      let totalPrice = 0;
      cartState.items.map((item) => {
        totalPrice += parseFloat(item.product.price) * item.quantity;
      });

      setGrandTotal(Math.round(totalPrice - discount + shippingCost + tax));

      setTotal(totalPrice);
    }

    return () => {};
  }, [cartState?.items]);

  // const handlePlaceOrder = async () => {
  //   const newAddress = {
  //     customerId: customer?._id,
  //     userName: name,
  //     addressName: addressType,
  //     userEmail: email,
  //     userAddress: address,
  //     pincode,
  //     phone,
  //   };
  //   // if (newAddress) {
  //   //   const result = await createAddress(newAddress);
  //   // }

  //   if (!selectedAddress && !newAddress) {
  //     handleSnackbar(
  //       true,
  //       "error",
  //       "Error !",
  //       "Please Choose Delivery Address."
  //     );
  //     return false;
  //   }

  //   setIsSubmitting(true);
  //   if (paymentMode === "card") {
  //     const data = {
  //       items: cartState.items,
  //       discount_coupon: discountCoupon,
  //       isShippingCost: isShippingCost,
  //       delivery_address: selectedAddress || newAddress,
  //       total,
  //       grandTotal,
  //       discount,
  //       shippingCost,
  //       tax,
  //       paymentMode,
  //     };

  //     dispatch(c_updateCheckout(data));

  //     createCheckoutToken({
  //       items: cartState.items,
  //       receipt_email: customer?.email,
  //     })
  //       .then((response: any) => {
  //         setIsSubmitting(false);
  //         // setIsLoading(false);
  //         setClientSecret(response.clientSecret);
  //         dispatch(c_updateCheckoutPaymentIntent(response?.paymentIntentId));
  //         dispatch(c_updateCheckoutClientSecret(response?.clientSecret));
  //         navigate("/payment", {
  //           state: { clientSecret: response.clientSecret },
  //           replace: true,
  //         });
  //       })
  //       .catch((error) => {
  //         setIsSubmitting(false);
  //         // setIsLoading(false);
  //         // setErrorMsg("Failed to initiate payment, please try again");
  //         console.log("Failed to payment token, Error:", error?.response);
  //       });
  //   } else {
  //     // TODO : handle COD
  //   }
  // };

  const handleStripePlaceOrder = async () => {
    if (name && email && addressType && address && phone && pincode) {
      const _address = {
        customerId: customer?._id,
        userName: name,
        addressName: addressType,
        userEmail: email,
        userAddress: address,
        pincode,
        phone,
      };
      const result = await createAddress(_address);
      console.log("result address : ", result);
      const newAddress = result?.result;
      console.log("newAddress address.... : ", newAddress);
      if (newAddress) {
        setIsSubmitting(true);
        if (paymentMode === "card") {
          const data = {
            items: cartState.items,
            discount_coupon: discountCoupon,
            isShippingCost: isShippingCost,
            delivery_address: newAddress,
            total,
            grandTotal,
            discount,
            shippingCost,
            tax,
            couponCode,
            paymentMode,
          };
          dispatch(c_updateCheckout(data));
          createCheckoutToken({
            items: cartState.items,
            receipt_email: customer?.email,
          })
            .then((response: any) => {
              setIsSubmitting(false);
              setClientSecret(response.clientSecret);
              dispatch(
                c_updateCheckoutPaymentIntent(response?.paymentIntentId)
              );
              dispatch(c_updateCheckoutClientSecret(response?.clientSecret));
              navigate("/payment", {
                state: { clientSecret: response.clientSecret },
                replace: true,
              });
            })
            .catch((error) => {
              setIsSubmitting(false);
              console.log("Failed to payment token, Error:", error?.response);
            });
        } else {
          // TODO : handle COD
        }
      }
    } else {
      setIsSubmitting(true);
      if (paymentMode === "card") {
        const data = {
          items: cartState.items,
          discount_coupon: discountCoupon,
          isShippingCost: isShippingCost,
          delivery_address: selectedAddress,
          total,
          grandTotal,
          discount,
          shippingCost,
          tax,
          couponCode,
          paymentMode,
        };
        dispatch(c_updateCheckout(data));
        createCheckoutToken({
          items: cartState.items,
          receipt_email: customer?.email,
        })
          .then((response: any) => {
            setIsSubmitting(false);
            setClientSecret(response.clientSecret);
            dispatch(c_updateCheckoutPaymentIntent(response?.paymentIntentId));
            dispatch(c_updateCheckoutClientSecret(response?.clientSecret));
            navigate("/payment", {
              state: { clientSecret: response.clientSecret },
              replace: true,
            });
          })
          .catch((error) => {
            setIsSubmitting(false);
            console.log("Failed to payment token, Error:", error?.response);
          });
      } else {
        // TODO : handle COD
      }
    }
  };

  const handlePlaceOrder = async () => {
    if (!name || !email || !addressType || !address || !phone || !pincode) {
      handlePaymentProcess(selectedAddress);
      return;
    }

    const _address = {
      customerId: customer?._id,
      userName: name,
      addressName: addressType,
      userEmail: email,
      userAddress: address,
      pincode,
      phone,
    };

    try {
      const result = await createAddress(_address);
      const newAddress = result?.result;
      if (newAddress) {
        handlePaymentProcess(newAddress);
      }
    } catch (error) {
      console.log("Failed to create address, Error:", error?.response);
    }
  };

  const handlePaymentProcess = (deliveryAddress: any) => {
    setIsSubmitting(true);

    if (paymentMode === "card") {
      const data = {
        items: cartState.items,
        discount_coupon: discountCoupon,
        customer: customer?._id,
        isShippingCost: isShippingCost,
        delivery_address: deliveryAddress,
        total,
        grandTotal,
        discount,
        shippingCost,
        tax,
        paymentMode,
      };

      dispatch(c_updateCheckout(data));

      // createCheckoutOrder({
      //   paymentIntentId,
      //   items,
      //   delivery_address,
      //   discount_coupon,
      //   paymentMode,
      //   shipping: isShippingCost,
      //   customer: customer?._id,
      //   status: "processing",
      // });

      createCheckoutOrder(data)
        .then((response: any) => {
          setIsSubmitting(false);
          window.location.replace(response?.redirect);
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.log("Failed to payment token, Error:", error?.response);
        });
    } else {
      // TODO: handle COD
    }
  };

  const [enableAddress, setEnableAddress] = useState<Boolean>();

  const handleAddress = () => {
    setEnableAddress(!enableAddress);
  };

  return (
    <WebLayout>
      <>
        {openSnackbar && (
          <Snackbar type={type} heading={heading} message={message} />
        )}

        {/* <!-- Checkout section Start --> */}
        <section className="checkout-section-2  section-b-space pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
          <div className="container-fluid-lg">
            <div className="row g-sm-4 g-3">
              <div className="col-lg-8">
                <div className="left-sidebar-checkout">
                  <div className="checkout-detail-box">
                    <ul>
                      <li>
                        <div className="checkout-icon">
                          {/* <lord-icon target=".nav-item" src="https://cdn.lordicon.com/ggihhudh.json"
                                            trigger="loop-on-hover"
                                            colors="primary:#121331,secondary:#646e78,tertiary:#0baf9a"
                                            className="lord-icon">
                                        </lord-icon> */}
                        </div>
                        <div className="checkout-box">
                          <div className="checkout-title d-flex justify-content-between align-items-center">
                            <h4>Delivery Address</h4>
                            <button
                              className="btn theme-bg-color btn-md  text-white mr-2"
                              onClick={handleAddress}
                            >
                              Add New Address
                            </button>{" "}
                          </div>
                          {enableAddress && (
                            <div className="modal-body max-h-[450px] overflow-y-auto">
                              <form>
                                <div className="form-floating mb-4 theme-form-floating">
                                  <input
                                    type="text"
                                    className="form-control text-content"
                                    id="fname"
                                    placeholder="Enter First Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                  <label htmlFor="fname">Name</label>
                                </div>
                              </form>

                              <form>
                                <div className="form-floating mb-4 theme-form-floating">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter Email Address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                  <label htmlFor="email">Email Address</label>
                                </div>
                              </form>
                              <form>
                                <div className="form-floating mb-4 theme-form-floating">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="lname"
                                    placeholder="Enter Phone Number"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                  <label htmlFor="lname">Phone Number</label>
                                </div>
                              </form>
                              <form>
                                <div className="form-floating mb-4 theme-form-floating">
                                  <input
                                    type="test"
                                    className="form-control"
                                    id="pin"
                                    placeholder="Enter address type"
                                    value={addressType}
                                    onChange={(e) =>
                                      setAddressType(e.target.value)
                                    }
                                  />
                                  <label htmlFor="pin">Address Type</label>
                                </div>
                              </form>
                              <form>
                                <div className="form-floating mb-4 theme-form-floating">
                                  <textarea
                                    className="form-control"
                                    placeholder="Leave a comment here"
                                    id="address"
                                    style={{ height: 100 }}
                                    defaultValue={""}
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                  />
                                  <label htmlFor="address">Enter Address</label>
                                </div>
                              </form>
                              <form>
                                <div className="form-floating mb-4 theme-form-floating">
                                  <input
                                    type="test"
                                    className="form-control"
                                    id="pin"
                                    placeholder="Enter address type"
                                    value={pincode}
                                    onChange={(e) => setPincode(e.target.value)}
                                  />
                                  <label htmlFor="pin">Pin Code</label>
                                </div>
                              </form>
                            </div>
                          )}
                          <div className="checkout-detail">
                            <div className="row g-4">
                              {addressList &&
                                addressList.map((item: any) => (
                                  <div
                                    className="col-xxl-6 col-lg-12 col-md-6"
                                    key={item._id}
                                  >
                                    <div className="delivery-address-box">
                                      <div>
                                        <div className="form-check">
                                          {/* <input
                                            className="form-check-input"
                                            type="radio"
                                            name="jack"
                                            id="flexRadioDefault1"
                                          /> */}

                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="jack"
                                            id={`radio-${item._id}`} // Unique id for each radio button
                                            checked={
                                              selectedAddress?._id === item?._id
                                            } // Check if ids match
                                            onChange={() =>
                                              handleAddressChange(item)
                                            } // Handle change
                                          />
                                        </div>

                                        <div className="label">
                                          <label>{item?.addressName}</label>
                                        </div>

                                        <ul className="delivery-address-detail">
                                          <li>
                                            <h4 className="fw-500">
                                              {item?.userName}
                                            </h4>
                                          </li>

                                          <li>
                                            <p className="text-content">
                                              <span className="text-title">
                                                Address :{" "}
                                              </span>
                                              {item?.userAddress}
                                            </p>
                                          </li>

                                          <li>
                                            <h6 className="text-content">
                                              <span className="text-title">
                                                Pin Code :
                                              </span>{" "}
                                              {item?.pincode}
                                            </h6>
                                          </li>

                                          <li>
                                            <h6 className="text-content mb-0">
                                              <span className="text-title">
                                                Phone :
                                              </span>{" "}
                                              {item?.phone}
                                            </h6>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="checkout-icon">
                          {/* <lord-icon
                            target=".nav-item"
                            src="https://cdn.lordicon.com/qmcsqnle.json"
                            trigger="loop-on-hover"
                            colors="primary:#0baf9a,secondary:#0baf9a"
                            className="lord-icon"
                          ></lord-icon> */}
                        </div>
                        <div className="checkout-box">
                          <div className="checkout-title">
                            <h4>Payment Option</h4>
                          </div>

                          <div className="checkout-detail flex flex-col">
                            <div className="custom-form-check form-check my-2">
                              <label
                                className="form-check-label font-semibold text-lg font-poppins"
                                //for="cash"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="cash"
                                  checked={paymentMode === "cod"}
                                  onChange={() => setPaymentMode("cod")}
                                />{" "}
                                Cash On Delivery
                              </label>
                            </div>

                            <div className="custom-form-check form-check my-2 mt-4">
                              <label
                                className="form-check-label font-semibold text-lg font-poppins"
                                htmlFor="card"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="credit"
                                  checked={paymentMode === "card"}
                                  onChange={() => setPaymentMode("card")}
                                />
                                Credit or Debit Card
                              </label>
                            </div>

                            {/* <div
                              className="accordion accordion-flush custom-accordion"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item">
                                <div
                                  className="accordion-header"
                                  id="flush-headingFour"
                                >
                                  <div
                                    className="accordion-button collapsed"
                                    // data-bs-toggle="collapse"
                                    // data-bs-target="#flush-collapseFour"
                                  ></div>
                                </div>
                                <div
                                  id="flush-collapseFour"
                                  className="accordion-collapse collapse show"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <p className="cod-review">
                                      Pay digitally with SMS Pay Link. Cash may
                                      not be accepted in COVID restricted areas.{" "}
                                      <a href="javascript:void(0)">
                                        Know more.
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>

                            

                              <div className="accordion-item">
                                <div
                                  className="accordion-header"
                                  id="flush-headingOne"
                                >
                                  <div
                                    className="accordion-button collapsed"
                                    // data-bs-toggle="collapse"
                                    // data-bs-target="#flush-collapseOne"
                                  ></div>
                                </div>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div className="row g-2">
                                      <div className="col-12">
                                        <div className="payment-method">
                                          <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="credit2"
                                              placeholder="Enter Credit & Debit Card Number"
                                            />
                                            <label>
                                              Enter Credit & Debit Card Number
                                            </label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-xxl-4">
                                        <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="expiry"
                                            placeholder="Enter Expiry Date"
                                          />
                                          <label>Expiry Date</label>
                                        </div>
                                      </div>

                                      <div className="col-xxl-4">
                                        <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="cvv"
                                            placeholder="Enter CVV Number"
                                          />
                                          <label>CVV Number</label>
                                        </div>
                                      </div>

                                      <div className="col-xxl-4">
                                        <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                          <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            placeholder="Enter Password"
                                          />
                                          <label>Password</label>
                                        </div>
                                      </div>

                                      <div className="button-group mt-0">
                                        <ul>
                                          <li>
                                            <button className="btn btn-light shopping-button">
                                              Cancel
                                            </button>
                                          </li>

                                          <li>
                                            <button className="btn btn-animation">
                                              Use This Card
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="right-side-summery-box">
                  <div className="summery-box-2">
                    <div className="summery-header">
                      <h3>Order Summery</h3>
                    </div>

                    <ul className="summery-contain">
                      {cartState?.items &&
                        cartState?.items.map((item: any) => (
                          <li key={item?.product?._id}>
                            <img
                              src={item?.product?.thumbImages?.location}
                              className="img-fluid blur-up lazyloaded checkout-image"
                              alt=""
                            />
                            <h4>
                              {item?.product?.name}{" "}
                              <span>X {item?.quantity}</span>
                            </h4>
                            <h4 className="price">
                              AED{" "}
                              {parseFloat(item?.product?.price) *
                                item?.quantity}
                            </h4>
                          </li>
                        ))}
                    </ul>

                    <ul className="summery-total">
                      <li>
                        <h4>Subtotal</h4>
                        <h4 className="price">AED {total.toFixed(2)}</h4>
                      </li>
                      <li>
                        <h4>Shipping</h4>
                        <h4 className="price">AED {shippingCost}</h4>
                      </li>
                      <li>
                        <h4>Tax</h4>
                        <h4 className="price">AED {tax}</h4>
                      </li>{" "}
                      <div className="flex flex-col">
                        <div className="coupon-cart mt-5">
                          <h4
                            className="price"
                            style={{ fontSize: 17, color: "#222" }}
                          >
                            Coupon Apply
                          </h4>
                          <div
                            className="mb-3 coupon-box input-group"
                            style={{ marginTop: 10 }}
                          >
                            <input
                              type="text"
                              className="form-control !border-0"
                              id="exampleFormControlInput1"
                              placeholder="Enter Coupon Code Here..."
                              value={couponCode}
                              onChange={(e) => setCouponCode(e.target.value)}
                              onBlur={(e) =>
                                handleApplyCoupon(e.currentTarget.value)
                              }
                            />
                            <button
                              className="btn-apply"
                              onClick={() => handleApplyCoupon(couponCode)}
                              style={{
                                background: "var(--theme-color)", // CSS variables work as strings
                                color: "#fff",
                                paddingLeft: 25,
                                paddingRight: 25,
                                fontWeight: 700, // Use camelCase for font-weight
                                border: "none", // Border property
                                borderTopRightRadius: "4px", // Add border radius to top-right corner
                                borderBottomRightRadius: "4px", // Add border radius to bottom-right corner
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                        {isValidCoupon ? (
                          <div className="flex flex-row text-primary">
                            👏👏 Coupon&nbsp;
                            <span className="font-bold">{couponCode}</span>
                            &nbsp; Applied !!
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {errorMessage && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Error : {errorMessage}
                        </div>
                      )}
                      <li></li>
                      {/* <li>
                        <h4>Coupon/Code</h4>
                        <h4 className="price">AED -{discount}</h4>
                      </li> */}
                      <li className="list-total">
                        <h4>Total (AED)</h4>
                        <h4 className="price">AED {grandTotal.toFixed(2)}</h4>
                      </li>
                    </ul>
                  </div>

                  <div className="checkout-offer">
                    <div className="offer-title">
                      <div className="offer-icon">
                        <img
                          src="/assets/images/inner-page/offer.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="offer-name">
                        <h6>Available Offers</h6>
                      </div>
                    </div>

                    <ul className="offer-detail">
                      <li>
                        <p>
                          Combo: BB Royal Almond/Badam Californian, Extra Bold
                          100 gm...
                        </p>
                      </li>
                      <li>
                        <p>
                          combo: Royal Cashew Californian, Extra Bold 100 gm +
                          BB Royal Honey 500 gm
                        </p>
                      </li>
                    </ul>
                  </div>

                  <button
                    className={`btn ${
                      enablePlaceOrder
                        ? "theme-bg-color text-white"
                        : "text-black/[0.5]"
                    } btn-md w-100 mt-4 fw-bold`}
                    disabled={!enablePlaceOrder}
                    onClick={handlePlaceOrder}
                  >
                    {isSubmitting ? (
                      <>
                        {/* @ts-ignore */}
                        <Spinner color="white" />
                      </>
                    ) : (
                      "Place Order"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Checkout section End --> */}
      </>
    </WebLayout>
  );
};

export default Checkout;
