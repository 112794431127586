import React, { useState } from "react";
import AuthLayout from "../../layout/AuthLayout";
import { useFormik } from "formik";
import * as Yup from "yup";

import { userLogin } from "../../api/auth";

import { useNavigate } from "react-router-dom";
import { Spinner } from "@material-tailwind/react";
import { useAppDispatch } from "../../lib/redux/hooks";

import Snackbar from "components/Snackbar";

type Props = {};

const Login = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      setErrorMsg("");
      if (values?.email && values.password) {
        userLogin(values)
          .then((res: any) => {
            setIsSubmitting(false);
            handleSnackbar(
              true,
              "success",
              "Success !",
              "Yo are logged in successfully"
            );
            // TODO : handle login navigation
          })
          .catch((error) => {
            setIsSubmitting(false);
            setErrorMsg("Failed to login, please try again");
            console.log("failed to login: ", error);
          });
      } else {
        setIsSubmitting(false);
        setErrorMsg("Missing input, please try again");
      }
    },
  });

  const handlePasswordToggle = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  //snackbar
  const [openSnackbar, setOpenSnackbar] = useState<any>();
  const [type, setType] = useState<any>();
  const [heading, setHeading] = useState<any>();
  const [message, setMessage] = useState<any>();

  const hideSnackbar = () => {
    setTimeout(() => {
      setOpenSnackbar(false);
      setType("");
      setHeading("");
      setMessage("");
    }, 3000);
  };

  const handleSnackbar = (openSnackbar, type, heading, message) => {
    setOpenSnackbar(openSnackbar);
    setType(type);
    setMessage(message);
    setHeading(heading);
    hideSnackbar();
  };

  return (
    <AuthLayout>
      {openSnackbar && (
        <Snackbar type={type} heading={heading} message={message} />
      )}

      <>
        <section className="breadcrumb-section pt-0">
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-contain">
                  <h2 className="mb-2">Log In</h2>
                  <nav>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <a href="/">
                          <i className="fa-solid fa-house"></i>
                        </a>
                      </li>
                      <li className="breadcrumb-item active">Log In</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="log-in-section background-image-2 section-b-space">
          <div className="container-fluid-lg w-100">
            <div className="row">
              <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                <div className="image-contain">
                  <img
                    src="/assets/images/logo/login.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                <div className="log-in-box">
                  <div className="log-in-title">
                    <h3>Welcome To Hidden Health</h3>
                    <h4>Log In Your Account</h4>
                  </div>

                  <div className="input-box">
                    <form className="row g-4" onSubmit={formik.handleSubmit}>
                      <div className="col-12">
                        <div className="form-floating theme-form-floating log-in-form">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Email Address"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={email}
                          />

                          <label htmlFor="email">Email Address</label>
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                          <div className="error-msg">{formik.errors.email}</div>
                        ) : null}
                      </div>

                      <div className="col-12">
                        <div className="form-floating theme-form-floating log-in-form">
                          <input
                            type={isPasswordVisible ? "text" : "password"}
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={password}
                          />

                          <label htmlFor="password">Password</label>
                          <div
                            className="h-8 w-8 flex flex-1 align-middle justify-center text-gray-700 text-center bg-transparent rounded-full"
                            onClick={() =>
                              setIsPasswordVisible(!isPasswordVisible)
                            }
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                          >
                            <i
                              className={
                                isPasswordVisible
                                  ? "fa-solid fa-eye-slash"
                                  : "fa-solid fa-eye"
                              }
                              style={{ marginTop: "2.6px" }}
                            ></i>
                          </div>
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <div className="error-msg" style={{ color: "red" }}>
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-12">
                        <div className="forgot-box">
                          <div className="form-check ps-0 m-0 remember-box">
                            <input
                              className="checkbox_animated check-box"
                              type="checkbox"
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Remember me
                            </label>
                          </div>
                          <a
                            href="/forgot-password"
                            className="forgot-password"
                          >
                            Forgot Password?
                          </a>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="error-msg" style={{ color: "red" }}>
                          {errorMsg}
                        </div>
                        <button
                          className="btn btn-animation w-100 justify-content-center"
                          type="submit"
                        >
                          {isSubmitting ? (
                            <>
                              {/* @ts-ignore */}
                              <Spinner color="green" />
                            </>
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="other-log-in">
                    <h6></h6>
                  </div>

                  <div className="sign-up-box">
                    <h4>Don't have an account?</h4>
                    <a href="/sign-up">Sign Up</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </AuthLayout>
  );
};

export default Login;
