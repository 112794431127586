import PaymentLayout from "layout/PaymentLayout";
import React from "react";

import Lottie from "lottie-react";

import PaymentFailedAnimation from "./lottie/PaymentFailed.json";
import { useLocation } from "react-router-dom";

type Props = {};

const PaymentFailed = (props: Props) => {
  const location = useLocation();

  return (
    <div className="flex flex-1 flex-col items-center">
      <Lottie
        animationData={PaymentFailedAnimation}
        loop={false}
        style={{ width: 250, height: 250 }}
        onError={(error: any) => console.log("Lottie error: ", error)}
      />

      <span className="text-2xl font-extrabold my-2 text-black">
        Payment Failed
      </span>
      {location?.state?.message && (
        <p className="text-md text-black p-2">{location?.state?.message}</p>
      )}

      <div className="my-2">
        <a
          href="/checkout"
          className="flex flex-row justify-center rounded-sm px-8 py-2 my-4 bg-blue-600 text-white font-bold text-lg shadow-sm hover:shadow-md"
        >
          <i data-feather="credit-card" className="mx-2 text-white"></i>
          Try again
        </a>

        <p className="text-gray-300 text-md text-center">
          ---------------- or ----------------
        </p>

        <a
          href="/shop"
          className="flex flex-row justify-center rounded-sm text-black px-8 py-2 my-4 text-green font-bold text-lg shadow-sm hover:shadow-md"
        >
          <i data-feather="shopping-cart" className="mx-2 text-black"></i>
          Continue Shopping
        </a>
      </div>
    </div>
  );
};

export default PaymentFailed;
