import React from "react";
import WebLayout from "../../layout/WebLayout";

type Props = {};

const OrderTracking = (props: Props) => {
  return (
    <WebLayout>
      <>
        {/* <!-- Cart Section Start --> */}
        <section className="cart-section  section-b-space pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
          <div className="container-fluid-lg">
            <div className="row g-sm-5 g-3">
              <div className="col-xxl-3">
                <div className="summery-box p-sticky">
                  <div className="summery-header">
                    <h3>Enter Order Details</h3>
                  </div>

                  <div className="summery-contain">
                    <form action="order-tracking-inner.html">
                      <div className="coupon-cart">
                        <h6 className="text-content mb-2">E-mail</h6>
                        <div className="mb-3 coupon-box input-group">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter e-mail id Here..."
                          />
                        </div>
                      </div>
                      <div className="coupon-cart">
                        <h6 className="text-content mb-2">Tracking Id</h6>
                        <div className="mb-3 coupon-box input-group">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter tracking id Here..."
                          />
                        </div>
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        <ul>
                          <li>
                            <input
                              type="submit"
                              value="Track Order"
                              className="btn btn-animation proceed-btn fw-bold"
                            />
                          </li>
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Cart Section End --> */}
      </>
    </WebLayout>
  );
};

export default OrderTracking;
