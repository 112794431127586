/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import CookiesBar from "./CookiesBar";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";

import CartMenu from "./CartMenu";
import Footer from "./Footer";
import { c_resetUser } from "../../lib/redux/reducers/customer/customerSlice";

type Props = {
  children: React.ReactNode;
};

const WebLayout = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const cartState = useAppSelector((state) => state.cartReducer);
  const customer = useAppSelector((state) => state.customerReducer.c_user);

  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    const openBtn = document.getElementById("navbar-toggler");
    const closeBtn = document.getElementById("nav-close-btn");
    const nav = document.getElementById("mobile-nav-modal");

    const openModal = () => {
      if (nav) {
        nav.classList.remove("-translate-x-full");
      }
    };

    const closeModal = () => {
      if (nav) {
        nav.classList.add("-translate-x-full");
      }
    };

    if (openBtn) {
      openBtn.addEventListener("click", openModal);
    }

    if (closeBtn) {
      closeBtn.addEventListener("click", closeModal);
    }

    // Cleanup event listeners on unmount
    return () => {
      if (openBtn) {
        openBtn.removeEventListener("click", openModal);
      }
      if (closeBtn) {
        closeBtn.removeEventListener("click", closeModal);
      }
    };
  }, []);

  useEffect(() => {
    if (cartState?.items && cartState?.items.length) {
      let quantity = 0;
      // cartState?.items.map((item) => {
      //   quantity += item.quantity;
      // });
      if (cartState?.items?.length) {
        setTotalQuantity(cartState?.items?.length);
      }
    } else {
      setTotalQuantity(0);
    }

    return () => {};
  }, [cartState?.items]);

  const handleLogout = () => {
    dispatch(c_resetUser());
  };

  return (
    <>
      <>
        {/* Header Start */}
        <header className="header-2 absolute top-0 left-0 z-[2] w-full">
          {/* <div className="bg-white text-[#F15624] overflow-hidden py-2 w-full marquee-container">
            <div className="marquee-content">
              <div className="w-full flex items-center gap-[15vw] whitespace-nowrap">
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
                <div className="w-full">
                  <h6 className="text-[#F15624] poppins-regular w-full flex !text-[3.25vw] md:!text-[1.1vw] items-center gap-[1vw] poppins-regular">
                    Enjoy 50% off the first four weeks of a new subscription{" "}
                    <button
                      onClick={() => (window.location.href = "/shop")}
                      className="btn !text-[3vw] md:!text-[.95vw] !inline-block btn-2 !py-[.35vw] !px-[2vw] md:!px-[1.25vw] !rounded-[.65vw] ml-4 bg-[#0FB16E] text-white"
                    >
                      <strong>Redeem Now</strong>
                    </button>
                  </h6>
                </div>
              </div>
            </div>
          </div> */}
          <div className="top-nav top-header sticky-header sticky-header-3 py-0 border-0">
            <div className="container-fluid-lg">
              <div className="row">
                <div className="col-12">
                  <div className="navbar-top">
                    <a href="/" className="web-logo nav-logo">
                      <img
                        src="/assets/images/home-new/logo.png"
                        className="md:!w-[200px]"
                        alt=""
                      />
                    </a>
                    <div className="middle-box">
                      <div className="center-box">
                        <div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
                          <div
                            className="offcanvas offcanvas-collapse order-xl-2"
                            id="primaryMenu"
                          >
                            <div className="offcanvas-header navbar-shadow">
                              <h5>Menu</h5>
                              <button
                                className="btn-close lead"
                                type="button"
                                data-bs-dismiss="offcanvas"
                              />
                            </div>
                            <div className="offcanvas-body">
                              <ul className="navbar-nav">
                                <li className="nav-item dropdown">
                                  <a
                                    className="nav-link dropdown-toggle poppins-regular"
                                    href="/"
                                  >
                                    Home
                                  </a>
                                </li>
                                <li className="nav-item dropdown">
                                  <a
                                    className="nav-link dropdown-toggle poppins-regular"
                                    href="/shop"
                                  >
                                    Shop
                                  </a>
                                </li>
                                <li className="nav-item dropdown">
                                  <a
                                    className="nav-link dropdown-toggle poppins-regular"
                                    href="/about-us"
                                  >
                                    About Us
                                  </a>
                                </li>
                                <li className="nav-item dropdown">
                                  <a
                                    className="nav-link dropdown-toggle poppins-regular"
                                    href="/blog"
                                  >
                                    Blog
                                  </a>
                                </li>
                                <li className="nav-item dropdown">
                                  <a
                                    className="nav-link dropdown-toggle poppins-regular"
                                    href="/contact-us"
                                  >
                                    Contact Us
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rightside-menu">
                      <div className="option-list">
                        <ul>
                          <li>
                            <a
                              // href={
                              //   customer?._id && customer?.authStatus
                              //     ? `/customer/account/${customer?._id}`
                              //     : `/customer/login?r=dashboard`
                              // }
                              href={
                                customer?._id && customer?.authStatus
                                  ? `/customer-account?customerId=${customer?._id}`
                                  : `/login?r=dashboard`
                              }
                              className="header-icon bg-transparent !border-transparent user-icon search-icon"
                            >
                              <i className="iconly-Profile icli" />
                            </a>
                          </li>
                          {/* <li>
                            <a
                              href="#"
                              className="header-icon bg-transparent !border-transparent search-box search-icon"
                            >
                              <i className="iconly-Search icli" />
                            </a>
                          </li> */}
                          <li>
                            <a
                              href="compare.html"
                              className="header-icon bg-transparent !border-transparent"
                            >
                              <i className="iconly-Search icli" />
                            </a>
                          </li>

                          <li>
                            <div className="onhover-dropdown header-badge">
                              <a
                                href="#"
                                className="header-icon bg-transparent !border-transparent"
                                style={{ position: "relative" }}
                              >
                                <i className="iconly-Bag-2 icli" />
                                {totalQuantity > 0 && (
                                  <span
                                    className="absolute top-0 right-0 translate-middle badge"
                                    style={{
                                      backgroundColor: "#fd7e14",
                                      borderRadius: "30%",
                                      padding: "5px 4px",
                                      color: "white",
                                      fontSize: "12px",
                                      transform: "translate(50%, -50%)",
                                    }}
                                  >
                                    {totalQuantity}
                                  </span>
                                )}
                              </a>
                              <CartMenu />
                            </div>
                          </li>

                          <li>
                            <a
                              // href={
                              //   customer?._id && customer?.authStatus
                              //     ? `/customer/account/${customer?._id}`
                              //     : `/customer/login?r=dashboard`
                              // }
                              href={
                                customer?._id && customer?.authStatus
                                  ? `/customer-account?customerId=${customer?._id}`
                                  : `/login?r=dashboard`
                              }
                              className="header-icon bg-transparent !border-transparent"
                            >
                              <i className="iconly-Profile icli" />
                            </a>
                          </li>
                          <button
                            id="navbar-toggler"
                            className="navbar-toggler d-xl-none d-block p-0 me-3"
                            type="button"
                            // data-bs-toggle="offcanvas"
                            // data-bs-target="#primaryMenu"
                          >
                            <span className="navbar-toggler-icon pl-[2vw]">
                              <i className="fa-solid fa-bars !text-[#0FB16E] !text-[5.5vw]" />
                            </span>
                          </button>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Header End */}
        {/* mobile fix menu start */}
        <div className="mobile-menu d-md-none d-none mobile-cart">
          <ul>
            <li className="active">
              <a href="/">
                <i className="iconly-Home icli" />
                <span className="poppins-regular">Home</span>
              </a>
            </li>
            <li className="mobile-category">
              <a href="/category">
                <i className="iconly-Category icli js-link" />
                <span className="poppins-regular">Category</span>
              </a>
            </li>
            <li>
              <a href="/search" className="search-box">
                <i className="iconly-Search icli" />
                <span className="poppins-regular">Search</span>
              </a>
            </li>
            <li>
              <a href="/wishlist" className="notifi-wishlist">
                <i className="iconly-Heart icli" />
                <span className="poppins-regular">My Wish</span>
              </a>
            </li>
            <li>
              <a href="/cart">
                <i className="iconly-Bag-2 icli fly-cate" />
                <span className="poppins-regular">Cart</span>
              </a>
            </li>
          </ul>
        </div>
        {/* mobile fix menu end */}
        <div
          id="mobile-nav-modal"
          className="md:hidden w-[80%] bg-white h-screen fixed top-0 left-0 z-[999] p-[5vw] duration-300 -translate-x-full"
        >
          <div className="">
            <div className="flex items-center justify-between">
              <h5 className="text-[5vw] font-normal !text-[#0FB16E] poppins-regular">
                Menu
              </h5>
              <button
                className="btn-close lead"
                id="nav-close-btn"
                type="button"
              />
            </div>
            <div className="mt-[10vw]">
              <ul className="flex flex-col gap-[5vw]">
                <li className="text-[4vw]">
                  <a className="poppins-regular" href="/">
                    Home
                  </a>
                </li>
                <li className="text-[4vw]">
                  <a className="poppins-regular" href="/shop">
                    Shop
                  </a>
                </li>
                <li className="text-[4vw]">
                  <a className="poppins-regular" href="/about-us">
                    About Us
                  </a>
                </li>
                <li className="text-[4vw]">
                  <a className="poppins-regular" href="/blog">
                    Blog
                  </a>
                </li>
                <li className="text-[4vw]">
                  <a className="poppins-regular" href="/contact-us">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>

      {children}

      {/* <!-- Footer Section Start --> */}
      <Footer />

      <CookiesBar />

      <div className="bg-overlay"></div>
      {/* <!-- Bg overlay End --> */}
    </>
  );
};

export default WebLayout;
