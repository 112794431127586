import SliderComponent from "components/Slider";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {};

const Faq = (props: Props) => {
  const navigate = useNavigate();

  const handleShopNowClick = () => {
    navigate(`/shop`);
  };

  return (
    <>
      {/* <!-- FAQ and other Section Start  --> */}
      <section
        className="py-[18vw] md:py-[10vw]"
        style={{
          backgroundColor: "#FECF0A",
          background:
            "linear-gradient(0deg, #FECF0A 45%, rgba(255,255,255,1) 45%)",
        }}
      >
        <div className="container-fluid-lg">
          {/* <!-- FAQ Start --> */}
          <div className="faq-box-contain md:max-w-[80%] mx-auto">
            <div className="title w-100 d-flex flex-column justify-content-center align-items-center">
              <h2 className="text-[8vw] md:text-[3vw] !text-center !font-normal !text-[#0FB16E]">
                Frequently Asked Questions
              </h2>
              {/* <!-- <div className="w-[60%] mx-auto h-[1px] bg-[#F15624]/70 mt-[1.5vw]"></div> --> */}
            </div>

            <div className="mt-[8vw] md:mt-[4vw]">
              <div className="faq-accordion">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item rounded-[6vw] md:!rounded-[1vw] !px-[1vw] md:!px-[.75vw]">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed flex poppins-regular justify-between !py-[2vw] md:!py-[.4vw]"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                      >
                        What is Hidden in Hidden Health ?{" "}
                        <span className="aspect-square w-[8.5vw] md:w-[2.5vw] shrink-0 rounded-full flex items-center justify-center">
                          <i className="fa-solid fa-angle-down !m-0"></i>
                        </span>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body  !pb-[3vw] md:!pb-[1.25vw]">
                        <p>
                          The idea of hidden health is to revolutionize the way
                          busy individuals and families approach mealtime. We
                          imagine quick and convenient foods that secretly give
                          you an extra bite of health. We might give you a
                          sandwich filling or pasta sauce – But with goodness
                          hidden in it. The Hidden Health products are crafted
                          using traditional recipes with an added variety of
                          hidden vegetables which will increase the nutritional
                          value of the meals without losing the taste or visual
                          appeal, especially for the kids. Choose Hidden Health
                          and make nutritious food a seamless part of your
                          everyday routine, ensuring the well-being of those you
                          care about most.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item rounded-[6vw] md:!rounded-[1vw] !px-[1vw] md:!px-[.75vw]">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed flex poppins-regular justify-between !py-[2vw] md:!py-[.4vw]"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                      >
                        Do you deliver meat ?{" "}
                        <span className="aspect-square w-[8.5vw] md:w-[2.5vw] shrink-0 rounded-full flex items-center justify-center">
                          <i className="fa-solid fa-angle-down !m-0"></i>
                        </span>
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body  !pb-[3vw] md:!pb-[1.25vw]">
                        <p>
                          We believe protein is key to better health. We have
                          burger patties, nuggets, etc ready to cook and filled
                          with a lot of goodness.
                          <br />
                          <br />
                          Also, stay connected, we are planning for marinated
                          meat soon !
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item rounded-[6vw] md:!rounded-[1vw] !px-[1vw] md:!px-[.75vw]">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed flex poppins-regular justify-between !py-[2vw] md:!py-[.4vw]"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                      >
                        I have allergies to certain ingredients. What do I do ?{" "}
                        <span className="aspect-square w-[8.5vw] md:w-[2.5vw] shrink-0 rounded-full flex items-center justify-center">
                          <i className="fa-solid fa-angle-down !m-0"></i>
                        </span>
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body  !pb-[3vw] md:!pb-[1.25vw]">
                        <p>
                          We value the health and well-being of our customers.
                          And we understand how fatal allergens can become. We
                          have included an option to add allergen information
                          about you and your family in your account so that we
                          will know what to show you and you can shop carefree.
                          <br />
                          <br />
                          Or you can go to the shop page and choose the filter
                          option !
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item rounded-[6vw] md:!rounded-[1vw] !px-[1vw] md:!px-[.75vw]">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed flex poppins-regular justify-between !py-[2vw] md:!py-[.4vw]"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                      >
                        Do you offer to choose dietary preferences !{" "}
                        <span className="aspect-square w-[8.5vw] md:w-[2.5vw] shrink-0 rounded-full flex items-center justify-center">
                          <i className="fa-solid fa-angle-down !m-0"></i>
                        </span>
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body  !pb-[3vw] md:!pb-[1.25vw]">
                        <p>
                          Yes. Carnivore to vegan. We have got it all. Please
                          choose the filter option !
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item rounded-[6vw] md:!rounded-[1vw] !px-[1vw] md:!px-[.75vw]">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed flex poppins-regular justify-between !py-[2vw] md:!py-[.4vw]"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                      >
                        Do you deliver anywhere other than Dubai ?{" "}
                        <span className="aspect-square w-[8.5vw] md:w-[2.5vw] shrink-0 rounded-full flex items-center justify-center">
                          <i className="fa-solid fa-angle-down !m-0"></i>
                        </span>
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body  !pb-[3vw] md:!pb-[1.25vw]">
                        <p>
                          As of now, we serve only in Dubai ! But fingers
                          crossed to cater to more places ! All we need is your
                          love and support !
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- FAQ End --> */}

          {/* <!-- Mail Banner Start --> */}
          <div className="my-[18vw] md:my-[10vw] md:max-w-[80%] mx-auto newsletter-section-2">
            <div
              className="newsletter-box bg-size !rounded-[3vw] md:!rounded-[1.5vw] !bg-left-bottom"
              style={{
                backgroundImage: "url(/assets/images/home-new/banner-2.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "left bottom",
                backgroundRepeat: "no-repeat",
                display: "block",
              }}
            >
              <img
                src="/assets/images/home-new/banner-2.jpg"
                className="img-fluid bg-img"
                alt=""
                style={{ display: "none" }}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-[#000]/50 to-transparent z-[1]"></div>
              <div className="row relative z-[2]">
                <div className="col">
                  <div className="newsletter-detail p-center-left text-white !px-[6vw] !pt-[6vw] !pb-[3vw] w-full">
                    <div className="w-full">
                      <h2 className=" !mb-0">LIMITED TIME OFFER</h2>
                      <h1 className="text-[10vw] md:text-[5vw] leading-[.9] font-semibold  mt-6 md:!mt-[2.5vw]">
                        30% off for
                        <br />
                        your first order
                      </h1>
                      <div className="flex flex-col md:flex-row justify-between md:items-center w-full gap-[3vw] md:gap-0 mt-6 md:!mt-[2.5vw]">
                        <h2 className="!text-[3.75vw] md:!text-[1.75vw]">
                          +Free delivery for next three orders!
                        </h2>
                        <form className="row g-2">
                          <div className="col-sm-10 col-12">
                            <div className="newsletter-for row">
                              <div className="col-12 col-md-4">
                                <button
                                  onClick={() => {
                                    handleShopNowClick();
                                  }}
                                  className="btn btn-2 text-white !bg-[#F15624]  !px-[5vw] !py-[2.5vw] md:!py-[.85vw] md:!px-[2.15vw] !rounded-[2vw] !rounded-[2vw] md:!rounded-[.65vw]"
                                >
                                  <strong>Avail Offer</strong>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Mail Banner End --> */}
        </div>
        {/* <!-- Testimonials Start --> */}
        <div>
          <div id="www" className="product-list-section">
            <div className="container-fluid-lg">
              <div className="title w-100 d-flex flex-column justify-content-center align-items-center">
                <h2 className="text-[8vw] md:text-[3vw] !text-center !font-normal !text-[#0FB16E]">
                  Affirmations that matters!
                </h2>
                {/* <!-- <div className="w-[60%] mx-auto h-[1px] bg-white mt-[1.5vw]"></div> --> */}
              </div>
            </div>
            <div className="row mt-5 g-0">
              <div className="col-12 col-md-12 mx-auto">
                <div>
                  <div className="slider-4-blog product-wrapper overflow-hidden">
                    <SliderComponent
                      dots={true}
                      slidesToShow={4}
                      autoplay={true}
                      autoplaySpeed={3500}
                    >
                      <div>
                        <div className="people-box bg-white p-[3vw] md:p-[1.5vw] rounded-[3vw] md:rounded-[1vw]">
                          <div className="product-rating mb-2 mb-md-3">
                            <ul className="rating">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                            </ul>
                          </div>
                          <div className="people-comment">
                            <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                              Sandra K
                            </h2>
                            <div className="reply mt-4">
                              <p>
                                I have started to use Hidden Health products
                                within the context of my efforts while changing
                                over to eating cleaner, and I am frankly
                                impressed. It's great that the flavors are
                                fantastic, it's really easy to whip up some
                                healthy food after a long working day.
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center gap-[1vw] mt-4">
                            <div className="people-image people-text rounded-[1.5vw] overflow-hidden">
                              <img
                                alt="user"
                                className="testimonial-img-fluid "
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3pvOal57TfXTwhCqd7V_xO93h1LtPTZlgsw&s"
                              />
                            </div>

                            <div className="">
                              <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                                Sandra K
                              </h2>
                              <div className="reply mt-1">
                                <p>Customer </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="people-box bg-white p-[3vw] md:p-[1.5vw] rounded-[3vw] md:rounded-[1vw]">
                          <div className="product-rating mb-2 mb-md-3">
                            <ul className="rating">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                            </ul>
                          </div>
                          <div className="people-comment">
                            <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                              Priya G
                            </h2>
                            <div className="reply mt-4">
                              <p>
                                Being a working mom means always finding
                                solutions that can be reaped in an incredibly
                                efficient manner. Products from Hidden Health
                                haven't only saved me time but also introduced
                                my family to new flavors that are healthy and
                                enjoyable.
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center gap-[1vw] mt-4">
                            <div className="people-image people-text rounded-[1.5vw] overflow-hidden">
                              <img
                                alt="user"
                                className="testimonial-img-fluid"
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3pvOal57TfXTwhCqd7V_xO93h1LtPTZlgsw&s"
                              />
                            </div>

                            <div className="">
                              <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                                Priya G
                              </h2>
                              <div className="reply mt-1">
                                <p>Customer </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="people-box bg-white p-[3vw] md:p-[1.5vw] rounded-[3vw] md:rounded-[1vw]">
                          <div className="product-rating mb-2 mb-md-3">
                            <ul className="rating">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                            </ul>
                          </div>
                          <div className="people-comment">
                            <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                              Elliot W
                            </h2>
                            <div className="reply mt-4">
                              <p>
                                I never believed in pre-prepped foods until
                                Hidden Health came into my life. Their
                                commitment to quality and nutrition really shows
                                through in the products. Now, it is a staple in
                                my home.
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center gap-[1vw] mt-4">
                            <div className="people-image people-text rounded-[1.5vw] overflow-hidden">
                              <img
                                alt="user"
                                className="testimonial-img-fluid "
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3pvOal57TfXTwhCqd7V_xO93h1LtPTZlgsw&s"
                              />
                            </div>

                            <div className="">
                              <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                                Elliot W
                              </h2>
                              <div className="reply mt-1">
                                <p>Customer </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="people-box bg-white p-[3vw] md:p-[1.5vw] rounded-[3vw] md:rounded-[1vw]">
                          <div className="product-rating mb-2 mb-md-3">
                            <ul className="rating">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                            </ul>
                          </div>
                          <div className="people-comment">
                            <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                              Rebecca H
                            </h2>
                            <div className="reply mt-4">
                              <p>
                                I highly recommend the hidden health for their
                                Turkey Burgers with sweet potato fries. Good
                                taste combined with nutrition. My family enjoys
                                a great meal without any guilt!
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center gap-[1vw] mt-4">
                            <div className="people-image people-text rounded-[1.5vw] overflow-hidden">
                              <img
                                alt="user"
                                className="testimonial-img-fluid "
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3pvOal57TfXTwhCqd7V_xO93h1LtPTZlgsw&s"
                              />
                            </div>

                            <div className="">
                              <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                                Rebecca H
                              </h2>
                              <div className="reply mt-1">
                                <p>Customer </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="people-box bg-white p-[3vw] md:p-[1.5vw] rounded-[3vw] md:rounded-[1vw]">
                          <div className="product-rating mb-2 mb-md-3">
                            <ul className="rating">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                            </ul>
                          </div>
                          <div className="people-comment">
                            <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                              Carlos E
                            </h2>
                            <div className="reply mt-4">
                              <p>
                                Salmon Burgers with Avocado Crema are heavenly!
                                Who would have thought that this was also
                                healthy? Thank you, Hidden Health, for making
                                dinner exciting and healthy!
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center gap-[1vw] mt-4">
                            <div className="people-image people-text rounded-[1.5vw] overflow-hidden">
                              <img
                                alt="user"
                                className="testimonial-img-fluid "
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3pvOal57TfXTwhCqd7V_xO93h1LtPTZlgsw&s"
                              />
                            </div>

                            <div className="">
                              <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                                Carlos E
                              </h2>
                              <div className="reply mt-1">
                                <p>Customer </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="people-box bg-white p-[3vw] md:p-[1.5vw] rounded-[3vw] md:rounded-[1vw]">
                          <div className="product-rating mb-2 mb-md-3">
                            <ul className="rating">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                            </ul>
                          </div>
                          <div className="people-comment">
                            <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                              Liam G
                            </h2>
                            <div className="reply mt-4">
                              <p>
                                This Roasted Tomato Sauce is out of this world.
                                I have used this with pasta and as a pizza
                                sauce, both times the hit. And knowing that it's
                                all healthy only makes it that much better.
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center gap-[1vw] mt-4">
                            <div className="people-image people-text rounded-[1.5vw] overflow-hidden">
                              <img
                                alt="user"
                                className="testimonial-img-fluid "
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3pvOal57TfXTwhCqd7V_xO93h1LtPTZlgsw&s"
                              />
                            </div>

                            <div className="">
                              <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                                Liam G
                              </h2>
                              <div className="reply mt-1">
                                <p>Customer </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="people-box bg-white p-[3vw] md:p-[1.5vw] rounded-[3vw] md:rounded-[1vw]">
                          <div className="product-rating mb-2 mb-md-3">
                            <ul className="rating">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star fill"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-star"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </li>
                            </ul>
                          </div>
                          <div className="people-comment">
                            <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                              Samantha Y
                            </h2>
                            <div className="reply mt-4">
                              <p>
                                My kids are completely loving the Chicken
                                Nuggets with Broccoli and Cheddar! That changes
                                everything for our plans of meals. She likes how
                                it sneaks in vegetables, hidden health. She
                                loved it.
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center gap-[1vw] mt-4">
                            <div className="people-image people-text rounded-[1.5vw] overflow-hidden">
                              <img
                                alt="user"
                                className="testimonial-img-fluid "
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3pvOal57TfXTwhCqd7V_xO93h1LtPTZlgsw&s"
                              />
                            </div>

                            <div className="">
                              <h2 className="people-name font-semibold text-black text-[4vw] md:text-[1.35vw]">
                                Samantha Y
                              </h2>
                              <div className="reply mt-1">
                                <p>Customer </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SliderComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Testimonials End --> */}
        </div>
      </section>
      {/* <!-- FAQ and other Section End  --> */}
    </>
  );
};

export default Faq;
