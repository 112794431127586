import React from "react";
import WebLayout from "../../layout/WebLayout";

type Props = {};

const FAQ = (props: Props) => {
  return (
    <WebLayout>
      <>
        {/* Breadcrumb Section Start */}
        <section className="faq-breadcrumb pt-0">
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-contain">
                  <h2>Help Center</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Eius saepe neque officiis quidem inventore, asperiores
                    aspernatur. Quasi, in tenetur magni eius modi obcaecati,
                    eveniet facilis sunt cum suscipit laborum iure.
                  </p>
                  <div className="faq-form-tag">
                    <div className="input-group">
                      <i className="fa-solid fa-magnifying-glass" />
                      <input
                        type="search"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="name@example.com"
                      />
                      <div className="dropdown">
                        <button
                          className="btn btn-md faq-dropdown-button dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                        >
                          All Product{" "}
                          <i className="fa-solid fa-angle-down ms-2" />
                        </button>
                        <ul className="dropdown-menu faq-dropdown-menu dropdown-menu-end">
                          <li>
                            <a className="dropdown-item" href="#">
                              Action
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Another action
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Something else here
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Breadcrumb Section End */}
        {/* Faq Question section Start */}
        <section className="faq-contain">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="slider-4-2 product-wrapper">
                  <div>
                    <div className="faq-top-box">
                      <div className="faq-box-icon">
                        <img
                          src="/assets/images/inner-page/faq/start.png"
                          className="blur-up lazyload"
                          alt=""
                        />
                      </div>
                      <div className="faq-box-contain">
                        <h3>Getting Started</h3>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Provident facere laudantium tenetur, porro
                          doloremque accusantium, libero, quis odit harum
                          laborum at. Consectetur tenetur praesentium in
                          suscipit amet, impedit non quae?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="faq-top-box">
                      <div className="faq-box-icon">
                        <img
                          src="/assets/images/inner-page/faq/help.png"
                          className="blur-up lazyload"
                          alt=""
                        />
                      </div>
                      <div className="faq-box-contain">
                        <h3>Sales Question</h3>
                        <p>
                          Lorem ipsum dolor sit, amet consectetur adipisicing
                          elit. Molestias nihil, aliquid esse dolor possimus
                          sunt porro praesentium magni ex dolorum eveniet? Non
                          veritatis id atque facilis, harum vitae tempora
                          voluptatibus.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="faq-top-box">
                      <div className="faq-box-icon">
                        <img
                          src="/assets/images/inner-page/faq/price.png"
                          className="blur-up lazyload"
                          alt=""
                        />
                      </div>
                      <div className="faq-box-contain">
                        <h3>Pricing &amp; Plans</h3>
                        <p>
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Quae, maiores! Beatae ex sequi consectetur
                          aliquid ipsum amet sint adipisci, atque nesciunt
                          tempora et quo quia. Officiis asperiores earum quis
                          dolor.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="faq-top-box">
                      <div className="faq-box-icon">
                        <img
                          src="/assets/images/inner-page/faq/contact.png"
                          className="blur-up lazyload"
                          alt=""
                        />
                      </div>
                      <div className="faq-box-contain">
                        <h3>Support Contact</h3>
                        <p>
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit. Expedita incidunt ut, iusto error unde magni
                          culpa, perspiciatis cum in molestiae laboriosam et
                          delectus neque doloribus dolor eius perferendis
                          corrupti cumque.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Faq Question section End */}
        {/* Faq Section Start */}
        <section className="faq-box-contain section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-xl-5">
                <div className="faq-contain">
                  <h2>Frequently Asked Questions</h2>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Necessitatibus adipisci in corporis minus sint rem porro
                    omnis doloremque qui atque? Eaque illum maxime nam
                    necessitatibus fuga dolorem. Iure, sunt rerum?{" "}
                    <a
                      href="contact-us.html"
                      className="theme-color text-decoration-underline"
                    >
                      contact our support.
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-xl-7">
                <div className="faq-accordion">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit? <i className="fa-solid fa-angle-down" />
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Eius impedit, libero quae iusto obcaecati
                            deserunt perferendis repellendus a cum praesentium
                            incidunt vitae expedita consectetur totam, quasi
                            aspernatur sit. Similique, commodi.
                          </p>
                          <p>
                            Lorem ipsum, dolor sit amet consectetur{" "}
                            <span className="fw-bold">blog post.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit? <i className="fa-solid fa-angle-down" />
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Voluptates corporis facilis tempore saepe
                            aperiam rem qui fugit veritatis esse exercitationem
                            accusamus, fugiat magni ratione nihil sunt at quae
                            deleniti officia?.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit? <i className="fa-solid fa-angle-down" />
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. Sequi, molestias vero. Corporis id fugiat
                            consequatur iusto deserunt blanditiis tempora magni
                            quis provident, vel tempore inventore modi quibusdam
                            velit, quo optio..
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit? <i className="fa-solid fa-angle-down" />
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Ut, iste illum expedita, minima mollitia
                            eveniet laborum sit maiores, repellat animi id
                            debitis. Nam aut ducimus aspernatur doloribus
                            nesciunt tempora iusto!.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit? <i className="fa-solid fa-angle-down" />
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Eligendi repudiandae facere sed culpa sint?
                            Natus illo quasi recusandae quam quos saepe enim
                            dolorem magnam illum blanditiis! Nihil ab laudantium
                            minima!.
                          </p>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Ad, sapiente tempora sit tempore alias
                            adipisci molestiae qui nostrum. Nobis perspiciatis
                            rerum consequuntur. Voluptate repellat eligendi
                            soluta recusandae earum consequatur vitae!.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit? <i className="fa-solid fa-angle-down" />
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Voluptatem et eos mollitia debitis! Asperiores
                            incidunt perspiciatis, temporibus quod doloremque
                            odit omnis, mollitia quia porro suscipit molestiae!
                            Saepe consectetur sit ut?.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit? <i className="fa-solid fa-angle-down" />
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Laborum perferendis magni ipsa amet ex quasi,
                            accusamus maxime delectus, at nostrum veniam
                            dignissimos quaerat, ullam molestias expedita
                            suscipit corrupti voluptas sed..
                          </p>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Accusamus, doloremque libero. Earum doloremque
                            mollitia laudantium, eligendi dolorem{" "}
                            <span className="fw-bold">blog post.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit? <i className="fa-solid fa-angle-down" />
                        </button>
                      </h2>
                      <div
                        id="collapseEight"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing
                            elit. Doloribus ea numquam, nisi adipisci, rerum
                            libero ullam cupiditate quas nesciunt voluptatibus
                            odit! Vel beatae hic quod eum explicabo quaerat
                            aspernatur officia?{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseNine"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit? <i className="fa-solid fa-angle-down" />
                        </button>
                      </h2>
                      <div
                        id="collapseNine"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            {" "}
                            Lorem Ipsum has been the industry's standard dummy
                            text ever since the 1500s, when an unknown printer
                            took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five
                            centuries, but also the leap into electronic
                            typesetting, remaining essentially unchanged. It was
                            popularised in the 1960s with the release of
                            Letraset sheets containing Lorem Ipsum passages, and
                            more recently with desktop publishing software like
                            Aldus PageMaker including versions of Lorem Ipsum
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Faq Section End */}
      </>
    </WebLayout>
  );
};

export default FAQ;
