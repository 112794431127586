import React, { useState, useEffect } from "react";
import ReviewModal from "./ReviewModal";
import Rating from "./Rating";

type Props = {
  product: string;
  customer?: any;
  reviewList: any;
};

const Page = (props: Props) => {
  const { product, customer, reviewList } = props;
  const [openReviewModal, setOpenReviewModal] = useState<boolean>(false);
  const [totalReviewCount, setTotalReviewCount] = useState(0);
  const [reviewDataList, setReviewDataList] = useState<any>();

  const [sortCase, setSortCase] = useState("High - Low");

  useEffect(() => {
    if (reviewList && reviewList[0]) {
      setTotalReviewCount(reviewList[0]?.overallTotalReviews);

      const reviewData = reviewList[0].ratingGroups.flatMap(
        (item: any) => item.reviews
      );

      switch (sortCase) {
        case "Recent":
          setReviewDataList(
            reviewData.sort((a, b) => b.createdAt - a.createdAt)
          );
          break;
        case "Oldest":
          setReviewDataList(
            reviewData.sort((a, b) => a.createdAt - b.createdAt)
          );
          break;
        case "High - Low":
          setReviewDataList(reviewData.sort((a, b) => b.rating - a.rating));
          break;
        case "Low - High":
          setReviewDataList(reviewData.sort((a, b) => a.rating - b.rating));
          break;

        default:
          setReviewDataList(reviewData.sort((a, b) => b.rating - a.rating));
          break;
      }
      setReviewDataList(reviewData);
    }

    return () => {};
  }, [reviewList, sortCase]);

  const handleOpenReviewModal = () => {
    setOpenReviewModal(true);
  };
  const handleCloseReviewModal = () => {
    setOpenReviewModal(false);
  };
  const handleReviewModal = () => {
    setOpenReviewModal(!openReviewModal);
  };

  return (
    <section className="pb-[14vw] md:pb-[7vw] !pt-0">
      <div className="container-fluid-lg product-section-box !mt-0">
        <h2 className="text-[4.5vw] md:text-[1.95vw] !text-[#0FB16E]">
          Customer Reviews
        </h2>
        <div className="review-box mt-[5vw] md:mt-[2vw]">
          <div className="row">
            <div className="col-xl-5">
              <div className="product-rating-box">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="product-main-rating">
                      <Rating level={reviewList[0]?.overallAverageRating} />
                      <h2 className="!font-normal lowercase pl-[1vw]">
                        {reviewList[0]?.overallAverageRating} out of 5
                      </h2>
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <ul className="product-rating-list">
                      <li>
                        <div className="rating-product">
                          <Rating level={5} />
                          <div className="progress h-[2vw] md:!h-[.9vw] !rounded-none">
                            <div
                              className="progress-bar !rounded-none"
                              style={{
                                width: `${
                                  ((totalReviewCount -
                                    reviewList[0]?.ratingGroups[4]
                                      ?.totalReviews) *
                                    100) /
                                  totalReviewCount
                                }%`,
                              }}
                            ></div>
                          </div>
                          <h5 className="total">
                            {reviewList[0]?.ratingGroups[4]?.totalReviews}
                          </h5>
                        </div>
                      </li>
                      <li>
                        <div className="rating-product">
                          <Rating level={4} />
                          <div className="progress h-[2vw] md:!h-[.9vw] !rounded-none">
                            <div
                              className="progress-bar !rounded-none"
                              style={{
                                width: `${
                                  ((totalReviewCount -
                                    reviewList[0]?.ratingGroups[3]
                                      ?.totalReviews) *
                                    100) /
                                  totalReviewCount
                                }%`,
                              }}
                            ></div>
                          </div>
                          <h5 className="total">
                            {reviewList[0]?.ratingGroups[3]?.totalReviews}
                          </h5>
                        </div>
                      </li>
                      <li>
                        <div className="rating-product">
                          <Rating level={3} />
                          <div className="progress h-[2vw] md:!h-[.9vw] !rounded-none">
                            <div
                              className="progress-bar !rounded-none"
                              style={{
                                width: `${
                                  ((totalReviewCount -
                                    reviewList[0]?.ratingGroups[2]
                                      ?.totalReviews) *
                                    100) /
                                  totalReviewCount
                                }%`,
                              }}
                            ></div>
                          </div>
                          <h5 className="total">
                            {reviewList[0]?.ratingGroups[2]?.totalReviews}
                          </h5>
                        </div>
                      </li>
                      <li>
                        <div className="rating-product">
                          <Rating level={2} />
                          <div className="progress h-[2vw] md:!h-[.9vw] !rounded-none">
                            <div
                              className="progress-bar !rounded-none"
                              style={{
                                width: `${
                                  ((totalReviewCount -
                                    reviewList[0]?.ratingGroups[1]
                                      ?.totalReviews) *
                                    100) /
                                  totalReviewCount
                                }%`,
                              }}
                            ></div>
                          </div>
                          <h5 className="total">
                            {reviewList[0]?.ratingGroups[1]?.totalReviews}
                          </h5>
                        </div>
                      </li>
                      <li>
                        <div className="rating-product">
                          <Rating level={1} />
                          <div className="progress h-[2vw] md:!h-[.9vw] !rounded-none">
                            <div
                              className="progress-bar !rounded-none"
                              style={{
                                width: `${
                                  ((totalReviewCount -
                                    reviewList[0]?.ratingGroups[0]
                                      ?.totalReviews) *
                                    100) /
                                  totalReviewCount
                                }%`,
                              }}
                            ></div>
                          </div>
                          <h5 className="total">
                            {reviewList[0]?.ratingGroups[0]?.totalReviews}
                          </h5>
                        </div>
                      </li>
                    </ul>

                    {customer?.c_user?.authStatus && (
                      <button
                        className="btn !bg-[#F15624] !px-[5vw] !py-[2.5vw] md:!py-[1vw] md:!px-[3.15vw] !rounded-[2vw] !rounded-[2vw] md:!rounded-[.65vw] mx-auto mt-[7vw] md:mt-[2vw] text-white text-[4vw] md:text-[1.25vw]"
                        type="button"
                        onClick={handleOpenReviewModal}
                      >
                        Write a review
                      </button>
                    )}
                  </div>

                  <div className="col-xl-10 col-11 mx-auto">
                    <div
                      className="relative rounded-[2vw] md:rounded-[.5vw] overflow-hidden text-white poppins-regular aspect-[15/18] !mt-[6vw] md:!mt-[4vw] bg-size blur-up lazyloaded"
                      style={{
                        backgroundImage:
                          'url("/assets/images/home-new/banner.jpg")',
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        display: "block",
                      }}
                    >
                      <div className="p-center-left px-[5vw] py-[6.5vw] md:py-[2.5vw] md:px-[2vw] flex flex-col items-start justify-between h-full">
                        <div>
                          <h2 className="!mb-0 text-[4vw] md:text-[1.5vw]">
                            LIMITED TIME OFFER
                          </h2>
                          <h1 className="text-[9vw] md:text-[3.15vw] leading-[1] font-regular mt-1 md:!mt-[1vw]">
                            30% off for <br /> your first order
                          </h1>
                        </div>

                        <div className="w-full">
                          <button
                            // onclick="location.href = 'shop-left-sidebar.html';"
                            className="btn btn-2 !font-medium text-white !bg-[#F15624] !px-[5vw] !py-[2.5vw] md:!py-[.95vw] md:!px-[2.75vw] !rounded-[2vw] !rounded-[2vw] md:!rounded-[.65vw] text-[4vw] md:text-[1.75vw] !mx-auto"
                          >
                            Avail Offer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-7">
              <div className="review-people !max-h-full">
                <div className="shop-section">
                  <div className="show-button">
                    <div className="top-filter-menu">
                      <div className="category-dropdown">
                        <div className="dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span>{sortCase}</span>{" "}
                            <i className="fa-solid fa-angle-down"></i>
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <button
                                className="dropdown-item"
                                id="pop"
                                onClick={() => setSortCase("Recent")}
                              >
                                Recent
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                id="low"
                                onClick={() => setSortCase("Oldest")}
                              >
                                Oldest
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                id="low"
                                onClick={() => setSortCase("High - Low")}
                              >
                                High - Low
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                id="low"
                                onClick={() => setSortCase("Low - High")}
                              >
                                Low - High
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="review-list !gap-[.5vw]">
                  {reviewDataList &&
                    reviewDataList.map((item: any) => (
                      <li>
                        <div className="people-box !pt-0 !bg-transparent">
                          <div>
                            <div className="people-comment !w-full !mt-[2vw] md:!mt-0">
                              <div className="people-name">
                                <a
                                  href="#"
                                  className="name !text-[4vw] md:!text-[1.65vw] !font-normal leading-[1]"
                                >
                                  {item?.reviewedBy?.name}
                                </a>
                                <div className="date-time">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-[#2C904E] w-[4vw] md:w-[1.5vw]"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                  >
                                    <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"></path>
                                  </svg>
                                </div>
                              </div>
                              <div className="product-rating mt-[1vw] md:mt-0">
                                <Rating level={item?.rating || 0} />
                              </div>
                              <div className="reply !mt-[2vw] md:!mt-[1vw]">
                                <p className="!text-[3vw] md:!text-[1.05vw]">
                                  {item?.comment}
                                </p>
                              </div>

                              {item?.attachments && (
                                <div className="mt-[1.5vw] grid grid-cols-4 gap-[1vw]">
                                  {item.attachments.map((attachment: any) => (
                                    <img
                                      src={attachment}
                                      className="aspect-square object-cover rounded-[.75vw]"
                                      alt=""
                                    />
                                  ))}
                                </div>
                              )}
                              <span className="text-xs text-gray-400">
                                {new Date(item?.createdAt).toLocaleString(
                                  "en-US",
                                  {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReviewModal
        open={openReviewModal}
        handler={handleReviewModal}
        close={handleCloseReviewModal}
        product={product}
        customer={customer}
      />
    </section>
  );
};

export default Page;
