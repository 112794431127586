import React, { useState, useEffect, useRef } from "react";
import { useAsyncValue, useNavigate } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";

import { createBlog, getBlogList, blogByStatus } from "api/blog";

import { getProductList, productDelete } from "api/product";

type Props = {};

const Blog = (props: Props) => {
  const navigate = useNavigate();

  const [blogList, setBlogList] = useState<any>();
  const [originalList, setOriginalList] = useState<any>(blogList);
  const [productList, setProductList] = useState<any>();

  useEffect(() => {
    blogByStatus({
      status: "approved",
    })
      .then((res: any) => {
        setBlogList(res?.result);
        setOriginalList(res?.result);
      })
      .catch((error) => {
        console.log("Failed to get blog list : ", error);
      });
    return () => {};
  }, []);

  const formatDate = (timestamp: string): string => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options).toUpperCase();
  };

  const readBlog = (blogId: any) => {
    navigate(`/blog-details?blogId=${blogId}`);
  };

  useEffect(() => {
    getProductList()
      .then((res: any) => {
        setProductList(res?.result);
      })
      .catch((error: any) => {
        console.log("Failed to get product list : , error", error);
      });
    return () => {};
  }, []);

  // Function to handle the search
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === "") {
      setBlogList(originalList);
    } else {
      const filteredData = blogList.filter(
        (item) =>
          item.title && item.title.toLowerCase().includes(value.toLowerCase())
      );
      setBlogList(filteredData);
    }
  };

  return (
    <WebLayout>
      {/* Blog Section Start  */}
      <section className="blog-section section-b-space pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
        <div className="container-fluid-lg">
          <div className="row g-4">
            <div className="col-xxl-9 col-xl-8 col-lg-7 order-lg-2">
              <div className="row g-4 ratio_65">
                {blogList &&
                  blogList.map((item: any, index: number) => (
                    <div className="col-xxl-4 col-sm-6">
                      <div className="blog-box wow fadeInUp">
                        <div className="blog-image">
                          <a href={`/blog-details?blogId=${item?._id}`}>
                            <img
                              src={item?.images?.location}
                              className="bg-img blur-up lazyload blog-img"
                              alt=""
                            />
                          </a>
                        </div>

                        <div className="blog-contain">
                          <div className="blog-label">
                            <span className="time">
                              <i data-feather="clock"></i>{" "}
                              <span>{formatDate(item?.createdAt)}</span>
                            </span>
                            <span className="super">
                              <i data-feather="user"></i>{" "}
                              <span>{item?.auther}</span>
                            </span>
                          </div>
                          <a href={`/blog-details?blogId=${item?._id}`}>
                            <h3>{item?.title}</h3>
                          </a>
                          <button
                            onClick={()=>readBlog(item?._id)}
                            className="blog-button"
                          >
                            Read More
                            <i className="fa-solid fa-right-long"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <nav className="custom-pagination">
                <ul className="pagination justify-content-center">
                  <li className="page-item disabled">
                    <a className="page-link" href="javascript:void(0)">
                      <i className="fa-solid fa-angles-left"></i>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="javascript:void(0)">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0)">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0)">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0)">
                      <i className="fa-solid fa-angles-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="col-xxl-3 col-xl-4 col-lg-5 order-lg-1">
              <div className="left-sidebar-box wow fadeInUp">
                <div className="left-search-box">
                  <div className="search-box">
                    <input
                      type="search"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Search...."
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                </div>

                <div
                  className="accordion left-accordion-box"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-headingOne"
                    >
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                      >
                        Highlights
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body pt-0">
                        <div className="recent-post-box">
                          {blogList &&
                            blogList.slice(0, 4) &&
                            blogList.slice(0, 4).map((item: any) => (
                              <div className="recent-box">
                                <a
                                  href={`/blog-details?blogId=${item?._id}`}
                                  className="recent-image"
                                >
                                  <img
                                    src={item?.images?.location}
                                    className="img-fluid blur-up lazyload"
                                    alt=""
                                  />
                                </a>

                                <div className="recent-detail">
                                  <a href={`/blog-details?blogId=${item?._id}`}>
                                    <h5 className="recent-name">
                                      {item?.title}
                                    </h5>
                                  </a>
                                  <h6>
                                    {formatDate(item?.createdAt)}
                                    <i data-feather="thumbs-up"></i>
                                  </h6>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-headingFour"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                      >
                        Trending Products
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse collapse show"
                    >
                      <div className="accordion-body">
                        <ul className="product-list product-list-2 border-0 p-0">
                          {productList &&
                            productList.slice(0, 4) &&
                            productList.slice(0, 4).map((item: any) => (
                              <li>
                                <div className="offer-product">
                                  <a
                                    href="shop-left-sidebar.html"
                                    className="offer-image"
                                  >
                                    <img
                                      src={item?.images[0]?.location}
                                      className="blur-up lazyload"
                                      alt=""
                                    />
                                  </a>

                                  <div className="offer-detail">
                                    <div>
                                      <a href="shop-left-sidebar.html">
                                        <h6 className="name">
                                        {item?.name}
                                        </h6>
                                      </a>
                                      <span>{item?.weight}</span>
                                      <h6 className="price theme-color">
                                        AED {item?.price}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Blog Section End */}
    </WebLayout>
  );
};

export default Blog;
