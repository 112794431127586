import React, { useState, useEffect } from "react";
import WebLayout from "../../layout/WebLayout";

import { useParams, useNavigate } from "react-router-dom";

import { getOrderDataById } from "api/order";
import { getAddressByCustomer, getAddressData } from "api/address";
import { getProductData } from "api/product";

type Props = {};

const OrderTrackingDetails = (props: Props) => {
  // const { orderId, productId } = useParams();

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const orderId = params.get("orderId");
  const productId = params.get("productId");

  const [orderData, setOrderData] = useState<any>();
  const [productData, setProductData] = useState<any>();

  useEffect(() => {
    if (orderId) {
      getOrderDataById(orderId)
        .then((res: any) => {
          console.log("order details```````````````` : ", res);
          setOrderData(res[0]);
          const _filterData = res[0]?.products.filter(
            (item) => item?._id === productId
          );
          setProductData(_filterData[0]);
          console.log("_filterData : ", _filterData);
        })
        .catch((error) => {
          console.log("failed to get order data : ", error);
        });
    }
    return () => {};
  }, [orderId, productId]);

  const formatDate = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    const day = dateObject.getDate(); // Get the day
    const month = dateObject.getMonth() + 1; // Get the month (0-indexed, so add 1)
    const year = dateObject.getFullYear(); // Get the full year

    // Format the date as '13-9-2024'
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const formatTime = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);

    let hours = dateObject.getHours();
    const minutes = String(dateObject.getMinutes()).padStart(2, "0"); // Get minutes and ensure 2 digits
    const ampm = hours >= 12 ? "PM" : "AM"; // Determine AM or PM
    hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format (handle 0 as 12)

    // Format the time as '8:22 PM'
    const formattedTime = `${hours}:${minutes} ${ampm}`;
    return formattedTime;
  };

  return (
    <WebLayout>
      {/* <!-- Order Detail Section Start --> */}
      <section className="order-detail mb-4  section-b-space pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
        <div className="container-fluid-lg">
          <div className="row g-sm-4 g-3">
            <div className="col-xxl-3 col-xl-4 col-lg-6">
              <div className="order-image">
                <img
                  src={orderData?.products[0]?.thumbImage}
                  className="img-fluid blur-up lazyload"
                  alt=""
                />
              </div>
            </div>

            <div className="col-xxl-9 col-xl-8 col-lg-6">
              <div className="row g-sm-4 g-3">
                <div className="col-xl-4 col-sm-6">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <i data-feather="package" className="text-content"></i>
                    </div>

                    <div className="order-details-name">
                      <h5 className="text-content">Tracking Code</h5>
                      <h3 className="theme-color">{orderData?._id}</h3>
                    </div>
                  </div>
                </div>

                {/* <div className="col-xl-4 col-sm-6">
                            <div className="order-details-contain">
                                <div className="order-tracking-icon">
                                    <i data-feather="truck" className="text-content"></i>
                                </div>

                                <div className="order-details-name">
                                    <h5 className="text-content">Service</h5>
                                    <img src="/assets/images/inner-page/brand-name.svg"
                                        className="img-fluid blur-up lazyload" alt="" />
                                </div>
                            </div> 
                        </div> */}

                {/* <div className="col-xl-4 col-sm-6">
                            <div className="order-details-contain">
                                <div className="order-tracking-icon">
                                    <i className="text-content" data-feather="info"></i>
                                </div>

                                <div className="order-details-name">
                                    <h5 className="text-content">Package Info</h5>
                                    <h4>Letter</h4>
                                </div>
                            </div>
                        </div>  */}

                {/* <div className="col-xl-4 col-sm-6">
                            <div className="order-details-contain">
                                <div className="order-tracking-icon">
                                    <i className="text-content" data-feather="crosshair"></i>
                                </div>

                                <div className="order-details-name">
                                    <h5 className="text-content">From</h5>
                                    <h4>STR. Smardan 9, Bucuresti, romania.</h4>
                                </div>
                            </div>
                        </div>  */}

                <div className="col-xl-4 col-sm-6">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <i className="text-content" data-feather="map-pin"></i>
                    </div>

                    <div className="order-details-name">
                      <h5 className="text-content">Destination</h5>
                      <h4>{orderData?.deliveryAddress?.userAddress}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-sm-6">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <i className="text-content" data-feather="calendar"></i>
                    </div>

                    <div className="order-details-name">
                      <h5 className="text-content">Estimated Time</h5>
                      <h4>{orderData?.expectedDelivery}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-12 overflow-hidden">
                  <ol className="progtrckr">
                    <li
                      className={
                        orderData?.orderTrack?.orderStatus?.order_placed
                          ?.status === "completed"
                          ? "progtrckr-done"
                          : "progtrckr-todo"
                      }
                    >
                      <h5>Order Placed</h5>
                      {orderData?.orderTrack?.orderStatus?.order_placed
                        ?.updatedAt && (
                        <>
                          <h6>
                            {formatDate(
                              orderData?.orderTrack?.orderStatus?.order_placed
                                ?.updatedAt
                            )}
                          </h6>
                          <h6>
                            {formatTime(
                              orderData?.orderTrack?.orderStatus?.order_placed
                                ?.updatedAt
                            )}
                          </h6>
                        </>
                      )}
                    </li>

                    <li
                      className={
                        orderData?.orderTrack?.orderStatus?.order_confirmed
                          ?.status === "completed"
                          ? "progtrckr-done"
                          : "progtrckr-todo"
                      }
                    >
                      <h5>Order Confirmed</h5>
                      {orderData?.orderTrack?.orderStatus?.order_confirmed
                        ?.updatedAt && (
                        <>
                          <h6>
                            {formatDate(
                              orderData?.orderTrack?.orderStatus
                                ?.order_confirmed?.updatedAt
                            )}
                          </h6>
                          <h6>
                            {formatTime(
                              orderData?.orderTrack?.orderStatus
                                ?.order_confirmed?.updatedAt
                            )}
                          </h6>
                        </>
                      )}
                    </li>
                    <li
                      className={
                        orderData?.orderTrack?.orderStatus?.food_assembly
                          ?.status === "completed"
                          ? "progtrckr-done"
                          : "progtrckr-todo"
                      }
                    >
                      <h5>Food Assembly</h5>
                      {orderData?.orderTrack?.orderStatus?.food_assembly
                        ?.updatedAt && (
                        <>
                          <h6>
                            {formatDate(
                              orderData?.orderTrack?.orderStatus?.food_assembly
                                ?.updatedAt
                            )}
                          </h6>
                          <h6>
                            {formatTime(
                              orderData?.orderTrack?.orderStatus?.food_assembly
                                ?.updatedAt
                            )}
                          </h6>
                        </>
                      )}
                    </li>
                    <li
                      className={
                        orderData?.orderTrack?.orderStatus?.out_for_delivery
                          ?.status === "completed"
                          ? "progtrckr-done"
                          : "progtrckr-todo"
                      }
                    >
                      <h5>Out For Delivery</h5>
                      {orderData?.orderTrack?.orderStatus?.out_for_delivery
                        ?.updatedAt && (
                        <>
                          <h6>
                            {formatDate(
                              orderData?.orderTrack?.orderStatus
                                ?.out_for_delivery?.updatedAt
                            )}
                          </h6>
                          <h6>
                            {formatTime(
                              orderData?.orderTrack?.orderStatus
                                ?.out_for_delivery?.updatedAt
                            )}
                          </h6>
                        </>
                      )}
                    </li>
                    <li
                      className={
                        orderData?.orderTrack?.orderStatus?.delivered
                          ?.status === "completed"
                          ? "progtrckr-done"
                          : "progtrckr-todo"
                      }
                    >
                      <h5>Delivered</h5>
                      {orderData?.orderTrack?.orderStatus?.delivered
                        ?.updatedAt && (
                        <>
                          <h6>
                            {formatDate(
                              orderData?.orderTrack?.orderStatus?.delivered
                                ?.updatedAt
                            )}
                          </h6>
                          <h6>
                            {formatTime(
                              orderData?.orderTrack?.orderStatus?.delivered
                                ?.updatedAt
                            )}
                          </h6>
                        </>
                      )}
                    </li>
                    {orderData?.orderTrack?.orderStatus?.cancelled
                      ?.updatedAt && (
                      <li
                        className={
                          orderData?.orderTrack?.orderStatus?.cancelled
                            ?.status === "completed"
                            ? "progtrckr-done"
                            : "progtrckr-todo"
                        }
                      >
                        <h5>Order Cancelled</h5>
                        <h6>
                          {formatDate(
                            orderData?.orderTrack?.orderStatus?.cancelled
                              ?.updatedAt
                          )}
                        </h6>
                        <h6>
                          {formatTime(
                            orderData?.orderTrack?.orderStatus?.cancelled
                              ?.updatedAt
                          )}
                        </h6>
                      </li>
                    )}

                    {orderData?.orderTrack?.orderStatus?.cancelled && (
                      // && orderData?.orderTrack?.orderStatus?.refund_initiated
                      //     ?.updatedAt
                      <li
                        className={
                          orderData?.orderTrack?.orderStatus?.refund_initiated
                            ?.status === "completed"
                            ? "progtrckr-done"
                            : "progtrckr-todo"
                        }
                      >
                        <h5>Refund Initiated</h5>
                        {orderData?.orderTrack?.orderStatus?.refund_initiated
                          ?.updatedAt && (
                          <>
                            <h6>
                              {formatDate(
                                orderData?.orderTrack?.orderStatus
                                  ?.refund_initiated?.updatedAt
                              )}
                            </h6>

                            <h6>
                              {formatTime(
                                orderData?.orderTrack?.orderStatus
                                  ?.refund_initiated?.updatedAt
                              )}
                            </h6>
                          </>
                        )}
                      </li>
                    )}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Order Detail Section End --> */}
    </WebLayout>
  );
};

export default OrderTrackingDetails;
