import React, { useState, useEffect } from "react";
import { useAppSelector } from "lib/redux/hooks";

import { getAddressByCustomer, getAddressData } from "api/address";
import { getCustomerData, customerUpdate } from "api/customer";

// import { Button, Chip, XIcon  } from "@material-tailwind/react";
// import { Chip, XIcon } from "@material-tailwind/react";
import { Chip } from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid"; // Import the close icon

import Dialog from "components/Dialog";
import "./styles.css";
import { getOrderList, getOrderCount } from "api/order";

type Props = {
  profileUpdated: (status: boolean) => void;
};

interface Option {
  value: string;
  label: string;
}

interface Insights {
  onMessageChange: (message: string) => void;
}

const initialOptions: Option[] = [
  { value: "milk", label: "Milk" },
  { value: "egg", label: "Egg" },
  { value: "red-meat", label: "Red Meat" },
  { value: "tree-nuts", label: "Tree Nuts" },
  { value: "peanuts", label: "Peanuts" },
  { value: "wheat", label: "Wheat" },
  { value: "soybeans", label: "Soybeans" },
];

const Insights = (props: Props) => {
  const customer = useAppSelector((state) => state.customerReducer.c_user);

  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const [name, setName] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [phone, setPhone] = useState<any>();
  const [pincode, setPincode] = useState<any>();

  const fetchCustomerData = async () => {
    getCustomerData(customer?._id)
      .then((res: any) => {
        setName(res?.result?.name);
        setEmail(res?.result?.email);
        setPhone(res?.result?.phone);
        setAddress(res?.result?.address);
        setPincode(res?.result?.pincode);
        setSelectedOptions(res?.result?.allergents);
        fetchDefaultBillingAddress(res?.result?.defaultBillingAddress);
        fetchDefaultDeliveryAddress(res?.result?.defaultDeliveryAddress);
      })
      .catch((error) => {
        console.log("failed to fetch customer datails : ", error);
      });
    return () => {};
  };

  useEffect(() => {
    if (customer?._id) {
      fetchAddressList();
      fetchCustomerData();
    }
    return () => {};
  }, [customer?._id]);

  const [orderCount, setOrderCount] = useState<any>();
  const [orderPendingCount, setOrderPendingCount] = useState<any>();

  useEffect(() => {
    if (customer?._id) {
      getOrderList(customer?._id)
        .then((response: any) => {
          console.log("order count : ", response);
          setOrderCount(response.length);
          const _filter =
            response &&
            response.filter(
              (item) =>
                item?.orderStatus?.status !== "cancelled" &&
                item?.orderStatus?.status !== "delivered" &&
                item?.orderStatus?.status !== "refund_initiated"
            );

          if (_filter) {
            setOrderPendingCount(_filter.length);
          }
          console.log("_filter : ", _filter);
        })
        .catch((error) => {
          console.log("Failed to get order data : ", error);
        });
    }

    return () => {};
  }, [customer?._id]);

  // edit profile functions
  const handleEditProfileModalOpen = () => {
    setOpenEditProfileModal(!openEditProfileModal);
  };

  const handleEditProfileSave = () => {
    // TODO: handle profile Edit
    // to close modal after Edit completion
    const data = {
      name,
      address,
      phone,
      pincode,
    };
    customerUpdate(customer?._id, data)
      .then((res: any) => {
        fetchCustomerData();
        props.profileUpdated(true);
      })
      .catch((error) => {
        console.log("failed to update customer datails : ", error);
      });
    handleEditProfileModalOpen();
  };

  // delete profile functions
  const handleEditProfileClose = () => {
    // TODO: handle blog edit
    // to close modal after Edit completion
    handleEditProfileModalOpen();
  };

  // address
  const [addressList, setAddressList] = useState<any>();

  const fetchAddressList = async () => {
    getAddressByCustomer(customer?._id)
      .then((res) => {
        setAddressList(res?.result);
      })
      .catch((error) => {
        console.log("Failed to get address Result : ", error);
      });
  };

  const [defaultBillingAddress, setDefaultBillingAddress] = useState<any>();
  const [defaultDeliveryAddress, setDefaultDeliveryAddress] = useState<any>();
  const [addressChooseEdit, setAddressChooseEdit] = useState(false);

  const handleAddressChooseOpen = () => {
    setAddressChooseEdit(!addressChooseEdit);
  };

  const fetchDefaultBillingAddress = async (addressId: string) => {
    getAddressData(addressId)
      .then((res: any) => {
        setDefaultBillingAddress(res?.result);
      })
      .catch((error: any) => {
        console.log("failed to get billing address : ", error);
      });
  };

  const updateUserBillingAddress = async (data: any) => {
    if (customer?._id) {
      customerUpdate(customer?._id, data)
        .then((res: any) => {
          fetchDefaultBillingAddress(data?.defaultBillingAddress);
        })
        .catch((error) => {
          console.log("failed to update customer datails : ", error);
        });
    }
  };

  const handleBillingAddressClick = (e: any) => {
    const data = {
      defaultBillingAddress: e?.target?.value,
    };
    updateUserBillingAddress(data);
  };

  const fetchDefaultDeliveryAddress = (addressId: string) => {
    getAddressData(addressId)
      .then((res: any) => {
        setDefaultDeliveryAddress(res?.result);
      })
      .catch((error: any) => {
        console.log("failed to get delivery address : ", error);
      });
  };

  const updateUserDeliveryAddress = async (data: any) => {
    if (customer?._id) {
      customerUpdate(customer?._id, data)
        .then((res: any) => {
          fetchDefaultDeliveryAddress(data?.defaultDeliveryAddress);
        })
        .catch((error) => {
          console.log("failed to update customer datails : ", error);
        });
    }
  };

  const handleDeliveryAddressClick = async (e: any) => {
    const data = {
      defaultDeliveryAddress: e?.target?.value,
    };
    updateUserDeliveryAddress(data);
  };

  //allergents

  const [addressAllergentEdit, setAllergentChooseEdit] = useState(false);

  const handleAllergentChooseOpen = () => {
    setAllergentChooseEdit(!addressAllergentEdit);
  };

  const [options, setOptions] = useState<Option[]>(initialOptions);
  const [allergentsList, setAllergentsList] =
    useState<Option[]>(initialOptions);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    );

    if (selectedOption) {
      setSelectedOptions([...selectedOptions, selectedOption]);
      setOptions(options.filter((option) => option.value !== selectedValue));
    }
  };

  useEffect(() => {
    if (selectedOptions && options && !addressAllergentEdit) {
      const filteredOptions = options.filter(
        (option) =>
          !selectedOptions.find(
            (selectedOption) => selectedOption.value === option.value
          )
      );
      setOptions(filteredOptions);
    }

    if (selectedOptions && addressAllergentEdit) {
      const data = {
        allergents: selectedOptions,
      };
      customerUpdate(customer?._id, data)
        .then((res: any) => {})
        .catch((error) => {
          console.log("failed to update customer datails : ", error);
        });
    }

    return () => {};
  }, [selectedOptions]);

  const handleDeleteChip = (value: string) => {
    const originalList = allergentsList.filter(
      (allergents) => allergents.value === value
    );

    if (originalList && originalList.length > 0) {
      const optionToRemove = selectedOptions.find(
        (option) => option.value === value
      );
      if (optionToRemove) {
        setOptions([...options, optionToRemove]);
        setSelectedOptions(
          selectedOptions.filter((option) => option.value !== value)
        );
      }
    } else {
      setSelectedOptions(
        selectedOptions.filter((option) => option.value !== value)
      );
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      const newOption = { value: inputValue.trim(), label: inputValue.trim() };
      setSelectedOptions([...selectedOptions, newOption]);
      setInputValue("");
    }
  };

  return (
    <div className="dashboard-home">
      <div className="title dashboard-title">
        <h2>My Dashboard</h2>
        <span className="title-leaf">
          <svg className="icon-width bg-gray">
            <use xlinkHref="../../assets/svg/leaf.svg#leaf" />
          </svg>
        </span>
      </div>
      <div className="dashboard-user-name">
        <h6 className="text-content">
          Hello, <b className="text-title">{name}</b>
        </h6>
        <p className="text-content">
          From your My Account Dashboard you have the ability to view a snapshot
          of your recent account activity and update your account information.
          Select a link below to view or edit information.
        </p>
      </div>
      <div className="total-box">
        <div className="row g-sm-4 g-3">
          <div className="col-xxl-4 col-lg-6 col-md-4 col-sm-6">
            <div className="total-contain">
              <img
                src="/assets/images/svg/order.svg"
                className="img-1 blur-up lazyload"
                alt=""
              />
              <img
                src="/assets/images/svg/order.svg"
                className="blur-up lazyload"
                alt=""
              />
              <div className="total-detail">
                <h5>Total Order</h5>
                <h3>{orderCount}</h3>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-lg-6 col-md-4 col-sm-6">
            <div className="total-contain">
              <img
                src="/assets/images/svg/pending.svg"
                className="img-1 blur-up lazyload"
                alt=""
              />
              <img
                src="/assets/images/svg/pending.svg"
                className="blur-up lazyload"
                alt=""
              />
              <div className="total-detail">
                <h5>Total Pending Order</h5>
                <h3>{orderPendingCount}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-title">
        <h3>Account Information</h3>
      </div>
      <div className="row g-4">
        <div className="col-xxl-6">
          <div className="dashboard-content-title">
            <h4>
              Contact Information{" "}
              <button onClick={handleEditProfileModalOpen}>Edit</button>
            </h4>
          </div>
          <div className="dashboard-detail">
            {/* <h6 className="text-content">{name}</h6>
            <h6 className="text-content">{email}</h6>
            <h6 className="text-content">{phone}</h6>
            <h6 className="text-content">{address}</h6>
            <h6 className="text-content">{pincode}</h6> */}
            {/* <a href="javascript:void(0)">Change Password</a> */}

            <h6 className="text-content">
              <i
                className="fa-solid fa-user mr-2 dashboard-icons"></i>
              {name}
            </h6>
            <h6 className="text-content">
              <i
                className="fa-solid fa-envelope mr-2 dashboard-icons"></i>
              {email}
            </h6>
            <h6 className="text-content">
              <i
                className="fa-solid fa-phone mr-2 dashboard-icons"></i>
              {phone}
            </h6>
            <h6 className="text-content">
              <i
                className="fa-solid fa-location-dot mr-2 dashboard-icons"></i>{" "}
              {address}
            </h6>
            <h6 className="text-content">
              <i
                className="fa-solid fa-map-pin mr-2 dashboard-icons"></i>
              {pincode}
            </h6>
          </div>
        </div>

        <div className="col-xxl-6">
          <div className="dashboard-content-title">
            <h4>
              Allergens Information
              <button onClick={handleAllergentChooseOpen}>Edit</button>
            </h4>
          </div>
          {addressAllergentEdit && (
            <>
              <select
                className="js-example-basic-single w-100"
                id="multiSelect"
                multiple
                onChange={handleSelectChange}
                value={[]}
                style={{
                  minHeight: "50px",
                  padding: "15px",
                  borderRadius: "4px",
                  backgroundColor: "#d3f0e2",
                  color: "#333",
                  border: "1px solid #ccc",
                  fontSize: "16px",
                  transition: "all 0.3s ease",
                }}
              >
                <option value={""} style={{ color: "green" }}>
                  -- Select Allergent --
                </option>
                {options.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    style={{ color: "#4a5568" }}
                  >
                    {option.label}
                  </option>
                ))}
              </select>

              <div style={{ marginBottom: "10px" }} className="flex flex-1">
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyPress={handleInputKeyPress}
                  placeholder={"Type allergent and press Enter to add "}
                  className="w-auto form-control flex-1"
                />
              </div>
            </>
          )}

          <div className="flex flex-1 flex-col">
            <div className="flex flex-wrap flex-row m-1">
              {selectedOptions.map((option) => (
                <Chip
                  className="flex flex-row m-1 rounded-full bg-[#d3f0e2] text-[#008000] no-underline"
                  key={option.value}
                  value={option.label}
                  style={{
                    textDecoration: "none !important", // Ensures no underline or decoration
                    textTransform: "none",
                  }}
                  onClose={
                    addressAllergentEdit
                      ? () => handleDeleteChip(option.value)
                      : undefined
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="dashboard-address">
            <div className="dashboard-content-title">
              <h4>
                Address Book
                <button onClick={handleAddressChooseOpen}>Edit</button>
              </h4>
            </div>
            {defaultBillingAddress?.addressName ? (
              <>
                <h6
                  className="text-content dashboard-text-content"
                  style={{
                    marginLeft: "5px",
                    marginBottom: "8px",
                  }}
                >
                  Default Billing Address
                </h6>
                <div
                  className="col-xxl-6 col-xl-6 col-lg-6 col-md-6"
                  style={{ marginBottom: "8px" }}
                >
                  <div className="address-box">
                    <div>
                      <div className="label">
                        <label>{defaultBillingAddress?.addressName}</label>
                      </div>
                      <div className="table-responsive address-table">
                        <table className="table">
                          <tbody>
                            <tr>
                              <td colSpan={2}>
                                {defaultBillingAddress?.userName}
                              </td>
                            </tr>
                            <tr>
                              <td>Address :</td>
                              <td>
                                <p>{defaultBillingAddress?.userAddress}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>Pin Code :</td>
                              <td>{defaultBillingAddress?.pincode}</td>
                            </tr>
                            <tr>
                              <td>Phone :</td>
                              <td>{defaultBillingAddress?.phone}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h6
                className="text-content"
                style={{ marginLeft: "5px", marginBottom: "8px" }}
              >
                No Default Billing Address
              </h6>
            )}
            {defaultDeliveryAddress?.addressName ? (
              <>
                <h6
                  className="text-content dashboard-text-content"
                  style={{
                    marginLeft: "5px",
                    marginBottom: "8px",
                    marginTop: 20,
                  }}
                >
                  Default Delivery Address
                </h6>
                <div
                  className="col-xxl-6 col-xl-6 col-lg-6 col-md-6"
                  style={{ marginBottom: "15px" }}
                >
                  <div className="address-box">
                    <div>
                      <div className="label">
                        <label>{defaultDeliveryAddress?.addressName}</label>
                      </div>
                      <div className="table-responsive address-table">
                        <table className="table">
                          <tbody>
                            <tr>
                              <td colSpan={2}>
                                {defaultDeliveryAddress?.userName}
                              </td>
                            </tr>
                            <tr>
                              <td>Address :</td>
                              <td>
                                <p>{defaultDeliveryAddress?.userAddress}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>Pin Code :</td>
                              <td>{defaultDeliveryAddress?.pincode}</td>
                            </tr>
                            <tr>
                              <td>Phone :</td>
                              <td>{defaultDeliveryAddress?.phone}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h6
                className="text-content"
                style={{ marginLeft: "5px", marginBottom: "8px" }}
              >
                No Default Delivery Address
              </h6>
            )}

            {addressChooseEdit && (
              <div className="row g-4">
                <div className="col-xxl-12">
                  <div className="dashboard-detail">
                    <h6
                      className="text-content dashboard-text-content"
                      style={{ marginBottom: "8px"}}
                    >
                      Please choose your default billing address
                    </h6>
                    <div className="row g-sm-4 g-3">
                      {addressList &&
                        addressList.map((_address: any, index: any) => (
                          <div
                            className="col-xxl-6 col-xl-6 col-lg-6 col-md-6"
                            style={{ marginBottom: "15px" }}
                            key={_address?._id || index}
                          >
                            <div className="address-box">
                              <div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="billing"
                                    id={`billing-${index}`}
                                    defaultChecked={false}
                                    value={_address?._id}
                                    onClick={(e) =>
                                      handleBillingAddressClick(e)
                                    }
                                  />
                                </div>
                                <div className="label">
                                  <label>{_address?.addressName}</label>
                                </div>
                                <div className="table-responsive address-table">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <td colSpan={2}>
                                          {_address?.userName}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Address :</td>
                                        <td>
                                          <p>{_address?.userAddress}</p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Pin Code :</td>
                                        <td>{_address?.pincode}</td>
                                      </tr>
                                      <tr>
                                        <td>Phone :</td>
                                        <td>{_address?.phone}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="col-xxl-12">
                  <div className="dashboard-detail">
                    <h6
                      className="text-content dashboard-text-content"
                      style={{ marginBottom: "8px"}}
                    >
                      Please choose your default delivery address address
                    </h6>
                    <div className="row g-sm-4 g-3">
                      {addressList &&
                        addressList.map((_address: any, index: any) => (
                          <div
                            className="col-xxl-6 col-xl-6 col-lg-6 col-md-6"
                            style={{ marginBottom: "8px" }}
                            key={_address?._id || index}
                          >
                            <div className="address-box">
                              <div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="delivery"
                                    id={`delivery-${index}`}
                                    defaultChecked={false}
                                    value={_address?._id}
                                    onClick={(e) =>
                                      handleDeliveryAddressClick(e)
                                    }
                                  />
                                </div>
                                <div className="label">
                                  <label>{_address?.addressName}</label>
                                </div>
                                <div className="table-responsive address-table">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <td colSpan={2}>
                                          {_address?.userName}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Address :</td>
                                        <td>
                                          <p>{_address?.userAddress}</p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Pin Code :</td>
                                        <td>{_address?.pincode}</td>
                                      </tr>
                                      <tr>
                                        <td>Phone :</td>
                                        <td>{_address?.phone}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                {/* <div className="col-xxl-6">
              <div className="dashboard-detail">
                <h6 className="text-content">Default Shipping Address</h6>
                <h6 className="text-content">
                  You have not set a default shipping address.
                </h6>
                <a
                  href="javascript:void(0)"
                  data-bs-toggle="modal"
                  data-bs-target="#editProfile"
                >
                  Edit Address
                </a>
              </div>
            </div> */}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* modal start */}
      <Dialog
        open={openEditProfileModal}
        handler={handleEditProfileModalOpen}
        header={
          <div className="flex flex-row flex-1 justify-between">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Contact Details
            </h5>
            <button
              type="button"
              className="w-8 h-8 rounded-full text-red-500 hover:bg-red-500 hover:shadow-red-500 hover:shadow-md hover:text-white"
              onClick={handleEditProfileClose}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        }
        footer={
          <div className="flex flex-row">
            <button
              type="button"
              className="btn btn-secondary btn-md text-white mx-1"
              onClick={handleEditProfileClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn theme-bg-color btn-md text-white mx-1 bg-custom-primary-btn"
              onClick={handleEditProfileSave}
            >
              Save changes
            </button>
          </div>
        }
      >
        <div className="modal-body max-h-[450px] overflow-y-auto">
          <form>
            <div className="form-floating mb-4 theme-form-floating">
              <input
                type="text"
                className="form-control"
                id="fname"
                placeholder="Enter First Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor="fname">Name</label>
            </div>
          </form>

          <form>
            <div className="form-floating mb-4 theme-form-floating">
              <input
                type="text"
                className="form-control"
                id="lname"
                placeholder="Enter Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <label htmlFor="lname">Phone Number</label>
            </div>
          </form>

          <form>
            <div className="form-floating mb-4 theme-form-floating">
              <textarea
                className="form-control"
                placeholder="Leave a comment here"
                id="address"
                style={{ height: 100 }}
                defaultValue={""}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <label htmlFor="address">Enter Address</label>
            </div>
          </form>
          <form>
            <div className="form-floating mb-4 theme-form-floating">
              <input
                type="test"
                className="form-control"
                id="pin"
                placeholder="Enter address type"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
              <label htmlFor="pin">Pin Code</label>
            </div>
          </form>
        </div>
      </Dialog>
      {/* modal end */}
    </div>
  );
};

export default Insights;
