import axios from "axios";
import { SERVER_URL } from "../../constants";

export const createAddress = (data: any) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/address/create`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const addressUpdate = (addressId: String, data: any) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${SERVER_URL}/address/update/${addressId}`, { data })
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const addressDelete = (addressId: String) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${SERVER_URL}/address/delete/${addressId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const addressById = (addressId: String) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/address/get/${addressId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getAddressByCustomer = (customerId: any) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/address/get/by/customer/${customerId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getAddressData = (addressId: any) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/address/get/default/${addressId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getAddressList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/address/list`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
