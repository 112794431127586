import React, {useEffect} from "react";
import Footer from "./Footer";
import Header from "./Header";
import NavigationHeader from "./NavigationHeader";

type Props = {
  children: React.ReactNode;
};

const AuthLayout = (props: Props) => {
  useEffect(() => {
    const openBtn = document.getElementById("navbar-toggler");
    const closeBtn = document.getElementById("nav-close-btn");
    const nav = document.getElementById("mobile-nav-modal");

    const openModal = () => {
      if (nav) {
        nav.classList.remove("-translate-x-full");
      }
    };

    const closeModal = () => {
      if (nav) {
        nav.classList.add("-translate-x-full");
      }
    };

    if (openBtn) {
      openBtn.addEventListener("click", openModal);
    }

    if (closeBtn) {
      closeBtn.addEventListener("click", closeModal);
    }

    // Cleanup event listeners on unmount
    return () => {
      if (openBtn) {
        openBtn.removeEventListener("click", openModal);
      }
      if (closeBtn) {
        closeBtn.removeEventListener("click", closeModal);
      }
    };
  }, []);
  
  return (
    <>
      <Header />

      <NavigationHeader />

      {props.children}

      {/* Footer */}
      <Footer />
      <div className="theme-option">
        <div className="back-to-top">
          <a id="back-to-top" href="#">
            <i className="fas fa-chevron-up"></i>
          </a>
        </div>
      </div>

      <div className="bg-overlay"></div>
    </>
  );
};

export default AuthLayout;
