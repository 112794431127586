import React, { useState, useEffect } from "react";
import WebLayout from "../../layout/WebLayout";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import {
  addItem,
  removeItem,
  updateQuantity,
} from "../../lib/redux/reducers/customer/cart";

import { getProductListByCategories, getProductList } from "api/product";
import { getProductData } from "api/product";

import { getCouponDataByCouponCode } from "api/coupon";

import Snackbar from "components/Snackbar";

type Props = {};

const Cart = (props: Props) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const cartState = useAppSelector((state) => state.cartReducer);

  const customer = useAppSelector((state) => state.customerReducer);

  const [isEmpty, setIsEmpty] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [grandTotal, setGrandTotal] = useState<number>(0);

  const [categoryId, setCategoryId] = useState<any>();
  const [firstProduct, setFirstProduct] = useState<any>();
  const [similarProductList, setSimilarProductList] = useState<any>();

  const [couponCode, setCouponCode] = useState<any>();
  const [couponData, setCouponData] = useState<any>();

  const handleApplyCoupon = () => {
    getCouponDataByCouponCode(couponCode)
      .then((res) => {
        console.log("coupon data : ", res);
      })
      .catch((error) => {
        console.log("failed to get coupon data : ", error);
      });
  };

  const discount = 20.0;
  const shippingCost = 6.9;
  const tax = 29.498;

  useEffect(() => {
    if (cartState.items && cartState.items.length) {
      setIsEmpty(false);

      console.log("cart first item : ", cartState.items);
      if (cartState.items[0]?.product) {
        console.log(
          "cart first item : ",
          cartState.items[0]?.product?.category?.id
        );
        setFirstProduct(cartState.items[0]?.product);
        setCategoryId(cartState.items[0]?.product?.category?.id);
      }

      let totalPrice = 0;
      cartState.items.map((item) => {
        totalPrice += parseFloat(item.product.price) * item.quantity;
      });

      setGrandTotal(totalPrice - discount + shippingCost + tax);

      setTotal(totalPrice);
    } else {
      setIsEmpty(true);
    }

    return () => {};
  }, [cartState?.items]);

  const handleProduct = (productId: string) => {
    navigate(`/product?id=${productId}`);
  };

  const isItemExist = (itemId: string) => {
    if (cartState?.items && cartState.items.length) {
      const existingItem = cartState.items.find(
        (item) => item.product._id === itemId
      );
      if (existingItem) return existingItem;

      return false;
    }
    return false;
  };

  const handleAddItem = (item: any) => {
    console.log("product added : ", item);
    dispatch(
      updateQuantity({
        productId: item.product._id,
        quantity: item.quantity + 1,
      })
    );
  };

  const handleRemoveItem = (item: any) => {
    const isItem = isItemExist(item.product._id);

    if (isItem && isItem.quantity > 0) {
      dispatch(
        updateQuantity({
          productId: item.product._id,
          quantity: isItem.quantity - 1,
        })
      );
    }

    if (isItem && isItem.quantity === 1) {
      dispatch(removeItem(item.product._id));
    }
  };

  const handleDeleteItem = (itemId: string) => {
    dispatch(removeItem(itemId));
  };

  useEffect(() => {
    if (cartState.items) {
      setIsEmpty(!cartState.items.length);

      let totalPrice = 0;
      cartState.items.map((item) => {
        totalPrice += parseFloat(item.product.price) * item.quantity;
      });

      setTotal(Math.round(totalPrice));
    } else {
      setIsEmpty(true);
    }

    return () => {};
  }, [cartState?.items]);

  const handleCheckout = () => {
    if (customer?.c_user?.authStatus) {
      navigate(`/checkout`);
    } else {
      // navigate("/customer/login?r=checkout");
      navigate("/login?r=checkout");
    }
  };

  const returnShopping = () => {
    navigate(`/shop`);
  };

  // People also buy this

  useEffect(() => {
    if (categoryId) {
      getProductListByCategories([categoryId])
        .then((res: any) => {
          const list = res?.result;
          if (list && list.length) {
            const _filterList = list.filter(
              (item: any) => item?._id !== firstProduct?._id
            );
            if (_filterList) {
              setSimilarProductList(_filterList);
            }
          }
        })
        .catch((error: any) => {
          console.log("failed to get product data", error);
        });
    }
  }, [categoryId]);

  const handleAddItemToCart = (item: any) => {
    dispatch(addItem(item));
    handleSnackbar(
      true,
      "success",
      "Success !",
      "The item has been successfully added to your cart."
    );
  };

  const handleRemoveItemFromCart = (item: any) => {
    const isItem = isItemExist(item._id);

    if (isItem && isItem.quantity > 0) {
      dispatch(
        updateQuantity({
          productId: item._id,
          quantity: isItem.quantity - 1,
        })
      );
    }

    if (isItem && isItem.quantity === 1) {
      dispatch(removeItem(item._id));
    }
  };

  //snackbar
  const [openSnackbar, setOpenSnackbar] = useState<any>();
  const [type, setType] = useState<any>();
  const [heading, setHeading] = useState<any>();
  const [message, setMessage] = useState<any>();

  const hideSnackbar = () => {
    setTimeout(() => {
      setOpenSnackbar(false);
      setType("");
      setHeading("");
      setMessage("");
    }, 3000);
  };

  const handleSnackbar = (openSnackbar, type, heading, message) => {
    setOpenSnackbar(openSnackbar);
    setType(type);
    setMessage(message);
    setHeading(heading);
    hideSnackbar();
  };
  const isMobile = window.innerWidth < 768;

  return (
    <WebLayout>
      {openSnackbar && (
        <Snackbar type={type} heading={heading} message={message} />
      )}
      <>
        {/* <!-- Cart Section Start --> */}
        <section className="cart-section section-b-space pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
          <div className="container-fluid-lg">
            <div className="row g-sm-5 g-3">
              <div className="col-xxl-8">
                <div className="cart-table p-0 bg-transparent">
                  <div className="table-responsive-xl">
                    <table className="table">
                      <tbody>
                        {!isEmpty ? (
                          cartState.items.map((item) => (
                            <tr className="product-box-contain">
                              <td className="product-detail poppins-regular w-[80%]">
                                <div className="product border-0">
                                  <a
                                    href={`/product?id=${item?.product?._id}`}
                                    className="w-[25vw] md:w-auto max-w-[20%]"
                                  >
                                    <img
                                      src={item.product.images[0].location}
                                      className="img-fluid blur-up lazyload w-full !aspect-square object-cover rounded-[.75vw] w-full"
                                      alt=""
                                    />
                                  </a>
                                  <div className="product-detail max-w-[80%]">
                                    <ul>
                                      <li className="name poppins-regular">
                                        <p className="!text-[4.5vw] md:!text-[1.55vw] line-clamp-2 leading-[1] !whitespace-normal">
                                          {item.product.name}
                                        </p>
                                      </li>

                                      <li className="text-content poppins-regular">
                                        {/* {item?.product?.shortDescription} */}
                                      </li>

                                      <li className="text-content poppins-regular !flex !items-center !gap-[2vw]">
                                        <div>
                                          <span className="text-title">
                                            Weight
                                          </span>{" "}
                                          - {item?.product?.weight}{" "}
                                          {item?.product?.units}
                                        </div>
                                        <div>
                                          <span className="text-title">
                                            Unit Price:
                                          </span>{" "}
                                          -{" "}
                                          {/* <span className="!scale-[90%] relative">
                                        <span className="absolute w-full h-[1px] bg-black left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-6"></span>{" "}
                                        AED 55
                                      </span>{" "} */}
                                          AED {item.product.price}
                                        </div>
                                      </li>

                                      {/* <li>
                                        <h5 className="text-content d-inline-block poppins-regular">
                                          Price :
                                        </h5>
                                        <span className="poppins-regular">
                                          $35.10
                                        </span>
                                        <span className="text-content poppins-regular">
                                          $45.68
                                        </span>
                                      </li> */}

                                      {/* <li>
                                        <h5 className="saving theme-color poppins-regular">
                                          Saving : $20.68
                                        </h5>
                                      </li> */}

                                      <li className="quantity-price-box">
                                        <div className="cart_qty">
                                          <div className="input-group">
                                            <button
                                              type="button"
                                              className="btn qty-left-minus"
                                              data-type="minus"
                                              data-field=""
                                              onClick={() =>
                                                handleRemoveItem(item)
                                              }
                                            >
                                              <i className="fa fa-minus ms-0"></i>
                                            </button>
                                            <input
                                              className="form-control input-number qty-input"
                                              type="text"
                                              name="quantity"
                                              value={item.quantity}
                                            />
                                            <button
                                              type="button"
                                              className="btn qty-right-plus"
                                              data-type="plus"
                                              data-field=""
                                              onClick={() =>
                                                handleAddItem(item)
                                              }
                                            >
                                              <i className="fa fa-plus ms-0"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </li>

                                      <li>
                                        <h5>Total: $35.10</h5>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>

                              <td className="save-remove w-[20%] align-middle">
                                <div className="product-section">
                                  <div className="right-box-contain">
                                    <div className="price-rating">
                                      <h3 className="theme-color price poppins-regular text-left mt-[1.5vw] md:mt-0 !text-[3.5vw] md:!text-[1.25vw]">
                                        Total: AED{" "}
                                        {parseFloat(item.product.price) *
                                          item.quantity}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex !items-center gap-[.5vw] mt-[2vw] md:mt-[.5vw]">
                                  <p>Qty:</p>
                                  <div className="price-qty flex items-center justify-between">
                                    <div className="counter-number">
                                      <div className="counter">
                                        <div
                                          className="qty-left-minus"
                                          data-type="minus"
                                          data-field=""
                                          onClick={() => handleRemoveItem(item)}
                                        >
                                          <i className="fa-solid fa-minus"></i>
                                        </div>
                                        <input
                                          className="form-control input-number qty-input"
                                          type="text"
                                          name="quantity"
                                          value={item.quantity}
                                        />
                                        <div
                                          className="qty-right-plus"
                                          data-type="plus"
                                          data-field=""
                                          onClick={() => handleAddItem(item)}
                                        >
                                          <i className="fa-solid fa-plus"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="flex justify-end mt-[2vw] md:mt-[.5vw]"
                                  onClick={() =>
                                    handleDeleteItem(item.product._id)
                                  }
                                >
                                  <a
                                    className="remove close_button !text-[2.85vw] md:!text-[.85vw]"
                                    href="javascript:void(0)"
                                  >
                                    Remove
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="flex flex-1 p-2 items-center justify-center m-4">
                            <p className="text-black text-md">
                              No products have been added yet!
                            </p>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4">
                <div className="summery-box p-sticky">
                  <div className="summery-header">
                    <h3>Cart Total</h3>
                  </div>

                  <div className="summery-contain">
                    {/* <div className="coupon-cart">
                      <h6 className="text-content mb-2">Coupon Apply</h6>
                      <div className="mb-3 coupon-box input-group">
                        <input
                          type="email"
                          className="form-control !border-0"
                          id="exampleFormControlInput1"
                          placeholder="Enter Coupon Code Here..."
                          value={couponCode}
                          onChange={(e)=> setCouponCode(e.target.value)}
                        />
                        <button className="btn-apply" onClick={handleApplyCoupon}>Apply</button>
                      </div>
                    </div> */}
                    <ul>
                      <li>
                        <h4>Subtotal</h4>
                        <h4 className="price">AED {total.toFixed(2)}</h4>
                      </li>

                      <li>
                        <h4>Coupon Discount</h4>
                        <h4 className="price">(-) AED {discount}</h4>
                      </li>

                      <li>
                        <h4>Delivery Charge</h4>
                        <h4 className="price">AED {shippingCost}</h4>
                      </li>

                      <li className="align-items-start">
                        <h4>VAT</h4>
                        <h4 className="price text-end">AED {tax}</h4>
                      </li>
                    </ul>
                  </div>

                  <ul className="summery-total">
                    <li className="list-total border-top-0">
                      <h4>Total</h4>
                      <h4 className="price theme-color">
                        AED {grandTotal.toFixed(2)}
                      </h4>
                    </li>
                  </ul>

                  <div className="button-group cart-button">
                    <ul>
                      <li>
                        <button
                          onClick={handleCheckout}
                          className="btn proceed-btn fw-bold !bg-[#0FB16E] text-[#ffffff]"
                        >
                          Process To Checkout
                        </button>
                      </li>

                      <li>
                        <button
                          onClick={returnShopping}
                          className="btn btn-light shopping-button text-dark !shadow-none !bg-transparent"
                        >
                          <i className="fa-solid fa-arrow-left-long"></i>Return
                          To Shopping
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Cart Section End --> */}

        <section className="pb-[14vw] md:pb-[7vw] !pt-0">
          <div className="container-fluid-lg">
            <h2 className="text-[4.5vw] md:text-[1.95vw] !text-[#0FB16E]">
              Related Products
            </h2>

            <div className=" mt-[5vw] md:mt-[2vw]">
              <div className="row g-8">
                {similarProductList &&
                  similarProductList.slice(0, 4) &&
                  similarProductList
                    .slice(0, 4)
                    .map((item: any, index: number) => {
                      const isItem = isItemExist(item._id);
                      const itemCount = (isItem && isItem?.quantity) || 0;

                      return (
                        <div
                          className="col-lg-3 col-md-4 col wow fadeInUp !bg-white"
                          key={item?._id || index}
                        >
                          <div className="rounded-[2vw] md:rounded-[.5vw] border border-[#ececec]">
                            <div className="product-image">
                              <a
                                href={`/product?id=${item?._id}`}
                                className="w-full h-full flex rounded-t-[2vw] md:rounded-t-[.5vw] overflow-hidden"
                              >
                                <img
                                  src={item?.images[0]?.location}
                                  className="!w-full !h-full !object-cover m-0"
                                  alt=""
                                />
                              </a>
                            </div>

                            <div className="product-detail product-box-4 !border-0">
                              <div className={!isMobile ? "product-detail-name" : undefined}>
                                <a href={`/product?id=${item?._id}`}>
                                  <h5 className="name">Curried Potato & Heirloom Veggie Spread</h5>
                                </a>
                              </div>

                              <h5 className={!isMobile ? "price !text-[#0FB16E] product-detail-price" : "price !text-[#0FB16E]"}>
                                AED {item?.price}
                              </h5>
                              <div className="price-qty flex items-center justify-between mt-[3vw] md:mt-[1vw]">
                                <div className="counter-number">
                                  <div className="counter">
                                    <div
                                      className="qty-left-minus"
                                      data-type="minus"
                                      data-field=""
                                      onClick={() =>
                                        handleRemoveItemFromCart(item)
                                      }
                                    >
                                      <i className="fa-solid fa-minus"></i>
                                    </div>
                                    <input
                                      className="form-control input-number qty-input"
                                      type="text"
                                      name="quantity"
                                      value={itemCount}
                                    />
                                    <div
                                      className="qty-right-plus"
                                      data-type="plus"
                                      data-field=""
                                      onClick={() => handleAddItemToCart(item)}
                                    >
                                      <i className="fa-solid fa-plus"></i>
                                    </div>
                                  </div>
                                </div>

                                <button
                                  className="buy-button buy-button-2 btn btn-cart !bg-[#0FB16E] !w-[14vw] !h-[9vw] md:!w-[2vw] md:!h-[2.35vw] text-[5vw] md:text-[1.75vw]"
                                  onClick={() => handleAddItemToCart(item)}
                                >
                                  <i className="iconly-Buy icli text-white m-0"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </section>
      </>
    </WebLayout>
  );
};

export default Cart;
