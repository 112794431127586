import React, { useState, useEffect } from "react";
import WebLayout from "layout/WebLayout";
import { useNavigate, useLocation } from "react-router-dom";

import { getCategoryList } from "api/category";
import {
  getProductListByCategories,
  getProductList,
  getProductListByFilter,
} from "api/product";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  addItem,
  removeItem,
  updateQuantity,
} from "lib/redux/reducers/customer/cart";
import { comboboxSelectors } from "@udecode/plate-combobox";
import Dialog from "components/Dialog";

import Snackbar from "components/Snackbar";

import { c_updateFilterState } from "lib/redux/reducers/customer/filterSlice";

type Props = {};

interface Category {
  _id: string;
  // Other properties if any
}

const activeStyle = {
  color: "#fff",
  background:
    "linear-gradient(90.56deg, var(--theme-color1) 8.46%, var(--theme-color) 62.97%)",
  fontSize: "19px",
  fontWeight: 600,
};

const defaultStyle = {
  color: "#000",
  background: "#f7f6f3",
  fontSize: "16px",
  fontWeight: 400,
};

const Shop = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const cartState = useAppSelector((state) => state.cartReducer);

  const filterProduct = useAppSelector(
    (state) => state.filterReducer?.c_filter
  );

  const queryParams = new URLSearchParams(location.search);
  const categoryValue = queryParams.get("category");

  const [categoryList, setCategoryList] = useState<any>();

  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [productList, setProductList] = useState<any>();
  const [originalList, setOriginalList] = useState<any>(productList);

  useEffect(() => {
    let openBtn = document.getElementById("navbar-toggler") as HTMLElement | null;
    let filterBtn = document.getElementById("filter-btn") as HTMLElement | null;
    let closeBtn = document.getElementById("nav-close-btn") as HTMLElement | null;
    let nav = document.getElementById("mobile-nav-modal") as HTMLElement | null;
    let modalmodal = document.getElementById("mob-filter") as HTMLElement | null;
    let overlay = document.querySelector(".bg-overlay") as HTMLElement | null;

    openBtn.addEventListener("click", openModal);
    closeBtn.addEventListener("click", closeModal);
    filterBtn.addEventListener("click", openFilterModal);
    overlay.addEventListener("click", closeFilterModal);

    function openModal() {
      if (nav) {
        nav.classList.remove("-translate-x-full");
      }

    }

    function closeModal() {
      if (nav) {
        nav.classList.add("-translate-x-full");
      }

    }

    function openFilterModal() {
      if (modalmodal) {
        modalmodal.classList.remove("-translate-x-full");
      }
      if (overlay) {
        overlay.style.visibility = "visible";
        overlay.style.opacity = ".2";
      }
    }

  function closeFilterModal() {
    if (modalmodal) {
        modalmodal.classList.add("-translate-x-full");
    }
    if (overlay) {
        overlay.style.visibility = "hidden";
        overlay.style.opacity = "0";
    }
}
    getCategoryList()
      .then((res: any) => {
        setCategoryList(res?.result);
      })
      .catch((error) => {
        console.log("failed to get category list");
      });
    return () => {};
  }, []);

  const [categoryLimit, setCategoryLimit] = useState<any>(6);
  const [categoryExpand, setCategoryExpand] = useState<Boolean>(false);
  const [productLimit, setProductLimit] = useState<any>(9);
  const [productCount, setProductCount] = useState<any>(9);
  const isMobile = window.innerWidth < 768;

  const handleSeeMore = () => {
    setCategoryExpand(!categoryExpand);
  };

  useEffect(() => {
    if (categoryExpand) setCategoryLimit(categoryList.length);
    else setCategoryLimit(6);
    return () => {};
  }, [categoryExpand]);

  useEffect(() => {
    if (selectedCategories.length > 0) setProductLimit(productCount);
    else setProductLimit(9);
    return () => {};
  }, [selectedCategories]);

  useEffect(() => {
    if (categoryValue && categoryList) {
      const _filterCategory = categoryList.filter(
        (_category: any) => _category._id === categoryValue
      );
      setSelectedCategories(_filterCategory);
    }

    return () => {};
  }, [categoryValue, categoryList]);

  const handleCategorySelect = (category: any) => {
    if (
      !selectedCategories.some((_category) => _category._id === category._id)
    ) {
      if (selectedCategories && selectedCategories.length) {
        setSelectedCategories((prevState: any) => [...prevState, category]);
      } else {
        setSelectedCategories([category]);
      }
    } else {
      const items = selectedCategories.filter((item) => item !== category);
      setSelectedCategories(items);
    }
  };

  const handleCategoryClose = (category: any) => {
    if (selectedCategories && selectedCategories.length) {
      const _filterCategory = selectedCategories.filter(
        (_category) => _category._id !== category._id
      );
      setSelectedCategories(_filterCategory);
    }
  };

  useEffect(() => {
    if (selectedCategories && selectedCategories.length) {
      setProductList([]);

      const categoryIds: string[] = selectedCategories.map((cat) => cat._id);

      if (categoryIds) {
        getProductListByCategories(categoryIds)
          .then((res: any) => {       
            setOriginalList(res?.result);
            setProductList(res?.result);
            setProductCount(res?.result?.length);
          })
          .catch((error: any) => {
            console.log("failed to get product data", error);
          });
      }
    } else if (filterProduct?.productsList && filterProduct.applyFilter) {
      setProductLimit(filterProduct?.productsList.length);
      setProductList(filterProduct?.productsList);
      setOriginalList(filterProduct?.productsList);
      dispatch(
        c_updateFilterState({
          allergens: [],
          productsList: [],
          openFilterModal: false,
          applyFilter: false,
        })
      );
    } else {
      getProductList()
        .then((res: any) => {
          setProductList(res?.result);
          setOriginalList(res?.result);
        })
        .catch((error: any) => {
          console.log("failed to get product data", error);
        });
    }

    return () => {};
  }, [selectedCategories]);

  const handleProduct = (productId: string) => {
    navigate(`/product?id=${productId}`);
  };

  const handleCartClick = (item) => {
    // dispatch(addItem(item));
    navigate(`/cart`);
  };

  const isItemExist = (itemId: string) => {
    if (cartState?.items && cartState.items.length) {
      const existingItem = cartState.items.find(
        (item) => item.product._id === itemId
      );
      if (existingItem) return existingItem;

      return false;
    }
    return false;
  };

  const handleAddItem = (item: any) => {
    dispatch(addItem(item));
    handleSnackbar(
      true,
      "success",
      "Success !",
      "The item has been successfully added to your cart."
    );
  };

  const handleRemoveItem = (item: any) => {
    const isItem = isItemExist(item._id);

    if (isItem && isItem.quantity > 0) {
      dispatch(
        updateQuantity({
          productId: item._id,
          quantity: isItem.quantity - 1,
        })
      );
    }

    if (isItem && isItem.quantity === 1) {
      dispatch(removeItem(item._id));
    }
  };

  //snackbar
  const [openSnackbar, setOpenSnackbar] = useState<any>();
  const [type, setType] = useState<any>();
  const [heading, setHeading] = useState<any>();
  const [message, setMessage] = useState<any>();

  const hideSnackbar = () => {
    setTimeout(() => {
      setOpenSnackbar(false);
      setType("");
      setHeading("");
      setMessage("");
    }, 3000);
  };

  const handleSnackbar = (openSnackbar, type, heading, message) => {
    setOpenSnackbar(openSnackbar);
    setType(type);
    setMessage(message);
    setHeading(heading);
    hideSnackbar();
  };

  const handleFilter = () => {
    dispatch(
      c_updateFilterState({
        allergens: [],
        productsList: [],
        openFilterModal: true,
        applyFilter: false,
      })
    );
  };

  useEffect(() => {
    if (filterProduct) {
      setOpenModal(filterProduct?.openFilterModal);
    }

    return () => {};
  }, [filterProduct]);

  const [openModal, setOpenModal] = useState(false);

  // edit profile functions
  const handleModalOpen = () => {
    setOpenModal(!openModal);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(
      c_updateFilterState({
        allergens: selected,
        productsList: [],
        openFilterModal: false,
        applyFilter: true,
      })
    );
  };

  const [selected, setSelected] = useState<any>([]);
  const items = [
    "Milk",
    "Eggs",
    "Peanuts",
    "Tree nuts",
    "Soy",
    "Wheat",
    "Fish",
    "Shellfish",
    "Sesame",
  ];

  const handleChipClick = (item) => {
    if (selected.includes(item)) {
      setSelected(selected.filter((selectedItem) => selectedItem !== item));
    } else {
      setSelected([...selected, item]);
    }
  };

  const handleApplyFilter = () => {
    const filterProducts = selected;
    getProductListByFilter(filterProducts)
      .then((res: any) => {
        setProductLimit(res?.result.length);
        setProductList(res?.result);
        dispatch(
          c_updateFilterState({
            allergens: selected,
            productsList: [],
            openFilterModal: false,
            applyFilter: false,
          })
        );
        setOpenModal(false);
      })
      .catch((error: any) => {
        console.log("Failed to get product list : , error", error);
      });
  };

   // Function to handle the search
   const [searchTerm, setSearchTerm] = useState("");

   const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
     const value = event.target.value;
     setSearchTerm(value);
 
     if (value === "") {
      setProductList(originalList);
     } else {
       const filteredData = productList.filter(
         (item) =>
           item.name && item.name.toLowerCase().includes(value.toLowerCase())
       );
       setProductList(filteredData);
     }
   };

  return (
    <WebLayout>
      <>
        {openSnackbar && (
          <Snackbar type={type} heading={heading} message={message} />
        )}

        {/* Shop Section Start */}
        <section className="section-b-space shop-section">
          <img
            src="/assets/images/home-new/pattern.png"
            className="absolute inset-0 opacity-[.019] h-[90%] md:h-auto object-cover top-[0%] md:top-[3%] !z-0"
            alt="Image"
          />

          <div className="container-fluid-lg !pt-[12vw] !z-[1]">
            <div className="row">
              <div className="col-custom-3">
                <div className="left-box wow fadeInUp border-0">
                  <div className="shop-left-sidebar">
                    <div className="flex justify-center">
                      <div className="title !mx-auto">
                        <h2 className="text-[1.45vw] poppins-regular text-center w-max !text-[#0FB16E] !font-medium">
                          Shop Category
                        </h2>
                      </div>
                    </div>
                    <ul
                      className="nav nav-pills mb-3 custom-nav-tab"
                      id="pills-tab"
                      role="tablist"
                    >
                      {categoryList &&
                        categoryList.slice(0, 6) &&
                        categoryList
                          .slice(0, categoryLimit)
                          .map((item: any, index: number) => (
                            <li className="nav-item" role="presentation">
                              <button
                                className={
                                  selectedCategories.some(
                                    (_category) => _category._id === item._id
                                  )
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                style={
                                  selectedCategories.some(
                                    (_category) => _category._id === item._id
                                  )
                                    ? activeStyle
                                    : defaultStyle
                                }
                                id="pills-vegetables"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-vegetable"
                                type="button"
                                role="tab"
                                onClick={() => handleCategorySelect(item)}
                              >
                                {item?.name}{" "}
                                <img
                                  src={item?.image?.location}
                                  className="blur-up lazyload"
                                  alt=""
                                />
                              </button>
                            </li>
                          ))}
                      <button
                        className="flex items-center gap-[.5vw] justify-center w-full"
                        onClick={handleSeeMore}
                      >
                        <p>{categoryExpand ? "See less" : "See More"}</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 text-[#0FB16E]"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </button>
                    </ul>
                  </div>

                  <div
                    className="relative rounded-[.5vw] overflow-hidden text-white poppins-regular aspect-[16/20] !mt-[4vw]"
                    style={{
                      backgroundImage:
                        'url("/assets/images/home-new/banner.jpg")',
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      display: "block",
                    }}
                  >
                    <div className="p-center-left py-[1.5vw] px-[1vw] flex flex-col justify-between h-full">
                      <div>
                        <h2 className="!mb-0">LIMITED TIME OFFER</h2>
                        <h1 className="text-[5vw] md:text-[2vw] leading-[.9] font-regular mt-1 md:!mt-[.5vw]">
                          30% off for
                          <br /> your first order
                        </h1>
                      </div>

                      <div>
                        <button
                          // onClick={}
                          className="btn btn-2 text-white !bg-[#F15624] !px-[5vw] !py-[2.5vw] md:!py-[.55vw] md:!px-[1.75vw] !rounded-[2vw] !rounded-[2vw] md:!rounded-[.65vw]"
                        >
                          <strong>Avail Offer</strong>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="relative rounded-[.5vw] overflow-hidden text-white poppins-regular aspect-[16/20] !mt-[2vw]"
                    style={{
                      backgroundImage:
                        'url("/assets/images/home-new/banner.jpg")',
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      display: "block",
                    }}
                  >
                    <div className="p-center-left py-[1.5vw] px-[1vw] flex flex-col justify-between h-full">
                      <div>
                        <h2 className="!mb-0">LIMITED TIME OFFER</h2>
                        <h1 className="text-[5vw] md:text-[2vw] leading-[.9] font-regular mt-1 md:!mt-[.5vw]">
                          30% off for
                          <br /> your first order
                        </h1>
                      </div>

                      <div>
                        <button
                          // onClick={}
                          className="btn btn-2 text-white !bg-[#F15624] !px-[5vw] !py-[2.5vw] md:!py-[.55vw] md:!px-[1.75vw] !rounded-[2vw] !rounded-[2vw] md:!rounded-[.65vw]"
                        >
                          <strong>Avail Offer</strong>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-custom- mt-[10vw] md:mt-0">

                <div className="row-cols-xs-11">
                  <div className="show-button mt-[5vw] md:mt-0">
                    <div className="top-filter-menu">
                      <div className="grid-option d-md-block">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div
                            className="search-container"
                            style={{
                              display: "flex",
                              position: "relative" // Add position relative
                            }}
                          >
                          <input
                            type="search"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Search...."
                            value={searchTerm}
                            onChange={handleSearch}
                          />
                          <i
                            className="fas fa-search search-icon"
                            style={{
                              color: "#0da487",
                              position: "absolute", // Position the icon absolutely
                              right: "10px", // Adjust to position the icon inside the input
                              top: "50%",
                              transform: "translateY(-50%)", // Center vertically
                            }}
                          ></i>
                        </div>
                        <button
                          onClick={() => handleFilter()}
                          className="  btn  !bg-[#0FB16E] !w-[14vw] !h-[9vw] md:!w-[2vw] md:!h-[2.35vw] text-[5vw] md:text-[1.75vw]"
                          style={{ margin: 10 }}
                        >
                          <i
                            className="fas fa-sliders text-white m-0"
                            style={{ fontSize: "14px" }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>

                <div className="row g-sm-4 g-3 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-1 product-list-section">
                  {productList &&
                    productList.slice(0, productLimit) &&
                    productList.slice(0, productLimit).map((item: any) => {
                      const isItem = isItemExist(item._id);
                      const itemCount = (isItem && isItem?.quantity) || 0;
                      return (
                        <div className="col-lg-3 col-md-4 col wow fadeInUp !bg-white">
                          <div className="rounded-[2vw] md:rounded-[.5vw] border border-[#ececec]">
                            <div
                              className="product-image"
                              onClick={() => handleProduct(item?._id)}
                              style={{ cursor: "pointer !important" }}
                            >
                              <a
                                href={`/product?id=${item?._id}`}
                                className="w-full h-full flex rounded-t-[2vw] md:rounded-t-[.5vw] overflow-hidden"
                              >
                                <img
                                  src={item?.images[0]?.location}
                                  className="!w-full !h-full !object-cover m-0"
                                  alt=""
                                />
                              </a>
                            </div>

                            <div className="product-detail product-box-4 !border-0">
                              <div className={!isMobile ? "product-detail-name" : undefined}>
                                <a href={`/product?id=${item?._id}`}>
                                  <h5 className="name">{item?.name}</h5>
                                </a>
                              </div>
                              <h5 className={!isMobile ? "price !text-[#0FB16E] product-detail-price" : "price !text-[#0FB16E]"}>
                                AED {item?.price}
                              </h5>
                              <div className="price-qty flex items-center justify-between mt-[3vw] md:mt-[1vw]">
                                <div className="counter-number">
                                  <div className="counter">
                                    <div
                                      className="qty-left-minus"
                                      data-type="minus"
                                      data-field=""
                                      onClick={() => handleRemoveItem(item)}
                                    >
                                      <i className="fa-solid fa-minus"></i>
                                    </div>
                                    <input
                                      className="form-control input-number qty-input"
                                      type="text"
                                      name="quantity"
                                      value={itemCount}
                                    />
                                    <div
                                      className="qty-right-plus"
                                      data-type="plus"
                                      data-field=""
                                      onClick={() => handleAddItem(item)}
                                    >
                                      <i className="fa-solid fa-plus"></i>
                                    </div>
                                  </div>
                                </div>

                                <button
                                  onClick={() => handleCartClick(item)}
                                  className="buy-button buy-button-2 btn btn-cart !bg-[#0FB16E] !w-[14vw] !h-[9vw] md:!w-[2vw] md:!h-[2.35vw] text-[5vw] md:text-[1.75vw]"
                                >
                                  <i className="iconly-Buy icli text-white m-0"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Shop Section End */}

        {/* Modal Start */}
        <div
          id="mob-filter"
          className="fixed md:hidden top-0 left-0 bg-white h-screen w-max shop-section z-[999] duration-300 -translate-x-full"
        >
          <div className="left-box !relative !left-0">
            <div className="shop-left-sidebar">
              <ul
                className="nav nav-pills mb-3 custom-nav-tab"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-vegetables"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-vegetable"
                    type="button"
                    role="tab"
                  >
                    Vegetables & Fruit{" "}
                    <img
                      src="assets/svg/1/vegetable.svg"
                      className="blur-up lazyload"
                      alt=""
                    />
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-beverages"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-beverages"
                    type="button"
                    role="tab"
                  >
                    Beverages{" "}
                    <img
                      src="assets/svg/1/cup.svg"
                      className="blur-up lazyload"
                      alt=""
                    />
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-meat"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-meat"
                    type="button"
                    role="tab"
                  >
                    Meats & Seafood{" "}
                    <img
                      src="assets/svg/1/meats.svg"
                      alt=""
                      className="blur-up lazyload"
                    />
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-dairy"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-dairy"
                    type="button"
                    role="tab"
                  >
                    Breakfast & Dairy{" "}
                    <img
                      src="assets/svg/1/breakfast.svg"
                      className="blur-up lazyload"
                      alt=""
                    />
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-frozen"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-frozen"
                    type="button"
                    role="tab"
                  >
                    Frozen Foods{" "}
                    <img
                      src="assets/svg/1/frozen.svg"
                      className="blur-up lazyload"
                      alt=""
                    />
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-snack"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-snack"
                    type="button"
                    role="tab"
                  >
                    Biscuits & Snacks{" "}
                    <img
                      src="assets/svg/1/biscuit.svg"
                      className="blur-up lazyload"
                      alt=""
                    />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Modal End */}

        {selectedCategories && selectedCategories.length == 0 && (
          <>
            {/* Third Section Start */}
            <section className="!py-0 md:mt-[6.5vw] mt-[5vw]">
              <div className="">
                <div
                  className="home-contain h-100 !rounded-none"
                  style={{
                    backgroundImage:
                      'url("/assets/images/home-new/banner.jpg")',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    display: "block",
                  }}
                >
                  <div className="home-detail home-width p-center-left position-relative !pt-[16vw] !pb-[8vw] md:!pt-[10vw] md:!pb-[4vw]">
                    <div className="flex flex-col items-center w-full">
                      <h6 className="poppins-regular text-white !text-[5vw] md:!text-[2vw]">
                        TRY OUR NEW
                      </h6>
                      <h1 className="fw-bold text-[#ffffff] text-center !text-[8vw] md:!text-[4vw]">
                        Organic Honey Butter
                      </h1>
                      <h3 className="text-content fw-light text-[#ffffff] !text-[4vw] md:!text-[1.75vw]">
                        Perfect for Toast, Pancakes, or Biscuts
                      </h3>
                      <button
                        // onClick={}
                        className="btn mt-4 mt-sm-4 btn-2 text-white !bg-[#F15624] !px-[5vw] !py-[2.5vw] md:!py-[.85vw] md:!px-[2.15vw] !rounded-[2vw] !rounded-[2vw] md:!rounded-[.65vw]"
                      >
                        <strong>Order Now</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Third Section End */}

            {/* Second Section Start */}
            <section className="product-section py-[15vw] pb-[4vw] md:pt-[5vw] md:pb-[2vw] relative">
              <img
                src="/assets/images/home-new/pattern.png"
                className="absolute inset-0 opacity-[.019] h-[90%] md:h-auto object-cover top-[0%]"
                alt="Image"
              />
              <div className="container-fluid-lg">
                <div
                  className="tab-content mt-[2vw] relative"
                  id="myTabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="all"
                    role="tabpanel"
                  >
                    <div className="row g-8">
                      {productList &&
                        productList.slice(9, 17) &&
                        productList.slice(9, 17).map((item: any) => {
                          const isItem = isItemExist(item._id);
                          const itemCount = (isItem && isItem?.quantity) || 0;
                          return (
                            <div className="col-lg-3 col-md-4 col wow fadeInUp !bg-white">
                              <div className="rounded-[2vw] md:rounded-[.5vw] border border-[#ececec]">
                                <div
                                  className="product-image"
                                  onClick={() => handleProduct(item?._id)}
                                >
                                  <a
                                    href={`/product?id=${item?._id}`}
                                    className="w-full h-full flex rounded-t-[2vw] md:rounded-t-[.5vw] overflow-hidden"
                                  >
                                    <img
                                      src={item?.images[0]?.location}
                                      className="!w-full !h-full !object-cover m-0"
                                      alt=""
                                    />
                                  </a>
                                </div>

                                <div className="product-detail product-box-4 !border-0">
                                <div className={!isMobile ? "product-detail-name" : undefined}>
                                    <a href={`/product?id=${item?._id}`}>
                                      <h5 className="name">{item?.name}</h5>
                                    </a>
                                  </div>
                                  <h5 className={!isMobile ? "price !text-[#0FB16E] product-detail-price" : "price !text-[#0FB16E]"}>
                                    AED {item?.price}
                                  </h5>
                                  <div className="price-qty flex items-center justify-between mt-[3vw] md:mt-[1vw]">
                                    <div className="counter-number">
                                      <div className="counter">
                                        <div
                                          className="qty-left-minus"
                                          data-type="minus"
                                          data-field=""
                                          onClick={() => handleRemoveItem(item)}
                                        >
                                          <i className="fa-solid fa-minus"></i>
                                        </div>
                                        <input
                                          className="form-control input-number qty-input"
                                          type="text"
                                          name="quantity"
                                          value={itemCount}
                                        />
                                        <div
                                          className="qty-right-plus"
                                          data-type="plus"
                                          data-field=""
                                          onClick={() => handleAddItem(item)}
                                        >
                                          <i className="fa-solid fa-plus"></i>
                                        </div>
                                      </div>
                                    </div>

                                    <button
                                      onClick={() => handleCartClick(item)}
                                      className="buy-button buy-button-2 btn btn-cart !bg-[#0FB16E] !w-[14vw] !h-[9vw] md:!w-[2vw] md:!h-[2.35vw] text-[5vw] md:text-[1.75vw]"
                                    >
                                      <i className="iconly-Buy icli text-white m-0"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Second Section End */}

            {/* Third Section Start */}
            <section className="!py-0 md:mt-[6.5vw] mt-[5vw]">
              <div className="">
                <div
                  className="home-contain h-100 !rounded-none"
                  style={{
                    backgroundImage:
                      'url("/assets/images/home-new/banner.jpg")',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    display: "block",
                  }}
                >
                  <div className="home-detail home-width p-center-left position-relative !pt-[16vw] !pb-[8vw] md:!pt-[10vw] md:!pb-[4vw]">
                    <div className="flex flex-col items-center w-full">
                      <h6 className="poppins-regular text-white !text-[5vw] md:!text-[2vw]">
                        TRY OUR NEW
                      </h6>
                      <h1 className="fw-bold text-[#ffffff] text-center !text-[8vw] md:!text-[4vw]">
                        Organic Honey Butter
                      </h1>
                      <h3 className="text-content fw-light text-[#ffffff] !text-[4vw] md:!text-[1.75vw]">
                        Perfect for Toast, Pancakes, or Biscuts
                      </h3>
                      <button
                        // onClick={}
                        className="btn mt-4 mt-sm-4 btn-2 text-white !bg-[#F15624] !px-[5vw] !py-[2.5vw] md:!py-[.85vw] md:!px-[2.15vw] !rounded-[2vw] !rounded-[2vw] md:!rounded-[.65vw]"
                      >
                        <strong>Order Now</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Third Section End */}

            {/* Second Section Start  */}
            <section className="product-section py-[15vw] pb-[4vw] md:pt-[5vw] md:pb-[8vw] relative">
              <img
                src="/assets/images/home-new/pattern.png"
                className="absolute inset-0 opacity-[.019] h-[90%] md:h-auto object-cover top-[0%]"
                alt="Image"
              />
              <div className="container-fluid-lg">
                <div
                  className="tab-content mt-[2vw] relative"
                  id="myTabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="all"
                    role="tabpanel"
                  >
                    <div className="row g-8">
                      {productList &&
                        productList.slice(17, 24) &&
                        productList.slice(17, 24).map((item: any) => {
                          const isItem = isItemExist(item._id);
                          const itemCount = (isItem && isItem?.quantity) || 0;
                          return (
                            <div className="col-lg-3 col-md-4 col wow fadeInUp !bg-white">
                              <div className="rounded-[2vw] md:rounded-[.5vw] border border-[#ececec]">
                                <div
                                  className="product-image"
                                  onClick={() => handleProduct(item?._id)}
                                >
                                  <a
                                    href={`/product?id=${item?._id}`}
                                    className="w-full h-full flex rounded-t-[2vw] md:rounded-t-[.5vw] overflow-hidden"
                                  >
                                    <img
                                      src={item?.images[0]?.location}
                                      className="!w-full !h-full !object-cover m-0"
                                      alt=""
                                    />
                                  </a>
                                </div>

                                <div className="product-detail product-box-4 !border-0">
                                  <div className={!isMobile ? "product-detail-name" : undefined}>
                                    <a href={`/product?id=${item?._id}`}>
                                      <h5 className="name">{item?.name}</h5>
                                    </a>
                                  </div>
                                  <h5 className={!isMobile ? "price !text-[#0FB16E] product-detail-price" : "price !text-[#0FB16E]"}>
                                    AED {item?.price}
                                  </h5>
                                  <div className="price-qty flex items-center justify-between mt-[3vw] md:mt-[1vw]">
                                    <div className="counter-number">
                                      <div className="counter">
                                        <div
                                          className="qty-left-minus"
                                          data-type="minus"
                                          data-field=""
                                          onClick={() => handleRemoveItem(item)}
                                        >
                                          <i className="fa-solid fa-minus"></i>
                                        </div>
                                        <input
                                          className="form-control input-number qty-input"
                                          type="text"
                                          name="quantity"
                                          value={itemCount}
                                        />
                                        <div
                                          className="qty-right-plus"
                                          data-type="plus"
                                          data-field=""
                                          onClick={() => handleAddItem(item)}
                                        >
                                          <i className="fa-solid fa-plus"></i>
                                        </div>
                                      </div>
                                    </div>

                                    <button
                                      className="buy-button buy-button-2 btn btn-cart !bg-[#0FB16E] !w-[14vw] !h-[9vw] md:!w-[2vw] md:!h-[2.35vw] text-[5vw] md:text-[1.75vw]"
                                      onClick={() => handleCartClick(item)}
                                    >
                                      <i className="iconly-Buy icli text-white m-0"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    
                  </div>
                </div>
              </div>

              <Dialog
                open={openModal}
                handler={handleModalOpen}
                header={
                  <div className="flex flex-row flex-1 justify-between">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Filter Products
                    </h5>
                    <button
                      type="button"
                      className="w-8 h-8 rounded-full text-red-500 hover:bg-red-500 hover:shadow-red-500 hover:shadow-md hover:text-white"
                      onClick={handleCloseModal}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                }
              >
                <div className="modal-body">
                  <h4
                    className="poppins-regular text-left"
                    style={{ fontSize: "20px", marginBottom: "20px" }}
                  >
                    Would you like to filter products based ingredients?
                  </h4>
                  <h4
                    className="poppins-regular text-left"
                    style={{ fontSize: "15px", marginBottom: "20px" }}
                  >
                    Choose allergens you want to filter.
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {items.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => handleChipClick(item)}
                        style={{
                          display: "inline-block",
                          margin: "5px",
                          padding: "10px 20px",
                          boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                          borderRadius: "5px",
                          cursor: "pointer",
                          backgroundColor: selected.includes(item)
                            ? "#cbf5cd"
                            : "#ffcccb",
                          // color: selected.includes(item) ? "#ffffff" : "#000000",
                        }}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex items-center justify-end shrink-0 flex-wrap p-4 text-blue-gray-500">
                  <div className="flex flex-row">
                    <button
                      type="button"
                      className="btn text-white mx-1"
                      style={{
                        backgroundColor: "#ff6347",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                      }}
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn text-white mx-1"
                      style={{
                        backgroundColor: "#32cd32",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                      }}
                      onClick={handleApplyFilter}
                    >
                      Apply Filter
                    </button>
                  </div>
                </div>
              </Dialog>
            </section>
            {/* Second Section End  */}

            <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg p-3 flex justify-center filter-category-shop">
        <div className="show-button mt-[3vw] md:mt-0">
          <div
            id="filter-btn"
            className="filter-button d-inline-block d-lg-none relative !z-[10]"
          >
            <a>
              <i className="fa-solid fa-sliders"></i> Filter Category
            </a>
          </div>

          <div className="top-filter-menu shop-filter-menu">
            <div className="grid-option d-none d-md-block"></div>
          </div>
        </div>
    </div>
    
          </>
        )}
      </>
    </WebLayout>
  );
};

export default Shop;
