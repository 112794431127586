import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import AuthLayout from "layout/AuthLayout";
import WebLayout from "layout/WebLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "@material-tailwind/react";

import { customerLogin, findCustomerByEmail } from "api/customer";

import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "lib/redux/hooks";

import { c_updateUserState } from "lib/redux/reducers/customer/customerSlice";

type Props = {};

const ForgotPassword = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Get the value of the `q` query param
  const r = searchParams.get("r");

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      setErrorMsg("");
      findCustomerByEmail({ email: values?.email })
        .then((res: any) => {
          console.log('email find response : ', res);
          navigate(`/reset-password?email=${res?.data?.email}`);
        })
        .catch((error) => {
          console.log("failed to login customer: ", error);
        });
    },
  });

  return (
    <WebLayout>
      <>
        <section className="log-in-section background-image-2 section-b-space pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
          <div className="container-fluid-lg w-100">
            <div className="row">
              <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                <div className="image-contain">
                  <img
                    src="/assets/images/logo/login.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                <div className="log-in-box">
                  <div className="log-in-title">
                    <h3>Welcome To Hidden Health</h3>
                    <h4>Forgot Your Account</h4>
                  </div>

                  <div className="input-box">
                    <form className="row g-4" onSubmit={formik.handleSubmit}>
                      <div className="col-12">
                        <div className="form-floating theme-form-floating log-in-form">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Email Address"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={email}
                          />
                          <label htmlFor="email">Email Address</label>
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                          <div className="error-msg">{formik.errors.email}</div>
                        ) : null}
                      </div>

                      <div className="col-12">
                        <button
                          className="btn btn-animation w-100 justify-content-center"
                          type="submit"
                        >
                          Forgot Password
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="other-log-in">
                    <h6></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </WebLayout>
  );
};

export default ForgotPassword;
