import React from 'react'

type Props = {}

const Recipes = (props: Props) => {
  return (
    <div className="dashboard-address">
    <div className="title title-flex">
      <div>
        <h2>My Recipes</h2>
        <span className="title-leaf">
          <svg className="icon-width bg-gray">
            <use xlinkHref="../../assets/svg/leaf.svg#leaf" />
          </svg>
        </span>
      </div>
    </div>
    <div className="row g-sm-4 g-3">
      <div className="col-xxl-4 col-xl-6 col-lg-12 col-md-6 m-t-4">
        <div className="col-md-12">
          <div className="thumbnail-container recipie-img">
            <img
              src="https://springfeeling.s3.me-south-1.amazonaws.com/products/9d56b464-4c62-46d7-985a-95a3f4751825"
              className="thumnail-image"
              alt="Recipe 3"
            />
            <div className="thumbnail-overlay">
              <p>Explore Recipie</p>
            </div>
          </div>
        </div>
        <div className="address-box recipie-box">
          <div>
            <div className="table-responsive address-table">
              <table className="table">
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      Carrot Cake Bites, 2 Servings
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <span className="calorie-label">
                        100 cal
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      className="subscription-category"
                    >
                      Dairy Free,Gluten Free
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="button-group">
            <button
              className="btn btn-sm add-button w-100"
              data-bs-toggle="modal"
              data-bs-target="#readMoreRecipie"
            >
              <i data-feather="chevron-down" />
              Read More
            </button>
            <button
              className="btn btn-sm add-button w-100"
              data-bs-toggle="modal"
              data-bs-target="#removeProfile"
            >
              <i data-feather="trash-2" />
              Remove
            </button>
          </div>
        </div>
      </div>
      <div className="col-xxl-4 col-xl-6 col-lg-12 col-md-6 m-t-4">
        <div className="col-md-12">
          <div className="thumbnail-container recipie-img">
            <img
              src="https://springfeeling.s3.me-south-1.amazonaws.com/products/9d56b464-4c62-46d7-985a-95a3f4751825"
              className="thumnail-image"
              alt="Recipe 3"
            />
            <div className="thumbnail-overlay">
              <p>Explore Recipie</p>
            </div>
          </div>
        </div>
        <div className="address-box recipie-box">
          <div>
            <div className="table-responsive address-table">
              <table className="table">
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      Carrot Cake Bites, 2 Servings
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <span className="calorie-label">
                        100 cal
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      className="subscription-category"
                    >
                      Dairy Free,Gluten Free
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="button-group">
            <button
              className="btn btn-sm add-button w-100"
              data-bs-toggle="modal"
              data-bs-target="#readMoreRecipie"
            >
              <i data-feather="chevron-down" />
              Read More
            </button>
            <button
              className="btn btn-sm add-button w-100"
              data-bs-toggle="modal"
              data-bs-target="#removeProfile"
            >
              <i data-feather="trash-2" />
              Remove
            </button>
          </div>
        </div>
      </div>
      <div className="col-xxl-4 col-xl-6 col-lg-12 col-md-6 m-t-4">
        <div className="col-md-12">
          <div className="thumbnail-container recipie-img">
            <img
              src="https://springfeeling.s3.me-south-1.amazonaws.com/products/9d56b464-4c62-46d7-985a-95a3f4751825"
              className="thumnail-image"
              alt="Recipe 3"
            />
            <div className="thumbnail-overlay">
              <p>Explore Recipie</p>
            </div>
          </div>
        </div>
        <div className="address-box recipie-box">
          <div>
            <div className="table-responsive address-table">
              <table className="table">
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      Carrot Cake Bites, 2 Servings
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <span className="calorie-label">
                        100 cal
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      className="subscription-category"
                    >
                      Dairy Free,Gluten Free
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="button-group">
            <button
              className="btn btn-sm add-button w-100"
              data-bs-toggle="modal"
              data-bs-target="#readMoreRecipie"
            >
              <i data-feather="chevron-down" />
              Read More
            </button>
            <button
              className="btn btn-sm add-button w-100"
              data-bs-toggle="modal"
              data-bs-target="#removeProfile"
            >
              <i data-feather="trash-2" />
              Remove
            </button>
          </div>
        </div>
      </div>
      <div className="col-xxl-4 col-xl-6 col-lg-12 col-md-6 m-t-4">
        <div className="col-md-12">
          <div className="thumbnail-container recipie-img">
            <img
              src="https://springfeeling.s3.me-south-1.amazonaws.com/products/9d56b464-4c62-46d7-985a-95a3f4751825"
              className="thumnail-image"
              alt="Recipe 3"
            />
            <div className="thumbnail-overlay">
              <p>Explore Recipie</p>
            </div>
          </div>
        </div>
        <div className="address-box recipie-box">
          <div>
            {/* <div class="label">
                              <label>Home</label>
                          </div> */}
            <div className="table-responsive address-table">
              <table className="table">
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      Carrot Cake Bites, 2 Servings
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <span className="calorie-label">
                        100 cal
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      className="subscription-category"
                    >
                      Dairy Free,Gluten Free
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="button-group">
            <button
              className="btn btn-sm add-button w-100"
              data-bs-toggle="modal"
              data-bs-target="#readMoreRecipie"
            >
              <i data-feather="chevron-down" />
              Read More
            </button>
            <button
              className="btn btn-sm add-button w-100"
              data-bs-toggle="modal"
              data-bs-target="#removeProfile"
            >
              <i data-feather="trash-2" />
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Recipes