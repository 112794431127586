import React from "react";

type Props = {};

const Banner = (props: Props) => {
  return (
    <>
      {/* Third Section Start */}
      <section className="!py-0 md:mt-[6.5vw] mt-[5vw]">
        <div className="">
          <div
            className="home-contain h-100 !rounded-none"
            style={{
              backgroundImage: 'url("/assets/images/home-new/banner.jpg")',
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              display: "block",
            }}
          >
            <div className="home-detail home-width p-center-left position-relative !pt-[16vw] !pb-[8vw] md:!pt-[10vw] md:!pb-[4vw]">
              <div className="flex flex-col items-center w-full">
                <h6 className="poppins-regular text-white !text-[5vw] md:!text-[2vw]">
                  TRY OUR NEW
                </h6>
                <h1 className="fw-bold text-[#ffffff] text-center !text-[8vw] md:!text-[4vw]">
                  Organic Honey Butter
                </h1>
                <h3 className="text-content fw-light text-[#ffffff] !text-[4vw] md:!text-[1.75vw]">
                  Perfect for Toast, Pancakes, or Biscuts
                </h3>
                <button
                  // onClick={}
                  className="btn mt-4 mt-sm-4 btn-2 text-white !bg-[#F15624] !px-[5vw] !py-[2.5vw] md:!py-[.85vw] md:!px-[2.15vw] !rounded-[2vw] !rounded-[2vw] md:!rounded-[.65vw]"
                >
                  <strong>Order Now</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Third Section End */}
    </>
  );
};

export default Banner;
