import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import customerReducer from "./customerSlice";
import cartReducer from "./cart";
import checkoutReducer from "./checkoutSlice";
import filterReducer from "./filterSlice";

const customerPersistConfig = {
  key: "c_user",
  storage: storage,
  whitelist: ["c_user"],
};
const cartPersistConfig = {
  key: "cart",
  storage: storage,
  whitelist: ["cart"],
};

const checkoutPersistConfig = {
  key: "checkout",
  storage: storage,
  whitelist: ["checkout"],
};

const filterPersistConfig = {
  key: "c_filter",
  storage: storage,
  whitelist: ["c_filter"],
};

const customerReducers = {
  customerReducer: persistReducer(customerPersistConfig, customerReducer),
  cartReducer: persistReducer(cartPersistConfig, cartReducer),
  checkoutReducer: persistReducer(checkoutPersistConfig, checkoutReducer),
  filterReducer: persistReducer(filterPersistConfig, filterReducer),
};

export default customerReducers;
