import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Product {
  _id?: string;
  name?: string;
  price?: string;
  thumbImages?: any;
}

interface CartItem {
  product?: Product;
  quantity?: number;
}

type deliveryAddressType = {
  addressName?: string;
  userAddress?: string;
  pincode?: string | number;
  phone?: string | number;
};

interface Checkout {
  checkout?: {
    paymentIntentId?: string;
    items?: CartItem[];
    discount_coupon?: string;
    isShippingCost?: boolean;
    delivery_address?: deliveryAddressType;
    total?: number;
    grandTotal?: number;
    discount?: number;
    shippingCost?: number;
    tax?: number;
    couponCode?: string,
    paymentMode?: string;
    clientSecret?: string;
  };
}

const initialState: Checkout = {
  checkout: {
    paymentIntentId: "",
    items: [],
    discount_coupon: "",
    isShippingCost: false,
    delivery_address: {
      addressName: "",
      userAddress: "",
      pincode: "",
      phone: "",
    },
    total: 0,
    grandTotal: 0,
    discount: 0,
    shippingCost: 0,
    tax: 0,
    couponCode: "",
    paymentMode: "",
    clientSecret: "",
  },
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    c_updateCheckout: (
      state: any,
      action: PayloadAction<Checkout["checkout"]>
    ) => {
      state.checkout = action.payload;
    },
    c_updateCheckoutPaymentIntent: (
      state: any,
      action: PayloadAction<string>
    ) => {
      state.checkout.paymentIntentId = action.payload;
    },
    c_updateCheckoutClientSecret: (
      state: any,
      action: PayloadAction<string>
    ) => {
      state.checkout.clientSecret = action.payload;
    },
    c_resetCheckout: (state: any) => {
      state.checkout = {
        items: [],
        discount_coupon: "",
        isShippingCost: false,
        delivery_address: {
          addressName: "",
          userAddress: "",
          pincode: "",
          phone: "",
        },
        total: 0,
        grandTotal: 0,
        discount: 0,
        shippingCost: 0,
        tax: 0,
        couponCode: "",
        paymentMode: "",
      };
    },
  },
});

export const {
  c_updateCheckout,
  c_updateCheckoutPaymentIntent,
  c_updateCheckoutClientSecret,
  c_resetCheckout
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
