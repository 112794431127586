import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";

import { removeItem } from "../../lib/redux/reducers/customer/cart";
import { useNavigate } from "react-router-dom";

type Props = {};

const CartMenu = (props: Props) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const cartState = useAppSelector((state) => state.cartReducer);
  const customer = useAppSelector((state) => state.customerReducer);

  const [isEmpty, setIsEmpty] = useState(false);
  const [total, setTotal] = useState<number>(0);

  const handleRemoveItem = (itemId: string) => {
    dispatch(removeItem(itemId));
  };

  useEffect(() => {
    if (cartState?.items && cartState?.items.length) {
      console.log('cart : ', cartState?.items)
      setIsEmpty(!cartState.items.length);

      let totalPrice = 0;
      cartState.items.map((item) => {
        totalPrice += parseFloat(item.product.price) * item.quantity;
      });

      setTotal(totalPrice);
    } else {
      setIsEmpty(true);
    }

    return () => {};
  }, [cartState?.items]);

  const handleViewCart = () => {
    !isEmpty && navigate(`/cart`);
  };

  const handleCheckout = () => {
    if (!isEmpty) {
      if (customer?.c_user?.authStatus) {
        navigate(`/checkout`);
      } else {
        // navigate("/customer/login");
        navigate("/login");
      }
    }
  };

  return (
    <div className="onhover-div flex flex-col">
      <ul className="cart-list flex flex-col">
      
        {cartState?.items && cartState.items.length ? (
          cartState.items.map((item) => (
            <li
              className="product-box-contain flex flex-1 flex-col"
              key={item.product._id}
            >
              <div className="drop-cart">
                <a href="/product-left" className="drop-image">
                  {item?.product?.thumbImages && (
                    <img
                      src={item?.product?.thumbImages?.location}
                      className="blur-up lazyload rounded-md"
                      alt=""
                    />
                  )}
                </a>

                <div className="drop-contain flex flex-1">
                  <div className="flex flex-1 flex-col">
                    <a href="/product-left">
                      <h5>{item.product.name}</h5>
                    </a>
                    <h6>
                      <span>{item.quantity} x</span> {item.product.price}
                    </h6>
                  </div>
                  <button
                    className="w-5 h-5 rounded-full hover:bg-red-500 hover:text-white hover:shadow-md"
                    onClick={() => handleRemoveItem(item.product._id)}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </div>
              </div>
            </li>
          ))
        ) : (
          <div className="flex flex-1 p-2 items-center justify-center">
            <p className="text-black text-md">
              No products have been added yet!
            </p>
          </div>
        )}
      </ul>
      {!isEmpty && (
        <div className="price-box">
          <h5>Total :</h5>
          <h4 className="theme-color fw-bold">AED: {total.toFixed(2)}</h4>
        </div>
      )}
      <div className="button-group">
        <button
          onClick={handleViewCart}
          className={
            isEmpty
              ? "btn btn-sm cart-button opacity-35"
              : "btn btn-sm cart-button"
          }
          disabled={isEmpty}
        >
          View Cart
        </button>
        <button
          onClick={handleCheckout}
          className={
            isEmpty
              ? "btn btn-sm cart-button theme-bg-color text-white opacity-35"
              : "btn btn-sm cart-button theme-bg-color text-white"
          }
          disabled={isEmpty}
        >
          Checkout
        </button>
      </div>
    </div>
  );
};

export default CartMenu;
