import React, { useState, useEffect, useRef } from "react";
import { useAsyncValue, useNavigate } from "react-router-dom";
import SliderComponent from "components/Slider";
import { createBlog, getBlogList, blogByStatus } from "api/blog";

type Props = {};

const BlogList = (props: Props) => {
  const navigate = useNavigate();
  const [blogList, setBlogList] = useState<any>();

  useEffect(() => {
    blogByStatus({
      status: "approved",
    })
      .then((res: any) => {
        setBlogList(res?.result);
      })
      .catch((error) => {
        console.log("Failed to get blog list : ", error);
      });
    return () => {};
  }, []);

  const formatDate = (timestamp: string): string => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options).toUpperCase();
  };

  const readBlog = (blogId: any) => {
    navigate(`/blog-details?blogId=${blogId}`);
  };

  return (
    <>
      {/* Blogs Section Start */}
      <section className="md:!pt-[8vw] md:!pb-[8vw] !py-[18vw] w-full overflow-hidden">
        <div className="">
          <div className="title w-100 d-flex flex-column justify-content-center align-items-center">
            <h2 className="text-[8vw] md:text-[3vw] !text-center !font-normal !text-[#0FB16E]">
              Good reads!
            </h2>
            {/* <div class="w-[60%] mx-auto h-[1px] bg-[#F15624]/70 mt-[1.5vw]"></div> */}
          </div>
          <div className="row blog-section ">
            <div className="col-11 col-md-12 mx-auto">
              <div className="slider-4-half product-wrapper">
                <SliderComponent
                  dots={true}
                  slidesToShow={5}
                  autoplay={true}
                  autoplaySpeed={2500}
                >
                  {blogList &&
                    blogList.map((item: any, index: number) => (
                      <div className="!py-[6vw] !px-[5vw] md:!px-0 md:!py-[2vw]">
                        <div className="">
                          <div
                            className="blog-box wow fadeInUp shadow"
                            style={{
                              visibility: "visible",
                              animationName: "fadeInUp",
                            }}
                          >
                            <div className="blog-image">
                              <a
                                href={`/blog-details?blogId=${item?._id}`}
                                className="bg-siz blur-up lazyloaded"
                              >
                                <img
                                  src={item?.images?.location}
                                  className="aspect-[16/10] object-cover blur-up lazyloaded w-full h-full"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="blog-contain">
                              <a href={`/blog-details?blogId=${item?._id}`}>
                                <h3>{item?.title}</h3>
                              </a>
                              <div className="blog-label">
                                <span className="time">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-clock"
                                  >
                                    <circle cx={12} cy={12} r={10} />
                                    <polyline points="12 6 12 12 16 14" />
                                  </svg>{" "}
                                  <span>{formatDate(item?.createdAt)}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </SliderComponent>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Blogs Section Start */}
    </>
  );
};

export default BlogList;
