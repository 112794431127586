import React, { useState } from "react";
import AuthLayout from "../../layout/AuthLayout";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { createAuthUser } from "../../api/auth";
import { Spinner } from "@material-tailwind/react";

import Snackbar from "components/Snackbar";

type Props = {};

const Signup = (props: Props) => {
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      setErrorMsg("");

      createAuthUser(values)
        .then((res) => {
          setIsSubmitting(false);
          handleSnackbar(
            true,
            "success",
            "Success !",
            "Your account has been successfully created, Please Login"
          );
          navigate("/login");
        })
        .catch((error) => {
          setIsSubmitting(false);
          setErrorMsg("Failed to register, please try again");
          console.log("user creation failed : ", error);
        });
    },
  });

  //snackbar
  const [openSnackbar, setOpenSnackbar] = useState<any>();
  const [type, setType] = useState<any>();
  const [heading, setHeading] = useState<any>();
  const [message, setMessage] = useState<any>();

  const hideSnackbar = () => {
    setTimeout(() => {
      setOpenSnackbar(false);
      setType("");
      setHeading("");
      setMessage("");
    }, 3000);
  };

  const handleSnackbar = (openSnackbar, type, heading, message) => {
    setOpenSnackbar(openSnackbar);
    setType(type);
    setMessage(message);
    setHeading(heading);
    hideSnackbar();
  };

  return (
    <AuthLayout>
      {openSnackbar && (
        <Snackbar type={type} heading={heading} message={message} />
      )}

      {/* Breadcrumb Section Start */}
      <section className="breadcrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-contain">
                <h2>Sign Up</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/">
                        <i className="fa-solid fa-house"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item active">Sign Up</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  Breadcrumb Section End */}

      {/* log in section start */}
      <section className="log-in-section section-b-space">
        <div className="container-fluid-lg w-100">
          <div className="row">
            <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
              <div className="image-contain">
                <img
                  src="/assets/images/logo/login.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
              <div className="log-in-box">
                <div className="log-in-title">
                  <h3>Welcome To Hidden Health</h3>
                  <h4>Create New Account</h4>
                </div>

                <div className="input-box">
                  <form className="row g-4" onSubmit={formik.handleSubmit}>
                    <div className="col-12">
                      <div className="form-floating theme-form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Full Name"
                          onChange={(e) => {
                            setName(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={name}
                        />
                        <label htmlFor="fullname">Full Name</label>
                      </div>
                      {formik.touched.name && formik.errors.name ? (
                        <div className="error-msg">{formik.errors.name}</div>
                      ) : null}
                    </div>
                    <div className="col-12">
                      <div className="form-floating theme-form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Email Address"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={email}
                        />
                        <label htmlFor="email">Email Address</label>
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error-msg">{formik.errors.email}</div>
                      ) : null}
                    </div>

                    <div className="col-12">
                      <div className="form-floating theme-form-floating">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={password}
                        />
                        <label htmlFor="password">Password</label>
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="error-msg" style={{ color: "red" }}>
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-12">
                      <div className="forgot-box">
                        <div className="form-check ps-0 m-0 remember-box">
                          <input
                            className="checkbox_animated check-box"
                            type="checkbox"
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            I agree with
                            <span>Terms</span> and <span>Privacy</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="error-msg" style={{ color: "red" }}>
                        {errorMsg}
                      </div>
                      <button className="btn btn-animation w-100" type="submit">
                        {isSubmitting ? (
                          <>
                            {/* @ts-ignore */}
                            <Spinner color="green" />
                          </>
                        ) : (
                          "Sign Up"
                        )}
                      </button>
                    </div>
                  </form>
                </div>

                {/* <div className="other-log-in">
                            <h6>or</h6>
                        </div> */}

                {/*  <div className="log-in-button">
                            <ul>
                                <li>
                                    <a href="https://accounts.google.com/signin/v2/identifier?flowName=GlifWebSignIn&flowEntry=ServiceLogin"
                                        className="btn google-button w-100">
                                        <img src="/assets/images/inner-page/google.png" className="blur-up lazyload"
                                            alt="" />
                                        Sign up with Google
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/" className="btn google-button w-100">
                                        <img src="/assets/images/inner-page/facebook.png" className="blur-up lazyload"
                                            alt="" /> Sign up with Facebook
                                    </a>
                                </li>
                            </ul>
                        </div> */}

                <div className="other-log-in">
                  <h6></h6>
                </div>

                <div className="sign-up-box">
                  <h4>Already have an account?</h4>
                  <a href="/login">Log In</a>
                </div>
              </div>
            </div>

            <div className="col-xxl-7 col-xl-6 col-lg-6"></div>
          </div>
        </div>
      </section>
    </AuthLayout>
  );
};

export default Signup;
