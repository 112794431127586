import axios from "axios";
import { SERVER_URL } from "../../constants";

export const orderTrackCreate = (data: Object) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/order/track/create`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const orderTrackUpdate = (orderTrackId: String, data: any) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${SERVER_URL}/order/track/update/${orderTrackId}`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const orderTrackDelete = (orderTrackId: String) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${SERVER_URL}/order/track/delete/${orderTrackId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getOrderTrackData = (orderTrackId: String) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/order/track/get/${orderTrackId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getOrderTrackDataByOrderId = (orderId: String) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/order/track/get/id/${orderId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const orderTrackList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/order/list`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
