import React, { useState, useEffect } from "react";
import WebLayout from "../../layout/WebLayout";

import { createMessage } from "api/messages";
import Snackbar from "components/Snackbar";

type Props = {};

const Contact = (props: Props) => {
  const [firstName, setFirstName] = useState<any>();
  const [lastName, setLastName] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [phone, setPhone] = useState<any>();
  const [customerMessage, setCustomerMessage] = useState<any>();

  const handleMessageSend = async () => {
    const data = {
      firstName,
      lastName,
      email,
      phone,
      message: customerMessage,
    };
    console.log("data com : ", data);
    const result = await createMessage({
      firstName,
      lastName,
      email,
      phone,
      message: customerMessage,
    });
    console.log("result: ", result);
    if (result) {
      handleSnackbar(
        true,
        "success",
        "Success !",
        "Message has been successfully sent."
      );
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setCustomerMessage("");
    } else {
      handleSnackbar(
        true,
        "error",
        "Error !",
        "Message has been successfully sent."
      );
    }
  };

  //snackbar
  const [openSnackbar, setOpenSnackbar] = useState<any>();
  const [type, setType] = useState<any>();
  const [heading, setHeading] = useState<any>();
  const [message, setMessage] = useState<any>();

  const hideSnackbar = () => {
    setTimeout(() => {
      setOpenSnackbar(false);
      setType("");
      setHeading("");
      setMessage("");
    }, 3000);
  };

  const handleSnackbar = (openSnackbar, type, heading, message) => {
    setOpenSnackbar(openSnackbar);
    setType(type);
    setMessage(message);
    setHeading(heading);
    hideSnackbar();
  };

  return (
    <WebLayout>
      <>
        {openSnackbar && (
          <Snackbar type={type} heading={heading} message={message} />
        )}

        {/* <!-- Contact Box Section Start --> */}
        <section className="contact-box-section  section-b-space pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
          <div className="container-fluid-lg">
            <div className="row g-lg-5 g-3">
              <div className="col-lg-6">
                <div className="left-sidebar-box">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="contact-image">
                        <img
                          src="/assets/images/inner-page/contact-us.png"
                          className="img-fluid blur-up lazyloaded"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="contact-title">
                        <h3>Get In Touch</h3>
                      </div>

                      <div className="contact-detail">
                        <div className="row g-4">
                          

                          <div className="col-xxl-6 col-lg-12 col-sm-6">
                            <div className="contact-detail-box">
                              <div className="contact-icon">
                                <i className="fa-solid fa-envelope"></i>
                              </div>
                              <div className="contact-detail-title">
                                <h4>Email</h4>
                              </div>

                              <div className="contact-detail-contain">
                                <p>info@hidden-health.com</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-xxl-6 col-lg-12 col-sm-6">
                            <div className="contact-detail-box">
                              <div className="contact-icon">
                                <i className="fa-solid fa-building"></i>
                              </div>
                              <div className="contact-detail-title">
                                <h4>Address</h4>
                              </div>

                              <div className="contact-detail-contain">
                                <p>Bulding No7, Street 1, Discovery Gardens, Dubai - UAE</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="title d-xxl-none d-block">
                  <h2>Contact Us</h2>
                </div>
                <div className="right-sidebar-box">
                  <div className="row">
                    <div className="col-xxl-6 col-lg-12 col-sm-6">
                      <div className="mb-md-4 mb-3 custom-form">
                        <label className="form-label">First Name</label>
                        <div className="custom-input">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput"
                            placeholder="Enter First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          <i className="fa-solid fa-user"></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-6 col-lg-12 col-sm-6">
                      <div className="mb-md-4 mb-3 custom-form">
                        <label className="form-label">Last Name</label>
                        <div className="custom-input">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          <i className="fa-solid fa-user"></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-6 col-lg-12 col-sm-6">
                      <div className="mb-md-4 mb-3 custom-form">
                        <label className="form-label">Email Address</label>
                        <div className="custom-input">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput2"
                            placeholder="Enter Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <i className="fa-solid fa-envelope"></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-6 col-lg-12 col-sm-6">
                      <div className="mb-md-4 mb-3 custom-form">
                        <label className="form-label">Phone Number</label>
                        <div className="custom-input">
                          <input
                            type="number"
                            className="form-control"
                            id="exampleFormControlInput3"
                            placeholder="Enter Your Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          <i className="fa-solid fa-mobile-screen-button"></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-md-4 mb-3 custom-form">
                        <label className="form-label">Message</label>
                        <div className="custom-textarea">
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea"
                            placeholder="Enter Your Message"
                            rows={6}
                            value={customerMessage}
                            onChange={(e) => setCustomerMessage(e.target.value)}
                          ></textarea>
                          <i className="fa-solid fa-message"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-animation btn-md fw-bold ms-auto"
                    onClick={handleMessageSend}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Contact Box Section End --> */}

        {/* <!-- Map Section Start --> */}
        <section className="map-section">
          <div className="container-fluid p-0">
            <div className="map-box">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22103.475256560738!2d55.124120989979126!3d25.03820021309294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6cd623c3f253%3A0x1bdcdc11b4126442!2sJebel%20Ali%20Village%20-%20Discovery%20Gardens%20-%20Dubai%20-%20United%20Arab%20Emirates!5e1!3m2!1sen!2sin!4v1727969787295!5m2!1sen!2sin"
                style={{ border: 0 }}
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </section>
        {/* <!-- Map Section End --> */}
      </>
    </WebLayout>
  );
};

export default Contact;
