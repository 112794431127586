import axios from "axios";
import { SERVER_URL } from "../../constants";

export const uploadFile = (data: FormData) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/upload`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const deleteFile = (fileKey: String) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${SERVER_URL}/upload/delete/${fileKey}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
