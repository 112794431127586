import PaymentLayout from "layout/PaymentLayout";
import React, { useState, useEffect } from "react";

import { Spinner } from "@material-tailwind/react";

import Lottie from "lottie-react";

import PaymentSuccessAnimation from "./lottie/PaymentSuccess.json";
import PaymentFailedAnimation from "./lottie/PaymentFailed.json";

import { useSearchParams } from "react-router-dom";
import { getPaymentStatus } from "api/payment";

type Props = {};

const PaymentSuccess = (props: Props) => {
  // Initialize search params
  const [searchParams] = useSearchParams();

  // Get the 'ref' parameter from the query string
  const ref = searchParams.get("ref");

  const [status, setStatus] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [paymentData, setPaymentData] = useState<any>();

  useEffect(() => {
    if (ref) {
      setIsLoading(true);
      getPaymentStatus(ref)
        .then((result: any) => {
          setIsLoading(false);
          setPaymentData(result);
          if (
            result?._embedded?.payment &&
            result?._embedded?.payment[0]?.state === "PURCHASED"
          ) {
            setStatus("purchased");
          } else {
            setStatus("failed");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("Failed to get payment status: ", error);
        });
    }

    return () => {};
  }, [ref]);

  return (
    <PaymentLayout>
      <div className="flex flex-1 flex-col items-center h-[calc(100vh-250px)]">
        {isLoading ? (
          <Spinner
            className="h-12 w-12"
            color="blue"
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        ) : (
          <>
            <Lottie
              animationData={
                status === "purchased"
                  ? PaymentSuccessAnimation
                  : PaymentFailedAnimation
              }
              loop={false}
              style={{ width: 250, height: 250 }}
              onError={(error: any) => console.log("Lottie error: ", error)}
            />

            <span className="text-2xl font-extrabold text-black">
              {status === "purchased" ? "Payment completed" : "Payment Failed"}
            </span>

            <div className="my-2">
              <a
                href="/shop"
                className="text-black flex flex-row justify-center rounded-sm hover:shadow-xl px-8 py-2 my-4 text-green font-bold text-lg shadow-sm hover:shadow-md"
              >
                <i
                  data-feather="shopping-cart"
                  className="mx-2  text-black"
                ></i>
                Continue Shopping
              </a>
            </div>

            <div className="flex flex-col items-center mt-4">
              <span className="font-normal font-poppins text-black/[0.55] px-2 p-1">
                id:{" "}
                {paymentData?._embedded?.payment &&
                  paymentData?._embedded?.payment[0]?._id}
              </span>
              <span className="font-normal text-black/[0.55] px-2 p-1 font-poppins">
                reference no.:{" "}
                {paymentData?._embedded?.payment &&
                  paymentData?._embedded?.payment[0]?.reference}
              </span>
            </div>
          </>
        )}
      </div>
    </PaymentLayout>
  );
};

export default PaymentSuccess;
