import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import AuthLayout from "../../../layout/AuthLayout";
import WebLayout from "../../../layout/WebLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "@material-tailwind/react";

import { customerLogin } from "../../../api/customer";

import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../lib/redux/hooks";

import { c_updateUserState } from "../../../lib/redux/reducers/customer/customerSlice";

type Props = {};

const Login = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Get the value of the `q` query param
  const r = searchParams.get("r");

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      setErrorMsg("");
      customerLogin(values)
        .then((res: any) => {
          setIsSubmitting(false);
          setIsSubmitting(false);
          dispatch(
            c_updateUserState({
              ...res,
              authStatus: true,
              email: values?.email,
            })
          );
          if (r === "checkout") {
            navigate("/checkout");
          } else {
            navigate(`/customer-account?customerId=${res._id}`);
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setErrorMsg("Failed to login, please try again.");
          console.log("failed to login customer: ", error);
        });
    },
  });

  return (
    <WebLayout>
      <>
        <section className="log-in-section background-image-2 section-b-space pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
          <div className="container-fluid-lg w-100">
            <div className="row">
              <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                <div className="image-contain">
                  <img
                    src="/assets/images/logo/login.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                <div className="log-in-box">
                  <div className="log-in-title">
                    <h3>Welcome To Hidden Health</h3>
                    <h4>Log In Your Account</h4>
                  </div>

                  <div className="input-box">
                    <form className="row g-4" onSubmit={formik.handleSubmit}>
                      <div className="col-12">
                        <div className="form-floating theme-form-floating log-in-form">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Email Address"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={email}
                          />
                          <label htmlFor="email">Email Address</label>
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                          <div className="error-msg text-red-500 text-sm">{formik.errors.email}</div>
                        ) : null}
                      </div>

                      <div className="col-12">
                        <div className="form-floating theme-form-floating log-in-form">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={password}
                          />
                          <label htmlFor="password">Password</label>
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <div className="error-msg text-red-500 text-sm" >
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-12">
                        <div className="forgot-box">
                          <div className="form-check ps-0 m-0 remember-box">
                            <input
                              className="checkbox_animated check-box"
                              type="checkbox"
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Remember me
                            </label>
                          </div>
                          <a
                            href="/forgot-password"
                            className="forgot-password"
                          >
                            Forgot Password?
                          </a>
                        </div>
                      </div>
                      
                      <div className="col-12">
                      {errorMsg && (
                        <div className="m-1 text-red-500 text-sm">
                          {errorMsg}
                        </div>
                      )}
                        <button
                          className="btn btn-animation w-100 justify-content-center"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="other-log-in">
                    <h6></h6>
                  </div>

                  <div className="sign-up-box">
                    <h4>Don't have an account?</h4>
                    <a href="/register">Sign Up</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </WebLayout>
  );
};

export default Login;
