import React from "react";
import WebLayout from "../../layout/WebLayout";

type Props = {};

const Search = (props: Props) => {
  return (
    <WebLayout>
      <>
        {/* Breadcrumb Section Start */}
        <section className="breadcrumb-section pt-0">
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-contain">
                  <h2>Search</h2>
                  <nav>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <a href="/">
                          <i className="fa-solid fa-house" />
                        </a>
                      </li>
                      <li className="breadcrumb-item active">Search</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Breadcrumb Section End */}
        {/* Search Bar Section Start */}
        <section className="search-section">
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col-xxl-6 col-xl-8 mx-auto">
                <div className="title d-block text-center">
                  <h2>Search for products</h2>
                  <span className="title-leaf">
                    <svg className="icon-width">
                      <use xlinkHref="../assets/svg/leaf.svg#leaf" />
                    </svg>
                  </span>
                </div>
                <div className="search-box">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                    <button
                      className="btn theme-bg-color text-white m-0"
                      type="button"
                      id="button-addon1"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Search Bar Section End */}
        {/* Product Section Start */}
        <section className="section-b-space">
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col-12">
                <div className="search-product product-wrapper">
                  <div>
                    <div className="product-box-3 h-100">
                      <div className="product-header">
                        <div className="product-image">
                          <a href="product-left.htm">
                            <img
                              src="/assets/images/cake/product/11.png"
                              className="img-fluid blur-up lazyload"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                      <div className="product-footer">
                        <div className="product-detail">
                          <span className="span-name">Cake</span>
                          <a href="product-left-thumbnail.html">
                            <h5 className="name">
                              Chocolate Chip Cookies 250 g
                            </h5>
                          </a>
                          <h6 className="unit">500 G</h6>
                          <h5 className="price">
                            <span className="theme-color">AED 10.25</span>{" "}
                            <del>AED 12.57</del>
                          </h5>
                          <div className="add-to-cart-box bg-white">
                            <button className="btn btn-add-cart addcart-button">
                              Add
                              <span className="add-icon bg-light-gray">
                                <i className="fa-solid fa-plus" />
                              </span>
                            </button>
                            <div className="cart_qty qty-box">
                              <div className="input-group bg-white">
                                <button
                                  type="button"
                                  className="qty-left-minus bg-gray"
                                  data-type="minus"
                                  data-field=""
                                >
                                  <i className="fa fa-minus" />
                                </button>
                                <input
                                  className="form-control input-number qty-input"
                                  type="text"
                                  name="quantity"
                                  defaultValue={0}
                                />
                                <button
                                  type="button"
                                  className="qty-right-plus bg-gray"
                                  data-type="plus"
                                  data-field=""
                                >
                                  <i className="fa fa-plus" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="product-box-3 h-100">
                      <div className="product-header">
                        <div className="product-image">
                          <a href="product-left-thumbnail.html">
                            <img
                              src="/assets/images/cake/product/2.png"
                              className="img-fluid blur-up lazyload"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                      <div className="product-footer">
                        <div className="product-detail">
                          <span className="span-name">Vegetable</span>
                          <a href="product-left-thumbnail.html">
                            <h5 className="name">
                              Fresh Bread and Pastry Flour 200 g
                            </h5>
                          </a>
                          <h6 className="unit">250 ml</h6>
                          <h5 className="price">
                            <span className="theme-color">AED 08.02</span>{" "}
                            <del>AED 15.15</del>
                          </h5>
                          <div className="add-to-cart-box bg-white">
                            <button className="btn btn-add-cart addcart-button">
                              Add
                              <span className="add-icon bg-light-gray">
                                <i className="fa-solid fa-plus" />
                              </span>
                            </button>
                            <div className="cart_qty qty-box">
                              <div className="input-group bg-white">
                                <button
                                  type="button"
                                  className="qty-left-minus bg-gray"
                                  data-type="minus"
                                  data-field=""
                                >
                                  <i className="fa fa-minus" />
                                </button>
                                <input
                                  className="form-control input-number qty-input"
                                  type="text"
                                  name="quantity"
                                  defaultValue={0}
                                />
                                <button
                                  type="button"
                                  className="qty-right-plus bg-gray"
                                  data-type="plus"
                                  data-field=""
                                >
                                  <i className="fa fa-plus" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="product-box-3 h-100">
                      <div className="product-header">
                        <div className="product-image">
                          <a href="product-left-thumbnail.html">
                            <img
                              src="/assets/images/cake/product/3.png"
                              className="img-fluid blur-up lazyload"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                      <div className="product-footer">
                        <div className="product-detail">
                          <span className="span-name">Vegetable</span>
                          <a href="product-left-thumbnail.html">
                            <h5 className="name">
                              Peanut Butter Bite Premium Butter Cookies 600 g
                            </h5>
                          </a>
                          <h6 className="unit">350 G</h6>
                          <h5 className="price">
                            <span className="theme-color">AED 04.33</span>{" "}
                            <del>AED 10.36</del>
                          </h5>
                          <div className="add-to-cart-box bg-white">
                            <button className="btn btn-add-cart addcart-button">
                              Add
                              <span className="add-icon bg-light-gray">
                                <i className="fa-solid fa-plus" />
                              </span>
                            </button>
                            <div className="cart_qty qty-box">
                              <div className="input-group bg-white">
                                <button
                                  type="button"
                                  className="qty-left-minus bg-gray"
                                  data-type="minus"
                                  data-field=""
                                >
                                  <i className="fa fa-minus" />
                                </button>
                                <input
                                  className="form-control input-number qty-input"
                                  type="text"
                                  name="quantity"
                                  defaultValue={0}
                                />
                                <button
                                  type="button"
                                  className="qty-right-plus bg-gray"
                                  data-type="plus"
                                  data-field=""
                                >
                                  <i className="fa fa-plus" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="product-box-3 h-100">
                      <div className="product-header">
                        <div className="product-image">
                          <a href="product-left-thumbnail.html">
                            <img
                              src="/assets/images/cake/product/4.png"
                              className="img-fluid blur-up lazyload"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                      <div className="product-footer">
                        <div className="product-detail">
                          <span className="span-name">Snacks</span>
                          <a href="product-left-thumbnail.html">
                            <h5 className="name">
                              SnackAmor Combo Pack of Jowar Stick and Jowar
                              Chips
                            </h5>
                          </a>
                          <h6 className="unit">570 G</h6>
                          <h5 className="price">
                            <span className="theme-color">AED 12.52</span>{" "}
                            <del>AED 13.62</del>
                          </h5>
                          <div className="add-to-cart-box bg-white">
                            <button className="btn btn-add-cart addcart-button">
                              Add
                              <span className="add-icon bg-light-gray">
                                <i className="fa-solid fa-plus" />
                              </span>
                            </button>
                            <div className="cart_qty qty-box">
                              <div className="input-group bg-white">
                                <button
                                  type="button"
                                  className="qty-left-minus bg-gray"
                                  data-type="minus"
                                  data-field=""
                                >
                                  <i className="fa fa-minus" />
                                </button>
                                <input
                                  className="form-control input-number qty-input"
                                  type="text"
                                  name="quantity"
                                  defaultValue={0}
                                />
                                <button
                                  type="button"
                                  className="qty-right-plus bg-gray"
                                  data-type="plus"
                                  data-field=""
                                >
                                  <i className="fa fa-plus" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="product-box-3 h-100">
                      <div className="product-header">
                        <div className="product-image">
                          <a href="product-left-thumbnail.html">
                            <img
                              src="/assets/images/cake/product/5.png"
                              className="img-fluid blur-up lazyload"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                      <div className="product-footer">
                        <div className="product-detail">
                          <span className="span-name">Snacks</span>
                          <a href="product-left-thumbnail.html">
                            <h5 className="name">
                              Yumitos Chilli Sprinkled Potato Chips 100 g
                            </h5>
                          </a>
                          <h6 className="unit">100 G</h6>
                          <h5 className="price">
                            <span className="theme-color">AED 10.25</span>{" "}
                            <del>AED 12.36</del>
                          </h5>
                          <div className="add-to-cart-box bg-white">
                            <button className="btn btn-add-cart addcart-button">
                              Add
                              <span className="add-icon bg-light-gray">
                                <i className="fa-solid fa-plus" />
                              </span>
                            </button>
                            <div className="cart_qty qty-box">
                              <div className="input-group bg-white">
                                <button
                                  type="button"
                                  className="qty-left-minus bg-gray"
                                  data-type="minus"
                                  data-field=""
                                >
                                  <i className="fa fa-minus" />
                                </button>
                                <input
                                  className="form-control input-number qty-input"
                                  type="text"
                                  name="quantity"
                                  defaultValue={0}
                                />
                                <button
                                  type="button"
                                  className="qty-right-plus bg-gray"
                                  data-type="plus"
                                  data-field=""
                                >
                                  <i className="fa fa-plus" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="product-box-3 h-100">
                      <div className="product-header">
                        <div className="product-image">
                          <a href="product-left-thumbnail.html">
                            <img
                              src="/assets/images/cake/product/6.png"
                              className="img-fluid blur-up lazyload"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                      <div className="product-footer">
                        <div className="product-detail">
                          <span className="span-name">Vegetable</span>
                          <a href="product-left-thumbnail.html">
                            <h5 className="name">
                              Fantasy Crunchy Choco Chip Cookies
                            </h5>
                          </a>
                          <h6 className="unit">550 G</h6>
                          <h5 className="price">
                            <span className="theme-color">AED 14.25</span>{" "}
                            <del>AED 16.57</del>
                          </h5>
                          <div className="add-to-cart-box bg-white">
                            <button className="btn btn-add-cart addcart-button">
                              Add
                              <span className="add-icon bg-light-gray">
                                <i className="fa-solid fa-plus" />
                              </span>
                            </button>
                            <div className="cart_qty qty-box">
                              <div className="input-group bg-white">
                                <button
                                  type="button"
                                  className="qty-left-minus bg-gray"
                                  data-type="minus"
                                  data-field=""
                                >
                                  <i className="fa fa-minus" />
                                </button>
                                <input
                                  className="form-control input-number qty-input"
                                  type="text"
                                  name="quantity"
                                  defaultValue={0}
                                />
                                <button
                                  type="button"
                                  className="qty-right-plus bg-gray"
                                  data-type="plus"
                                  data-field=""
                                >
                                  <i className="fa fa-plus" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="product-box-3 h-100">
                      <div className="product-header">
                        <div className="product-image">
                          <a href="product-left-thumbnail.html">
                            <img
                              src="/assets/images/cake/product/7.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                      <div className="product-footer">
                        <div className="product-detail">
                          <span className="span-name">Vegetable</span>
                          <a href="product-left-thumbnail.html">
                            <h5 className="name">
                              Fresh Bread and Pastry Flour 200 g
                            </h5>
                          </a>
                          <h6 className="unit">1 Kg</h6>
                          <h5 className="price">
                            <span className="theme-color">AED 12.68</span>{" "}
                            <del>AED 14.69</del>
                          </h5>
                          <div className="add-to-cart-box bg-white">
                            <button className="btn btn-add-cart addcart-button">
                              Add
                              <span className="add-icon bg-light-gray">
                                <i className="fa-solid fa-plus" />
                              </span>
                            </button>
                            <div className="cart_qty qty-box">
                              <div className="input-group bg-white">
                                <button
                                  type="button"
                                  className="qty-left-minus bg-gray"
                                  data-type="minus"
                                  data-field=""
                                >
                                  <i className="fa fa-minus" />
                                </button>
                                <input
                                  className="form-control input-number qty-input"
                                  type="text"
                                  name="quantity"
                                  defaultValue={0}
                                />
                                <button
                                  type="button"
                                  className="qty-right-plus bg-gray"
                                  data-type="plus"
                                  data-field=""
                                >
                                  <i className="fa fa-plus" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Product Section End */}
      </>
    </WebLayout>
  );
};

export default Search;
