import axios from "axios";
import { SERVER_URL } from "../../constants";

type couponType = {
  title: string | undefined;
  code: string| undefined;
  description: string | undefined;
  offerPercentage: number | undefined;
  status: boolean | undefined;
  numberOfUse: string | undefined;
  expiryDays: string| undefined;

};

export const createCoupon = (data: couponType) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/coupon/create`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const couponUpdate = (couponId: any, data: any) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${SERVER_URL}/coupon/update/${couponId}`, { data })
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const couponDelete = (couponId: String) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${SERVER_URL}/coupon/delete/${couponId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const couponById = (couponId: String) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/coupon/get/${couponId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

  export const getCouponDataByCouponCode = (couponId: String) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${SERVER_URL}/coupon/get/code/${couponId}`)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error));
    });

export const getCouponList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/coupon/list`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
