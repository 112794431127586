import React, { useState, useEffect } from "react";

interface SnackbarProps {
  type: string;
  heading: string;
  message: string;
}

const Snackbar: React.FC<SnackbarProps> = ({ type, heading, message }) => {
  return (
    <div className={"snackbar-bar-box"}>
      <div className="snackbar-box">
        <div className="snackbar-image">
          {type === "success" ? (
            <>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="green"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <h1
                className="text-content"
                style={{
                  color: "green",
                  marginLeft: "5px",
                }}
              >
                {heading}
              </h1>
            </>
          ) : (
            <>
              <>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="red"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="red"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <h1
                  className="text-content"
                  style={{
                    color: "red",
                    marginLeft: "5px",
                  }}
                >
                  {heading}
                </h1>
              </>
            </>
          )}
        </div>

        <div className="snackbar-contain">
          <h5
            className="text-content"
            style={{ color: type === "success" ? "green" : "red" }}
          >
            {message}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Snackbar;
