import React, { useState, useEffect } from "react";
import Dialog from "components/Dialog";
import { useParams } from "react-router-dom";
import {
  createAddress,
  getAddressByCustomer,
  addressDelete,
  addressUpdate,
} from "api/address";

import { useAppDispatch, useAppSelector } from "lib/redux/hooks";

type Props = {};

const Address = (props: Props) => {
  const customer = useAppSelector((state) => state.customerReducer?.c_user);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const [name, setName] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [phone, setPhone] = useState<any>();
  const [pincode, setPincode] = useState<any>();
  const [addressType, setAddressType] = useState<any>();

  const [addressList, setAddressList] = useState<any>();

  const resetForm = () => {
    setName("");
    setEmail("");
    setAddress("");
    setPhone("");
    setPincode("");
    setAddressType("");
  };

  const fetchAddressList = async () => {
    getAddressByCustomer(customer?._id)
      .then((res) => {
        setAddressList(res?.result);
      })
      .catch((error) => {
        console.log("Failed to get address Result : ", error);
      });
  };

  useEffect(() => {
    if (customer?._id) {
      fetchAddressList();
    }
    return () => {};
  }, [customer?._id]);

  const [editAddressId, setEditAddressId] = useState<any>();

  const handleSaveAddress = async () => {
    try {
      if (!customer?._id || !name || !email || !address || !phone || !pincode) {
        return false;
      }

      const data = {
        customerId: customer?._id,
        userName: name,
        addressName: addressType,
        userEmail: email,
        userAddress: address,
        pincode,
        phone,
      };

      if (editAddressId) {
        const result = await addressUpdate(editAddressId, data);
      } else {
        const result = await createAddress(data);
      }

      await fetchAddressList();
      handleCreateModal();
      // resets fields
      resetForm();
    } catch (error) {
      console.log("failed to create address", error);
    }
  };

  // address delete
  const [openDeleteAddressModal, setOpenDeleteAddressModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState<any>();

  const handleDeleteAddressModalOpen = () => {
    setOpenDeleteAddressModal(!openDeleteAddressModal);
  };

  const handleDeleteAddressClose = () => {
    handleDeleteAddressModalOpen();
    setDeleteItem("");
  };

  const handleRemoveClick = (item: any) => {
    setDeleteItem(item);
    handleDeleteAddressModalOpen();
  };

  const handleDeleteAddress = () => {
    addressDelete(deleteItem?._id)
      .then((res) => {
        fetchAddressList();
        handleDeleteAddressModalOpen();
      })
      .catch((error) => {
        console.log("Failed to delete address : ", error);
      });
  };

  const handleEditAddress = (_address: any) => {
    setOpenCreateModal(true);
    setEditAddressId(_address?._id);
    setName(_address?.userName);
    setEmail(_address?.userEmail);
    setAddress(_address?.userAddress);
    setPhone(_address?.phone);
    setPincode(_address?.pincode);
    setAddressType(_address?.addressName);
  };

  const handleCreateModal = () => {
    setEditAddressId("");
    setOpenCreateModal(!openCreateModal);
  };

  return (
    <div className="dashboard-address">
      <div className="title title-flex">
        <div>
          <h2>My Address Book</h2>
          <span className="title-leaf">
            <svg className="icon-width bg-gray">
              <use xlinkHref="../assets/svg/leaf.svg#leaf" />
            </svg>
          </span>
        </div>
        <button
          className="btn theme-bg-color text-white btn-sm fw-bold mt-lg-0 mt-3"
          onClick={handleCreateModal}
        >
          <i data-feather="plus" className="me-2" /> Add New Address
        </button>
      </div>
      <div className="row g-sm-4 g-3">
        {addressList &&
          addressList.map((_address: any, index: number) => (
            <div
              className="col-xxl-4 col-xl-6 col-lg-12 col-md-6"
              key={_address?._id || index}
            >
              <div className="address-box">
                <div>
                  <div className="label">
                    <label>{_address?.addressName}</label>
                  </div>
                  <div className="table-responsive address-table">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td colSpan={2}>{_address?.userName}</td>
                        </tr>
                        <tr>
                          <td>Address :</td>
                          <td>
                            <p>{_address?.userAddress}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>Pin Code :</td>
                          <td>{_address?.pincode}</td>
                        </tr>
                        <tr>
                          <td>Phone :</td>
                          <td>{_address?.phone}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="button-group">
                  <button
                    className="btn btn-sm add-button w-100"
                    onClick={() => handleEditAddress(_address)}
                  >
                    <i data-feather="edit" />
                    Edit
                  </button>
                  <button
                    className="btn btn-sm w-100 bg-red-500"
                    onClick={() => handleRemoveClick(_address)}
                  >
                    <i data-feather="trash-2" />
                    Remove
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* modal start */}
      <Dialog
        open={openCreateModal}
        handler={handleCreateModal}
        header={
          <div className="flex flex-row flex-1 justify-between">
            <h5 className="modal-title" id="exampleModalLabel">
              Add a new address
            </h5>
            <button
              type="button"
              className="w-8 h-8 rounded-full text-gray-500 hover:bg-gray-200 hover:text-gray-800 flex items-center justify-center"
              onClick={handleCreateModal}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        }
        footer={
          <div className="flex flex-row">
            <button
              type="button"
              className="btn theme-bg-color btn-md text-white mx-1 bg-custom-secondary-btn"
              onClick={handleCreateModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn theme-bg-color btn-md text-white mx-1 bg-custom-primary-btn"
              onClick={handleSaveAddress}
            >
              Save changes
            </button>
          </div>
        }
      >
        <div className="modal-body max-h-[550px] overflow-y-auto">
          <form>
            <div className="form-floating mb-4 theme-form-floating">
              <input
                type="text"
                className="form-control text-content"
                id="fname"
                placeholder="Enter First Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor="fname">Name</label>
            </div>
          </form>

          <form>
            <div className="form-floating mb-4 theme-form-floating">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="email">Email Address</label>
            </div>
          </form>
          <form>
            <div className="form-floating mb-4 theme-form-floating">
              <input
                type="text"
                className="form-control"
                id="lname"
                placeholder="Enter Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <label htmlFor="lname">Phone Number</label>
            </div>
          </form>
          <form>
            <div className="form-floating mb-4 theme-form-floating">
              <input
                type="test"
                className="form-control"
                id="pin"
                placeholder="Enter address type"
                value={addressType}
                onChange={(e) => setAddressType(e.target.value)}
              />
              <label htmlFor="pin">Address Type</label>
            </div>
          </form>
          <form>
            <div className="form-floating mb-4 theme-form-floating">
              <textarea
                className="form-control"
                placeholder="Leave a comment here"
                id="address"
                style={{ height: 100 }}
                defaultValue={""}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <label htmlFor="address">Enter Address</label>
            </div>
          </form>
          <form>
            <div className="form-floating mb-4 theme-form-floating">
              <input
                type="test"
                className="form-control"
                id="pin"
                placeholder="Enter address type"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
              <label htmlFor="pin">Pin Code</label>
            </div>
          </form>
        </div>
      </Dialog>
      {/* modal end */}

      {/* delete address start */}
      <Dialog
        open={openDeleteAddressModal}
        handler={handleDeleteAddressModalOpen}
        header={
          <div className="modal-header flex flex-1 justify-between">
            <h5 className="modal-title" id="exampleModalLabel">
              Delete Address
            </h5>
            <button type="button" onClick={handleDeleteAddressClose}>
              <i className="fa-solid fa-xmark" />
            </button>
          </div>
        }
        footer={
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-md"
              onClick={handleDeleteAddressClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn theme-bg-color btn-md text-white"
              onClick={handleDeleteAddress}
            >
              Yes, Delete this address
            </button>
          </div>
        }
      >
        <p style={{ fontSize: 16, fontWeight: 600, marginLeft: 10 }}>
          Do you want to delete {deleteItem && deleteItem?.addressName.substring(0, 25)}{" "}
          address?{" "}
        </p>
      </Dialog>
      {/* delete address end */}
    </div>
  );
};

export default Address;
