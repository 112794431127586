import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Filter {
  allergens: [];
  productsList: [];
  openFilterModal: boolean;
  applyFilter: boolean;
}

interface FilterState {
  c_filter: Filter;
}

const initialState: FilterState = {
  c_filter: {
    allergens: [],
    productsList: [],
    openFilterModal: true,
    applyFilter: false,
  },
};

export const filterSlice = createSlice({
  name: "c_filter",
  initialState,
  reducers: {
    c_updateFilterState: (state, action: PayloadAction<Filter>) => {
      state.c_filter = action.payload;
    },
    c_resetFilter: (state) => {
      state.c_filter = initialState.c_filter;
    },
  },
});

export const { c_updateFilterState, c_resetFilter } = filterSlice.actions;

export default filterSlice.reducer;
