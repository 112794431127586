import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WebLayout from "layout/WebLayout";

import { getCustomerData, customerUpdate } from "api/customer";
import { uploadFile } from "api/upload";
import Address from "./Address";
import SavedCards from "./SavedCards";
import Profile from "./Profile";
import Recipes from "./Recipes";
import Blogs from "./Blogs";
import Privacy from "./Privacy";
import Orders from "./Orders";
import Insights from "./Insights";
import { c_resetUser } from "lib/redux/reducers/customer/customerSlice";
import { useAppDispatch } from "lib/redux/hooks";

type Props = {};

const Dashboard = (props: Props) => {
  // const { customerId } = useParams();
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const customerId = params.get("customerId");

  const [openMenu, setOpenMenu] = useState(false);

  const dispatch = useAppDispatch();

  const [dataUpdated, setDataUpdated] = useState<boolean>(false);

  const handleProfileChange = (value: boolean) => {
    setDataUpdated(value);
  };

  const [customer, setCustomer] = useState<any>();
  const [profileImage, setProfileImage] = useState<any>();

  useEffect(() => {
    if (customerId) {
      getCustomerData(customerId)
        .then((res: any) => {
          setDataUpdated(false);
          setCustomer(res?.result);
          setProfileImage(res?.result?.profile_photo);
        })
        .catch((error) => {
          console.log("failed to fetch customer datails : ", error);
        });
      return () => {};
    }
  }, [customerId, dataUpdated]);

  const updateUserProfile = (data: any) => {
    if (customerId) {
      customerUpdate(customerId, data)
        .then((res: any) => {
          setCustomer(res?.result);
        })
        .catch((error) => {
          console.log("failed to update customer datails : ", error);
        });
    }
  };

  const handleProfileImageUpload = async (e: any) => {
    const files = Array.from(e.target.files);
    const imageFormData = new FormData();
    files.forEach((image: any) => {
      imageFormData.append(`file`, image);
    });
    const imagesUploadResult: any = await uploadFile(imageFormData);
    setProfileImage(imagesUploadResult.files[0]);
    const data = {
      profile_photo: imagesUploadResult.files[0],
    };
    updateUserProfile(data);
  };

  const handleLogout = () => {
    dispatch(c_resetUser());
  };

  return (
    <WebLayout>
      <>
        {/* User Dashboard Section Start */}
        <section className="user-dashboard-section section-b-space pt-[35vw] md:pt-[14vw] pb-[14vw] md:pb-[7vw]">
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col-xxl-3 col-lg-4">
                <div
                  className={`dashboard-left-sidebar${
                    openMenu ? " show " : ""
                  }`}
                >
                  <div className="close-button d-flex d-lg-none">
                    <button
                      className="close-sidebar"
                      onClick={() => setOpenMenu(false)}
                    >
                      <i className="fa-solid fa-xmark" />
                    </button>
                  </div>
                  <div className="profile-box">
                    <div className="cover-image">
                      <img
                        src="/assets/images/inner-page/background-cover.jpg"
                        className="img-fluid blur-up lazyload"
                        alt=""
                      />
                    </div>
                    <div className="profile-contain">
                      <div className="profile-image">
                        <div className="position-relative">
                          <img
                            src={
                              profileImage?.location ||
                              "/assets/images/default_profile.jpg"
                            }
                            className="blur-up lazyload update_img"
                            alt=""
                          />
                          <div className="cover-icon">
                            <i className="fa-solid fa-pen">
                              <input
                                type="file"
                                accept="image/*"
                                id="formFileMultiple1"
                                onChange={handleProfileImageUpload}
                              />
                            </i>
                          </div>
                        </div>
                      </div>
                      <div className="profile-name">
                        <h3>{customer?.name}</h3>
                        <h6 className="text-content">{customer?.email}</h6>
                      </div>
                    </div>
                  </div>
                  <ul
                    className="nav nav-pills user-nav-pills"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-dashboard-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-dashboard"
                        type="button"
                      >
                        <i data-feather="home" />
                        Dashboard
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-order-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-order"
                        type="button"
                      >
                        <i data-feather="shopping-bag" />
                        Order
                      </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-card-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-card"
                        type="button"
                        role="tab"
                      >
                        <i data-feather="credit-card" /> Saved Card
                      </button>
                    </li> */}
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-address-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-address"
                        type="button"
                        role="tab"
                      >
                        <i data-feather="map-pin" />
                        Address
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                      >
                        <i data-feather="user" />
                        Profile
                      </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-subscriptions-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-subscriptions"
                        type="button"
                        role="tab"
                      >
                        <i data-feather="folder" />
                        Subscriptions
                      </button>
                    </li> */}
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-blogs-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-blogs"
                        type="button"
                        role="tab"
                      >
                        <i data-feather="arrow-right" />
                        Blogs
                      </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-security-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-security"
                        type="button"
                        role="tab"
                      >
                        <i data-feather="shield" />
                        Privacy
                      </button>
                    </li> */}
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-logout-tab"
                        type="button"
                        onClick={handleLogout}
                      >
                        <i data-feather="shield" />
                        Log out
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-9 col-lg-8">
                <button
                  className="btn left-dashboard-show btn-animation btn-md fw-bold d-block mb-4 d-lg-none"
                  onClick={() => setOpenMenu(!openMenu)}
                >
                  Show Menu
                </button>
                <div className="dashboard-right-sidebar">
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-dashboard"
                      role="tabpanel"
                    >
                      <Insights profileUpdated={handleProfileChange} />
                    </div>

                    <div
                      className="tab-pane fade"
                      id="pills-order"
                      role="tabpanel"
                    >
                      <Orders />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-address"
                      role="tabpanel"
                    >
                      <Address />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-card"
                      role="tabpanel"
                    >
                      <SavedCards />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                    >
                      <Profile />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-subscriptions"
                      role="tabpanel"
                    >
                      <Recipes />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-blogs"
                      role="tabpanel"
                    >
                      <Blogs />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-security"
                      role="tabpanel"
                    >
                      <Privacy />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* User Dashboard Section End */}
      </>
    </WebLayout>
  );
};

export default Dashboard;
