import axios from "axios";
import { SERVER_URL } from "../../constants";

export const paymentCreate = (data: Object) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/payment/create`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const paymentUpdate = (paymentId: String, data: any) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${SERVER_URL}/payment/update/${paymentId}`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const paymentDelete = (paymentId: String) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${SERVER_URL}/payment/delete/${paymentId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const paymentById = (paymentId: String) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/payment/get/${paymentId}`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const paymentList = () =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${SERVER_URL}/paymentId/list`)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const createCheckoutToken = (data: any) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/payment/checkout/create-payment-intent`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const createCheckoutOrder = (data: any) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/payment/checkout/create/order`, data)
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });

export const getPaymentStatus = (refId: string) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/payment/get/status`, { ref: refId })
      .then((response: any) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
