/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../lib/redux/hooks";

type Props = {};

const Footer = (props: Props) => {
  const navigate = useNavigate();

  const customer = useAppSelector((state) => state.customerReducer.c_user);

  return (
    <>
      {/* Footer Start */}
      <footer className="section-t-space footer-section-2 footer-color-2 !text-white !bg-[#0FB16E] pt-[4vw]">
        <div className="container-fluid-lg">
          <div className="main-footer">
            <div className="flex items-center justify-end gap-[1vw]">
              <ul className="social-icon">
                <li className="light-bg">
                  <a
                    href="https://www.facebook.com/"
                    className="footer-link-color"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li className="light-bg">
                  <a
                    href="https://accounts.google.com/signin/v2/identifier?flowName=GlifWebSignIn&flowEntry=ServiceLogin"
                    className="footer-link-color"
                  >
                    <i className="fab fa-google" />
                  </a>
                </li>
                <li className="light-bg">
                  <a
                    href="https://twitter.com/i/flow/login"
                    className="footer-link-color"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li className="light-bg">
                  <a
                    href="https://www.instagram.com/"
                    className="footer-link-color"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li className="light-bg">
                  <a
                    href="https://in.pinterest.com/"
                    className="footer-link-color"
                  >
                    <i className="fab fa-pinterest-p" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="row mt-[2vw] gap-[10vw] md:gap-[3vw] 2xl:gap-[1vw]">
              <div className="col-12 col-md-6 row">
                <div className="flex flex-col md:flex-row !gap-[2vw] md:items-center items-start">
                  <a
                    href="/"
                    className="foot-logo theme-logo shrink-0"
                  >
                    <img
                      src="/assets/images/home-new/white-logo.png"
                      className="img-fluid blur-up lazyloaded"
                      alt=""
                    />
                  </a>
                  <p className="information-text information-text-2 mt-md-0 mt-3 !text-white">
                  Hidden Health offers nutritious meal solutions designed for busy individuals and families who prioritize healthy eating. Our products, like sauces and sandwich fillings, are crafted with hidden vegetables to boost nutritional value without compromising on taste. We make it easy to incorporate balanced meals into your routine, even on hectic days. Choose Hidden Health for convenient, delicious options that support a healthier lifestyle.
                  </p>
                </div>
                <ul className="border-t border-[rgba(241,208,78,.4)] pt-[3vw] md:pt-[1vw] mt-[3vw] flex flex-col gap-[.5vw]">
                  <li>
                    <a href="#" className="light-text">
                      <div className="inform-box flex gap-[3vw] md:gap-[1vw] items-center flex-start-box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-map-pin !text-white"
                        >
                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                          <circle cx={12} cy={10} r={3} />
                        </svg>
                        <p className="!text-white">
                        Bulding No7, Street 1, Discovery Gardens, Dubai - UAE
                        </p>
                      </div>
                    </a>
                  </li>
 
                </ul>
              </div>
              <div className="col-12 col-md-6 row">
                <div className="col-xl-4 col-sm-6">
                  <div className="footer-title">
                    <h4 className="text-white poppins-regular">Useful Links</h4>
                  </div>
                  <ul className="footer-list footer-contact footer-list-light !gap-[1.5vw] md:!gap-[.5vw]">
                    <li>
                      <a
                        href="/"
                        className="light-text !text-white"
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        href="/shop"
                        className="light-text !text-white"
                      >
                        Shop
                      </a>
                    </li>
                    <li>
                      <a
                        href="/about-us"
                        className="light-text !text-white"
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="/blog" className="light-text !text-white">
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        href="/contact-us"
                        className="light-text !text-white"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="footer-title">
                    <h4 className="text-white poppins-regular">Help Center</h4>
                  </div>
                  <ul className="footer-list footer-list-light footer-contact !gap-[1.5vw] md:!gap-[.5vw]">
                
                    <li>
                      <a
                        // href={
                        //   customer?._id && customer?.authStatus
                        //     ? `/customer/account/${customer?._id}`
                        //     : `/customer/login?r=dashboard`
                        // }
                        href={
                          customer?._id && customer?.authStatus
                            ? `/customer-account?customerId=${customer?._id}`
                            : `/login?r=dashboard`
                        }
                        className="light-text !text-white"
                      >
                        Your Account
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="order-success.html"
                        className="light-text !text-white"
                      >
                        Your Order
                      </a>
                    </li>
                    <li>
                      <a
                        href="order-tracking.html"
                        className="light-text !text-white"
                      >
                        Track Orders
                      </a>
                    </li>
                    <li>
                      <a
                        href="wishlist.html"
                        className="light-text !text-white"
                      >
                        Your Wishlist
                      </a>
                    </li>
                    <li>
                      <a href="faq.html" className="light-text !text-white">
                        Search
                      </a>
                    </li>
                    <li>
                      <a href="faq.html" className="light-text !text-white">
                        FAQs
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="col-xl-5 col-sm-6">
                  <div className="footer-title">
                    <h4 className="text-white poppins-regular">Contact Us</h4>
                  </div>
                  <ul className="footer-list footer-list-light footer-contact !gap-[1.5vw] md:!gap-[.5vw]">
                   
                    <li>
                      <a href="#" className="light-text">
                        <div className="inform-box flex items-center gap-[1vw] !text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-mail !text-white"
                          >
                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                            <polyline points="22,6 12,13 2,6" />
                          </svg>
                          <p>info@hidden-health.com</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-footer !mb-0 sub-footer-lit py-[3vw] md:py-[1vw] !border-t-0">
            <div className="left-footer">
              <p className="light-text !text-white">
                2024 Hidden health food products LLC. All rights reserved
              </p>
            </div>
            <ul className="payment-box">
              <li>
                <img
                  src="/assets/images/icon/paymant/visa.png"
                  className="blur-up lazyloaded"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="/assets/images/icon/paymant/discover.png"
                  alt=""
                  className="blur-up lazyloaded"
                />
              </li>
              <li>
                <img
                  src="/assets/images/icon/paymant/american.png"
                  alt=""
                  className="blur-up lazyloaded"
                />
              </li>
              <li>
                <img
                  src="/assets/images/icon/paymant/master-card.png"
                  alt=""
                  className="blur-up lazyloaded"
                />
              </li>
              <li>
                <img
                  src="/assets/images/icon/paymant/giro-pay.png"
                  alt=""
                  className="blur-up lazyloaded"
                />
              </li>
            </ul>
          </div>
        </div>
      </footer>
      {/* Footer End */}
      {/* Tap to top and theme setting button start */}
      <div className="theme-option">
        <div className="back-to-top">
          <a id="back-to-top" href="#">
            <i className="fas fa-chevron-up" />
          </a>
        </div>
      </div>
      {/* Tap to top and theme setting button end */}
    </>
  );
};

export default Footer;
